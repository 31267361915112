"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayStorage = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../container/ServiceIdentifiers");
const Initializable_1 = require("../decorators/Initializable");
let ArrayStorage = class ArrayStorage {
    constructor(randomGenerator, options) {
        this.storageLength = 0;
        this.randomGenerator = randomGenerator;
        this.options = options;
    }
    initialize() {
        this.storage = [];
        this.storageId = this.randomGenerator.getRandomString(6);
    }
    delete(key) {
        var _a;
        const deletedElement = (_a = this.storage.splice(key, 1)[0]) !== null && _a !== void 0 ? _a : undefined;
        if (deletedElement) {
            this.storageLength--;
        }
        return deletedElement;
    }
    get(key) {
        return this.storage[key];
    }
    getOrThrow(key) {
        const value = this.get(key);
        if (!value) {
            throw new Error(`No value found in array storage with key \`${key}\``);
        }
        return value;
    }
    getKeyOf(value) {
        const key = this.storage.indexOf(value);
        return key >= 0 ? key : null;
    }
    getLength() {
        return this.storageLength;
    }
    getStorage() {
        return this.storage;
    }
    getStorageId() {
        return this.storageId;
    }
    mergeWith(storage, mergeId = false) {
        this.storage = [...this.storage, ...storage.getStorage()];
        if (mergeId) {
            this.storageId = storage.getStorageId();
        }
    }
    set(key, value) {
        if (key === this.storageLength) {
            this.storage.push(value);
        }
        else {
            this.storage.splice(key, 0, value);
        }
        this.storageLength++;
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Array)
], ArrayStorage.prototype, "storage", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], ArrayStorage.prototype, "storageId", void 0);
__decorate([
    (0, inversify_1.postConstruct)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ArrayStorage.prototype, "initialize", null);
ArrayStorage = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], ArrayStorage);
exports.ArrayStorage = ArrayStorage;
