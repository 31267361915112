"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayScopeCallsWrapperTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const StringArrayCustomNode_1 = require("../../enums/custom-nodes/StringArrayCustomNode");
const StringArrayWrappersType_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayWrappersType");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeAppender_1 = require("../../node/NodeAppender");
const NodeGuards_1 = require("../../node/NodeGuards");
let StringArrayScopeCallsWrapperTransformer = class StringArrayScopeCallsWrapperTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options, visitedLexicalScopeNodesStackStorage, stringArrayStorage, stringArrayScopeCallsWrappersDataStorage, stringArrayTransformerCustomNodeFactory) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.StringArrayRotateFunctionTransformer
        ];
        this.visitedLexicalScopeNodesStackStorage = visitedLexicalScopeNodesStackStorage;
        this.stringArrayStorage = stringArrayStorage;
        this.stringArrayScopeCallsWrappersDataStorage = stringArrayScopeCallsWrappersDataStorage;
        this.stringArrayTransformerCustomNodeFactory = stringArrayTransformerCustomNodeFactory;
    }
    getVisitor(nodeTransformationStage) {
        if (!this.options.stringArrayWrappersCount) {
            return null;
        }
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.StringArray:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isNodeWithLexicalScopeStatements(node, parentNode)) {
                            this.onLexicalScopeNodeEnter(node);
                        }
                    },
                    leave: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isNodeWithLexicalScopeStatements(node, parentNode)) {
                            this.onLexicalScopeNodeLeave();
                            return this.transformNode(node);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(lexicalScopeBodyNode) {
        var _a;
        const stringArrayScopeCallsWrappersDataByEncoding = (_a = this.stringArrayScopeCallsWrappersDataStorage.get(lexicalScopeBodyNode)) !== null && _a !== void 0 ? _a : null;
        if (!stringArrayScopeCallsWrappersDataByEncoding) {
            return lexicalScopeBodyNode;
        }
        const stringArrayScopeCallsWrappersDataList = Object.values(stringArrayScopeCallsWrappersDataByEncoding);
        for (const stringArrayScopeCallsWrappersData of stringArrayScopeCallsWrappersDataList) {
            if (!stringArrayScopeCallsWrappersData) {
                continue;
            }
            const { scopeCallsWrappersData } = stringArrayScopeCallsWrappersData;
            const scopeCallsWrappersDataLength = scopeCallsWrappersData.length;
            for (let i = scopeCallsWrappersDataLength - 1; i >= 0; i--) {
                const stringArrayScopeCallsWrapperData = scopeCallsWrappersData[i];
                const upperStringArrayCallsWrapperData = this.getUpperStringArrayCallsWrapperData(stringArrayScopeCallsWrappersData);
                this.getAndAppendStringArrayScopeCallsWrapperNode(lexicalScopeBodyNode, stringArrayScopeCallsWrapperData, upperStringArrayCallsWrapperData);
            }
        }
        return lexicalScopeBodyNode;
    }
    getRootStringArrayCallsWrapperData(stringArrayScopeCallsWrappersData) {
        const { encoding } = stringArrayScopeCallsWrappersData;
        return {
            name: this.stringArrayStorage.getStorageCallsWrapperName(encoding),
            index: 0,
            parameterIndexesData: null
        };
    }
    getUpperStringArrayCallsWrapperData(stringArrayScopeCallsWrappersData) {
        var _a, _b, _c, _d;
        const { encoding } = stringArrayScopeCallsWrappersData;
        const rootStringArrayCallsWrapperData = this.getRootStringArrayCallsWrapperData(stringArrayScopeCallsWrappersData);
        if (!this.options.stringArrayWrappersChainedCalls) {
            return rootStringArrayCallsWrapperData;
        }
        const parentLexicalScopeBodyNode = (_a = this.visitedLexicalScopeNodesStackStorage.getLastElement()) !== null && _a !== void 0 ? _a : null;
        if (!parentLexicalScopeBodyNode) {
            return rootStringArrayCallsWrapperData;
        }
        const parentLexicalScopeCallsWrappersDataByEncoding = (_b = this.stringArrayScopeCallsWrappersDataStorage
            .get(parentLexicalScopeBodyNode)) !== null && _b !== void 0 ? _b : null;
        const parentScopeCallsWrappersData = (_d = (_c = parentLexicalScopeCallsWrappersDataByEncoding === null || parentLexicalScopeCallsWrappersDataByEncoding === void 0 ? void 0 : parentLexicalScopeCallsWrappersDataByEncoding[encoding]) === null || _c === void 0 ? void 0 : _c.scopeCallsWrappersData) !== null && _d !== void 0 ? _d : null;
        if (!(parentScopeCallsWrappersData === null || parentScopeCallsWrappersData === void 0 ? void 0 : parentScopeCallsWrappersData.length)) {
            return rootStringArrayCallsWrapperData;
        }
        return this.randomGenerator
            .getRandomGenerator()
            .pickone(parentScopeCallsWrappersData);
    }
    getAndAppendStringArrayScopeCallsWrapperNode(lexicalScopeBodyNode, stringArrayScopeCallsWrapperData, upperStringArrayCallsWrapperData) {
        let stringArrayScopeCallsWrapperNode;
        switch (this.options.stringArrayWrappersType) {
            case StringArrayWrappersType_1.StringArrayWrappersType.Function: {
                const randomIndex = this.randomGenerator.getRandomInteger(0, lexicalScopeBodyNode.body.length - 1);
                stringArrayScopeCallsWrapperNode = this.getStringArrayScopeCallsWrapperFunctionNode(stringArrayScopeCallsWrapperData, upperStringArrayCallsWrapperData);
                NodeAppender_1.NodeAppender.insertAtIndex(lexicalScopeBodyNode, stringArrayScopeCallsWrapperNode, randomIndex);
                break;
            }
            case StringArrayWrappersType_1.StringArrayWrappersType.Variable:
            default: {
                stringArrayScopeCallsWrapperNode = this.getStringArrayScopeCallsWrapperVariableNode(stringArrayScopeCallsWrapperData, upperStringArrayCallsWrapperData);
                NodeAppender_1.NodeAppender.prepend(lexicalScopeBodyNode, stringArrayScopeCallsWrapperNode);
            }
        }
    }
    getStringArrayScopeCallsWrapperVariableNode(stringArrayScopeCallsWrapperData, upperStringArrayCallsWrapperData) {
        const stringArrayScopeCallsWrapperVariableNode = this.stringArrayTransformerCustomNodeFactory(StringArrayCustomNode_1.StringArrayCustomNode.StringArrayScopeCallsWrapperVariableNode);
        stringArrayScopeCallsWrapperVariableNode.initialize(stringArrayScopeCallsWrapperData, upperStringArrayCallsWrapperData);
        return stringArrayScopeCallsWrapperVariableNode.getNode();
    }
    getStringArrayScopeCallsWrapperFunctionNode(stringArrayScopeCallsWrapperData, upperStringArrayCallsWrapperData) {
        const stringArrayScopeCallsWrapperFunctionNode = this.stringArrayTransformerCustomNodeFactory(StringArrayCustomNode_1.StringArrayCustomNode.StringArrayScopeCallsWrapperFunctionNode);
        stringArrayScopeCallsWrapperFunctionNode.initialize(stringArrayScopeCallsWrapperData, upperStringArrayCallsWrapperData);
        return stringArrayScopeCallsWrapperFunctionNode.getNode();
    }
    onLexicalScopeNodeEnter(lexicalScopeBodyNode) {
        this.visitedLexicalScopeNodesStackStorage.push(lexicalScopeBodyNode);
    }
    onLexicalScopeNodeLeave() {
        this.visitedLexicalScopeNodesStackStorage.pop();
    }
};
StringArrayScopeCallsWrapperTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IVisitedLexicalScopeNodesStackStorage)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayScopeCallsWrappersDataStorage)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayCustomNode)),
    __metadata("design:paramtypes", [Object, Object, Object, Object, Object, Function])
], StringArrayScopeCallsWrapperTransformer);
exports.StringArrayScopeCallsWrapperTransformer = StringArrayScopeCallsWrapperTransformer;
