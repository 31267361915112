"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeStatementUtils = void 0;
const NodeGuards_1 = require("./NodeGuards");
class NodeStatementUtils {
    static getParentNodeWithStatements(node) {
        return NodeStatementUtils.getParentNodesWithStatementsRecursive(node, 1)[0];
    }
    static getParentNodesWithStatements(node) {
        return NodeStatementUtils.getParentNodesWithStatementsRecursive(node);
    }
    static getNextSiblingStatement(statement) {
        return NodeStatementUtils.getSiblingStatementByOffset(statement, 1);
    }
    static getPreviousSiblingStatement(statement) {
        return NodeStatementUtils.getSiblingStatementByOffset(statement, -1);
    }
    static getRootStatementOfNode(node) {
        if (NodeGuards_1.NodeGuards.isProgramNode(node)) {
            throw new Error('Unable to find root statement for `Program` node');
        }
        const parentNode = node.parentNode;
        if (!parentNode) {
            throw new ReferenceError('`parentNode` property of given node is `undefined`');
        }
        if (!NodeGuards_1.NodeGuards.isNodeWithStatements(parentNode)) {
            return NodeStatementUtils.getRootStatementOfNode(parentNode);
        }
        return node;
    }
    static getScopeOfNode(node) {
        const parentNode = node.parentNode;
        if (!parentNode) {
            throw new ReferenceError('`parentNode` property of given node is `undefined`');
        }
        if (!NodeGuards_1.NodeGuards.isNodeWithStatements(parentNode)) {
            return NodeStatementUtils.getScopeOfNode(parentNode);
        }
        return parentNode;
    }
    static getParentNodesWithStatementsRecursive(node, maxSize = Infinity, nodesWithStatements = [], depth = 0) {
        if (nodesWithStatements.length >= maxSize) {
            return nodesWithStatements;
        }
        const parentNode = node.parentNode;
        if (!parentNode) {
            throw new ReferenceError('`parentNode` property of given node is `undefined`');
        }
        if (NodeGuards_1.NodeGuards.isProgramNode(node) ||
            (NodeGuards_1.NodeGuards.isNodeWithLexicalScopeStatements(node, parentNode) && depth > 0)) {
            nodesWithStatements.push(node);
        }
        if (node !== parentNode) {
            return NodeStatementUtils.getParentNodesWithStatementsRecursive(parentNode, maxSize, nodesWithStatements, ++depth);
        }
        return nodesWithStatements;
    }
    static getSiblingStatementByOffset(statement, offset) {
        const scopeNode = NodeStatementUtils.getScopeOfNode(statement);
        const scopeBody = !NodeGuards_1.NodeGuards.isSwitchCaseNode(scopeNode)
            ? scopeNode.body
            : scopeNode.consequent;
        const indexInScope = scopeBody.indexOf(statement);
        return scopeBody[indexInScope + offset] || null;
    }
}
exports.NodeStatementUtils = NodeStatementUtils;
