"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractStatementSimplifyTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeFactory_1 = require("../../node/NodeFactory");
let AbstractStatementSimplifyTransformer = class AbstractStatementSimplifyTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.ExpressionStatementsMergeTransformer,
            NodeTransformer_1.NodeTransformer.VariableDeclarationsMergeTransformer
        ];
    }
    getStatementSimplifyData(statementNode) {
        if (!statementNode) {
            return null;
        }
        if (!NodeGuards_1.NodeGuards.isBlockStatementNode(statementNode)) {
            return {
                leadingStatements: [statementNode],
                trailingStatement: null,
                hasReturnStatement: false,
                hasSingleExpression: false
            };
        }
        const { startIndex, unwrappedExpressions, hasReturnStatement, hasStatementsAfterReturnStatement } = this.collectIteratedStatementsSimplifyData(statementNode);
        if (hasStatementsAfterReturnStatement) {
            return {
                leadingStatements: statementNode.body,
                trailingStatement: null,
                hasReturnStatement: false,
                hasSingleExpression: false
            };
        }
        const leadingStatements = this.getLeadingStatements(statementNode, startIndex);
        if (!unwrappedExpressions.length) {
            return {
                leadingStatements,
                trailingStatement: null,
                hasReturnStatement,
                hasSingleExpression: false
            };
        }
        const hasSingleExpression = unwrappedExpressions.length === 1;
        const expression = hasSingleExpression
            ? unwrappedExpressions[0]
            : NodeFactory_1.NodeFactory.sequenceExpressionNode(unwrappedExpressions);
        const statement = hasReturnStatement
            ? NodeFactory_1.NodeFactory.returnStatementNode(expression)
            : NodeFactory_1.NodeFactory.expressionStatementNode(expression);
        return {
            leadingStatements,
            trailingStatement: {
                statement,
                expression
            },
            hasReturnStatement,
            hasSingleExpression
        };
    }
    collectIteratedStatementsSimplifyData(statementNode) {
        const statementNodeBodyLength = statementNode.body.length;
        const unwrappedExpressions = [];
        let hasReturnStatement = false;
        let hasStatementsAfterReturnStatement = false;
        let startIndex = null;
        for (let i = statementNodeBodyLength - 1; i >= 0; i--) {
            const statementBodyStatementNode = statementNode.body[i];
            if (NodeGuards_1.NodeGuards.isExpressionStatementNode(statementBodyStatementNode)) {
                if (NodeGuards_1.NodeGuards.isSequenceExpressionNode(statementBodyStatementNode.expression)) {
                    unwrappedExpressions.unshift(...statementBodyStatementNode.expression.expressions);
                }
                else {
                    unwrappedExpressions.unshift(statementBodyStatementNode.expression);
                }
                startIndex = i;
                continue;
            }
            if (NodeGuards_1.NodeGuards.isReturnStatementNode(statementBodyStatementNode)
                && statementBodyStatementNode.argument) {
                unwrappedExpressions.unshift(statementBodyStatementNode.argument);
                hasReturnStatement = true;
                hasStatementsAfterReturnStatement = i !== statementNodeBodyLength - 1;
                startIndex = i;
                continue;
            }
            break;
        }
        return {
            startIndex,
            unwrappedExpressions,
            hasReturnStatement,
            hasStatementsAfterReturnStatement
        };
    }
    getLeadingStatements(statementNode, startIndex) {
        if (startIndex === null) {
            return statementNode.body;
        }
        return startIndex === 0
            ? []
            : statementNode.body.slice(0, startIndex);
    }
    getPartialStatement(statementSimplifyData) {
        if (!statementSimplifyData.leadingStatements.length && statementSimplifyData.trailingStatement) {
            return statementSimplifyData.trailingStatement.statement;
        }
        return NodeFactory_1.NodeFactory.blockStatementNode([
            ...statementSimplifyData.leadingStatements.length ? statementSimplifyData.leadingStatements : [],
            ...statementSimplifyData.trailingStatement ? [statementSimplifyData.trailingStatement.statement] : []
        ]);
    }
};
AbstractStatementSimplifyTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], AbstractStatementSimplifyTransformer);
exports.AbstractStatementSimplifyTransformer = AbstractStatementSimplifyTransformer;
