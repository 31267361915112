"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.analyzersModule = void 0;
const InversifyContainerFacade_1 = require("../../InversifyContainerFacade");
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const CalleeDataExtractor_1 = require("../../../enums/analyzers/calls-graph-analyzer/CalleeDataExtractor");
const CallsGraphAnalyzer_1 = require("../../../analyzers/calls-graph-analyzer/CallsGraphAnalyzer");
const FunctionDeclarationCalleeDataExtractor_1 = require("../../../analyzers/calls-graph-analyzer/callee-data-extractors/FunctionDeclarationCalleeDataExtractor");
const FunctionExpressionCalleeDataExtractor_1 = require("../../../analyzers/calls-graph-analyzer/callee-data-extractors/FunctionExpressionCalleeDataExtractor");
const NumberNumericalExpressionAnalyzer_1 = require("../../../analyzers/number-numerical-expression-analyzer/NumberNumericalExpressionAnalyzer");
const ObjectExpressionCalleeDataExtractor_1 = require("../../../analyzers/calls-graph-analyzer/callee-data-extractors/ObjectExpressionCalleeDataExtractor");
const PrevailingKindOfVariablesAnalyzer_1 = require("../../../analyzers/prevailing-kind-of-variables-analyzer/PrevailingKindOfVariablesAnalyzer");
const ScopeAnalyzer_1 = require("../../../analyzers/scope-analyzer/ScopeAnalyzer");
const StringArrayStorageAnalyzer_1 = require("../../../analyzers/string-array-storage-analyzer/StringArrayStorageAnalyzer");
exports.analyzersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICallsGraphAnalyzer)
        .to(CallsGraphAnalyzer_1.CallsGraphAnalyzer)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INumberNumericalExpressionAnalyzer)
        .to(NumberNumericalExpressionAnalyzer_1.NumberNumericalExpressionAnalyzer)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IPrevailingKindOfVariablesAnalyzer)
        .to(PrevailingKindOfVariablesAnalyzer_1.PrevailingKindOfVariablesAnalyzer)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IScopeAnalyzer)
        .to(ScopeAnalyzer_1.ScopeAnalyzer)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorageAnalyzer)
        .to(StringArrayStorageAnalyzer_1.StringArrayStorageAnalyzer)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICalleeDataExtractor)
        .to(FunctionDeclarationCalleeDataExtractor_1.FunctionDeclarationCalleeDataExtractor)
        .whenTargetNamed(CalleeDataExtractor_1.CalleeDataExtractor.FunctionDeclarationCalleeDataExtractor);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICalleeDataExtractor)
        .to(FunctionExpressionCalleeDataExtractor_1.FunctionExpressionCalleeDataExtractor)
        .whenTargetNamed(CalleeDataExtractor_1.CalleeDataExtractor.FunctionExpressionCalleeDataExtractor);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICalleeDataExtractor)
        .to(ObjectExpressionCalleeDataExtractor_1.ObjectExpressionCalleeDataExtractor)
        .whenTargetNamed(CalleeDataExtractor_1.CalleeDataExtractor.ObjectExpressionCalleeDataExtractor);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICalleeDataExtractor)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getCacheFactory(ServiceIdentifiers_1.ServiceIdentifiers.ICalleeDataExtractor));
});
