"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayCallsTransformThresholdRule = void 0;
const StringArrayCallsTransformThresholdRule = (options) => {
    if (options.stringArrayCallsTransformThreshold === 0) {
        options = {
            ...options,
            stringArrayCallsTransform: false,
            stringArrayCallsTransformThreshold: 0
        };
    }
    return options;
};
exports.StringArrayCallsTransformThresholdRule = StringArrayCallsTransformThresholdRule;
