"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var BasePropertiesExtractor_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasePropertiesExtractor = void 0;
const inversify_1 = require("inversify");
const NodeAppender_1 = require("../../../node/NodeAppender");
const NodeFactory_1 = require("../../../node/NodeFactory");
const NodeGuards_1 = require("../../../node/NodeGuards");
const NodeStatementUtils_1 = require("../../../node/NodeStatementUtils");
const NodeUtils_1 = require("../../../node/NodeUtils");
let BasePropertiesExtractor = BasePropertiesExtractor_1 = class BasePropertiesExtractor {
    static getPropertyNodeKeyName(propertyNode) {
        const propertyKeyNode = propertyNode.key;
        if (NodeGuards_1.NodeGuards.isLiteralNode(propertyKeyNode)
            && (typeof propertyKeyNode.value === 'string'
                || typeof propertyKeyNode.value === 'number')) {
            return propertyKeyNode.value.toString();
        }
        if (NodeGuards_1.NodeGuards.isIdentifierNode(propertyKeyNode)) {
            return propertyKeyNode.name;
        }
        return null;
    }
    static isProhibitedPropertyNode(node) {
        return node.kind !== 'init';
    }
    static isProhibitedPattern(node) {
        return !node
            || NodeGuards_1.NodeGuards.isObjectPatternNode(node)
            || NodeGuards_1.NodeGuards.isArrayPatternNode(node)
            || NodeGuards_1.NodeGuards.isAssignmentPatternNode(node)
            || NodeGuards_1.NodeGuards.isRestElementNode(node);
    }
    static shouldCreateLiteralNode(property) {
        return !property.computed
            || (property.computed && !!property.key && NodeGuards_1.NodeGuards.isLiteralNode(property.key));
    }
    extract(objectExpressionNode, hostStatement) {
        const hostNode = objectExpressionNode.parentNode;
        if (hostNode
            && NodeGuards_1.NodeGuards.isVariableDeclaratorNode(hostNode)
            && NodeGuards_1.NodeGuards.isIdentifierNode(hostNode.id)) {
            return this.transformObjectExpressionNode(objectExpressionNode, hostStatement, hostNode.id);
        }
        return {
            nodeToReplace: objectExpressionNode,
            objectExpressionHostStatement: hostStatement,
            objectExpressionNode: objectExpressionNode
        };
    }
    transformObjectExpressionNode(objectExpressionNode, hostStatement, memberExpressionHostNode) {
        const properties = objectExpressionNode.properties;
        const [expressionStatements, removablePropertyIds] = this
            .extractPropertiesToExpressionStatements(properties, hostStatement, memberExpressionHostNode);
        const hostNodeWithStatements = NodeStatementUtils_1.NodeStatementUtils.getScopeOfNode(hostStatement);
        this.filterExtractedObjectExpressionProperties(objectExpressionNode, removablePropertyIds);
        NodeAppender_1.NodeAppender.insertAfter(hostNodeWithStatements, expressionStatements, hostStatement);
        NodeUtils_1.NodeUtils.parentizeAst(hostNodeWithStatements);
        return {
            nodeToReplace: objectExpressionNode,
            objectExpressionHostStatement: hostStatement,
            objectExpressionNode: objectExpressionNode
        };
    }
    extractPropertiesToExpressionStatements(properties, hostStatement, memberExpressionHostNode) {
        const propertiesLength = properties.length;
        const expressionStatements = [];
        const removablePropertyIds = [];
        for (let i = propertiesLength - 1; i >= 0; i--) {
            const property = properties[i];
            if (NodeGuards_1.NodeGuards.isSpreadElementNode(property)) {
                break;
            }
            if (BasePropertiesExtractor_1.isProhibitedPropertyNode(property)) {
                continue;
            }
            const propertyValue = property.value;
            if (BasePropertiesExtractor_1.isProhibitedPattern(propertyValue)) {
                continue;
            }
            const propertyKeyName = BasePropertiesExtractor_1.getPropertyNodeKeyName(property);
            if (!propertyKeyName) {
                continue;
            }
            const shouldCreateLiteralNode = BasePropertiesExtractor_1.shouldCreateLiteralNode(property);
            const memberExpressionProperty = shouldCreateLiteralNode
                ? NodeFactory_1.NodeFactory.literalNode(propertyKeyName)
                : NodeFactory_1.NodeFactory.identifierNode(propertyKeyName);
            const memberExpressionNode = NodeFactory_1.NodeFactory
                .memberExpressionNode(memberExpressionHostNode, memberExpressionProperty, true);
            const expressionStatementNode = NodeFactory_1.NodeFactory.expressionStatementNode(NodeFactory_1.NodeFactory.assignmentExpressionNode('=', memberExpressionNode, propertyValue));
            if (NodeGuards_1.NodeGuards.isObjectExpressionNode(property.value)) {
                this.transformObjectExpressionNode(property.value, hostStatement, memberExpressionNode);
            }
            expressionStatements.unshift(expressionStatementNode);
            removablePropertyIds.unshift(i);
        }
        return [expressionStatements, removablePropertyIds];
    }
    filterExtractedObjectExpressionProperties(objectExpressionNode, removablePropertyIds) {
        objectExpressionNode.properties = objectExpressionNode.properties
            .filter((property, index) => !removablePropertyIds.includes(index));
    }
};
BasePropertiesExtractor = BasePropertiesExtractor_1 = __decorate([
    (0, inversify_1.injectable)()
], BasePropertiesExtractor);
exports.BasePropertiesExtractor = BasePropertiesExtractor;
