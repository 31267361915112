"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainLockCodeHelper = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const ObfuscationTarget_1 = require("../../enums/ObfuscationTarget");
const Initializable_1 = require("../../decorators/Initializable");
const DomainLockTemplate_1 = require("./templates/DomainLockTemplate");
const GlobalVariableNoEvalTemplate_1 = require("../common/templates/GlobalVariableNoEvalTemplate");
const AbstractCustomCodeHelper_1 = require("../AbstractCustomCodeHelper");
const NodeUtils_1 = require("../../node/NodeUtils");
let DomainLockCodeHelper = class DomainLockCodeHelper extends AbstractCustomCodeHelper_1.AbstractCustomCodeHelper {
    constructor(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options, cryptUtils) {
        super(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options);
        this.cryptUtils = cryptUtils;
    }
    initialize(callsControllerFunctionName, domainLockFunctionName) {
        this.callsControllerFunctionName = callsControllerFunctionName;
        this.domainLockFunctionName = domainLockFunctionName;
    }
    getNodeStructure(codeHelperTemplate) {
        return NodeUtils_1.NodeUtils.convertCodeToStructure(codeHelperTemplate);
    }
    getCodeHelperTemplate() {
        const domainsString = this.options.domainLock.join(';');
        const domainsLockRedirectUrl = this.options.domainLockRedirectUrl;
        const [hiddenDomainsString, domainsStringDiff] = this.cryptUtils.hideString(domainsString, domainsString.length * 3);
        const [hiddenDomainLockRedirectUrl, domainLockRedirectUrlDiff] = this.cryptUtils.hideString(domainsLockRedirectUrl, domainsLockRedirectUrl.length * 3);
        const globalVariableTemplate = this.options.target !== ObfuscationTarget_1.ObfuscationTarget.BrowserNoEval
            ? this.getGlobalVariableTemplate()
            : (0, GlobalVariableNoEvalTemplate_1.GlobalVariableNoEvalTemplate)();
        return this.customCodeHelperFormatter.formatTemplate((0, DomainLockTemplate_1.DomainLockTemplate)(), {
            callControllerFunctionName: this.callsControllerFunctionName,
            domainLockFunctionName: this.domainLockFunctionName,
            domainsStringDiff,
            domains: hiddenDomainsString,
            domainLockRedirectUrlDiff: domainLockRedirectUrlDiff,
            hiddenDomainLockRedirectUrl: hiddenDomainLockRedirectUrl,
            globalVariableTemplate
        });
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], DomainLockCodeHelper.prototype, "callsControllerFunctionName", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], DomainLockCodeHelper.prototype, "domainLockFunctionName", void 0);
DomainLockCodeHelper = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperObfuscator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICryptUtils)),
    __metadata("design:paramtypes", [Function, Object, Object, Object, Object, Object])
], DomainLockCodeHelper);
exports.DomainLockCodeHelper = DomainLockCodeHelper;
