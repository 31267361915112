"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoggingMessage = void 0;
var LoggingMessage;
(function (LoggingMessage) {
    LoggingMessage["EmptySourceCode"] = "Empty source code. Obfuscation canceled...";
    LoggingMessage["ObfuscationCompleted"] = "Obfuscation completed. Total time: %s sec.";
    LoggingMessage["ObfuscationStarted"] = "Obfuscation started...";
    LoggingMessage["RandomGeneratorSeed"] = "Random generator seed: %s...";
    LoggingMessage["CodeTransformationStage"] = "Code transformation stage: %s...";
    LoggingMessage["NodeTransformationStage"] = "AST transformation stage: %s...";
    LoggingMessage["Version"] = "Version: %s";
})(LoggingMessage = exports.LoggingMessage || (exports.LoggingMessage = {}));
