"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeadCodeInjectionThresholdRule = void 0;
const DeadCodeInjectionThresholdRule = (options) => {
    if (options.deadCodeInjectionThreshold === 0) {
        options = {
            ...options,
            deadCodeInjection: false,
            deadCodeInjectionThreshold: 0
        };
    }
    return options;
};
exports.DeadCodeInjectionThresholdRule = DeadCodeInjectionThresholdRule;
