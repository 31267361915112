"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentifierNamesGenerator = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.IdentifierNamesGenerator = Utils_1.Utils.makeEnum({
    DictionaryIdentifierNamesGenerator: 'dictionary',
    HexadecimalIdentifierNamesGenerator: 'hexadecimal',
    MangledIdentifierNamesGenerator: 'mangled',
    MangledShuffledIdentifierNamesGenerator: 'mangled-shuffled'
});
