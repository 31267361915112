"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThroughIdentifierReplacer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const NodeFactory_1 = require("../../../node/NodeFactory");
let ThroughIdentifierReplacer = class ThroughIdentifierReplacer {
    constructor(identifierNamesCacheStorage, options) {
        this.identifierNamesCacheStorage = identifierNamesCacheStorage;
        this.options = options;
    }
    replace(identifierNode) {
        var _a;
        const identifierName = identifierNode.name;
        const newIdentifierName = this.options.identifierNamesCache && !this.isReservedName(identifierName)
            ? (_a = this.identifierNamesCacheStorage.get(identifierName)) !== null && _a !== void 0 ? _a : identifierName
            : identifierName;
        return NodeFactory_1.NodeFactory.identifierNode(newIdentifierName);
    }
    isReservedName(name) {
        if (!this.options.reservedNames.length) {
            return false;
        }
        return this.options.reservedNames
            .some((reservedName) => {
            return new RegExp(reservedName, 'g').exec(name) !== null;
        });
    }
};
ThroughIdentifierReplacer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IGlobalIdentifierNamesCacheStorage)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], ThroughIdentifierReplacer);
exports.ThroughIdentifierReplacer = ThroughIdentifierReplacer;
