"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfDefendingTemplate = void 0;
function SelfDefendingTemplate(randomGenerator, escapeSequenceEncoder) {
    const identifierLength = 6;
    const rc4BytesIdentifier = randomGenerator.getRandomString(identifierLength);
    const statesIdentifier = randomGenerator.getRandomString(identifierLength);
    const newStateIdentifier = randomGenerator.getRandomString(identifierLength);
    const firstStateIdentifier = randomGenerator.getRandomString(identifierLength);
    const secondStateIdentifier = randomGenerator.getRandomString(identifierLength);
    const checkStateIdentifier = randomGenerator.getRandomString(identifierLength);
    const runStateIdentifier = randomGenerator.getRandomString(identifierLength);
    const getStateIdentifier = randomGenerator.getRandomString(identifierLength);
    const stateResultIdentifier = randomGenerator.getRandomString(identifierLength);
    return `
        const StatesClass = function (${rc4BytesIdentifier}) {
            this.${rc4BytesIdentifier} = ${rc4BytesIdentifier};
            this.${statesIdentifier} = [1, 0, 0];
            this.${newStateIdentifier} = function(){return 'newState';};
            this.${firstStateIdentifier} = '${escapeSequenceEncoder.encode('\\w+ *\\(\\) *{\\w+ *', true)}';
            this.${secondStateIdentifier} = '${escapeSequenceEncoder.encode('[\'|"].+[\'|"];? *}', true)}';
        };
        
        StatesClass.prototype.${checkStateIdentifier} = function () {
            const regExp = new RegExp(this.${firstStateIdentifier} + this.${secondStateIdentifier});
            const expression = regExp.test(this.${newStateIdentifier}.toString())
                ? --this.${statesIdentifier}[1]
                : --this.${statesIdentifier}[0];
            
            return this.${runStateIdentifier}(expression);
        };
        
        StatesClass.prototype.${runStateIdentifier} = function (${stateResultIdentifier}) {
            if (!Boolean(~${stateResultIdentifier})) {
                return ${stateResultIdentifier};
            }
            
            return this.${getStateIdentifier}(this.${rc4BytesIdentifier});
        };

        StatesClass.prototype.${getStateIdentifier} = function (${rc4BytesIdentifier}) {
            for (let i = 0, len = this.${statesIdentifier}.length; i < len; i++) {
                this.${statesIdentifier}.push(Math.round(Math.random()));
                len = this.${statesIdentifier}.length;
            }
            
            return ${rc4BytesIdentifier}(this.${statesIdentifier}[0]);
        };

        new StatesClass({stringArrayCallsWrapperName}).${checkStateIdentifier}();
    `;
}
exports.SelfDefendingTemplate = SelfDefendingTemplate;
