"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayEncodingRule = void 0;
const StringArrayEncoding_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayEncoding");
const StringArrayEncodingRule = (options) => {
    if (!options.stringArrayEncoding.length) {
        options = {
            ...options,
            stringArrayEncoding: [
                StringArrayEncoding_1.StringArrayEncoding.None
            ]
        };
    }
    return options;
};
exports.StringArrayEncodingRule = StringArrayEncodingRule;
