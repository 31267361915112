"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayBase64DecodeTemplate = void 0;
function StringArrayBase64DecodeTemplate(randomGenerator) {
    const identifierLength = 6;
    const initializedIdentifier = randomGenerator.getRandomString(identifierLength);
    const base64Identifier = randomGenerator.getRandomString(identifierLength);
    return `
        if ({stringArrayCallsWrapperName}.${initializedIdentifier} === undefined) {
            {atobPolyfill}
            {stringArrayCallsWrapperName}.${base64Identifier} = {atobFunctionName};

            {stringArrayCacheName} = arguments;
            
            {stringArrayCallsWrapperName}.${initializedIdentifier} = true;
        }
                  
        const firstValue = stringArray[0];
        const cacheKey = index + firstValue;
        const cachedValue = {stringArrayCacheName}[cacheKey];
        
        if (!cachedValue) {
            {selfDefendingCode}
            
            value = {stringArrayCallsWrapperName}.${base64Identifier}(value);
            {stringArrayCacheName}[cacheKey] = value;
        } else {
            value = cachedValue;
        }
    `;
}
exports.StringArrayBase64DecodeTemplate = StringArrayBase64DecodeTemplate;
