"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayHexadecimalNumericStringIndexNode = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const AbstractStringArrayIndexNode_1 = require("./AbstractStringArrayIndexNode");
const NodeFactory_1 = require("../../../node/NodeFactory");
const NumberUtils_1 = require("../../../utils/NumberUtils");
let StringArrayHexadecimalNumericStringIndexNode = class StringArrayHexadecimalNumericStringIndexNode extends AbstractStringArrayIndexNode_1.AbstractStringArrayIndexNode {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
    }
    getNode(index) {
        const hexadecimalIndex = NumberUtils_1.NumberUtils.toHex(index);
        return NodeFactory_1.NodeFactory.literalNode(hexadecimalIndex);
    }
};
StringArrayHexadecimalNumericStringIndexNode = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], StringArrayHexadecimalNumericStringIndexNode);
exports.StringArrayHexadecimalNumericStringIndexNode = StringArrayHexadecimalNumericStringIndexNode;
