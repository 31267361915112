"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectPatternPropertiesTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeLexicalScopeUtils_1 = require("../../node/NodeLexicalScopeUtils");
const NodeUtils_1 = require("../../node/NodeUtils");
let ObjectPatternPropertiesTransformer = class ObjectPatternPropertiesTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Converting:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isPropertyNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(propertyNode, parentNode) {
        if (!NodeGuards_1.NodeGuards.isObjectPatternNode(parentNode) || !propertyNode.shorthand) {
            return propertyNode;
        }
        if (!this.options.renameGlobals) {
            const lexicalScope = NodeLexicalScopeUtils_1.NodeLexicalScopeUtils.getLexicalScope(propertyNode);
            const shouldNotTransformGlobalPropertyNode = !!lexicalScope && NodeGuards_1.NodeGuards.isProgramNode(lexicalScope);
            if (shouldNotTransformGlobalPropertyNode) {
                return propertyNode;
            }
        }
        propertyNode.shorthand = false;
        propertyNode.value = NodeUtils_1.NodeUtils.clone(propertyNode.value);
        NodeUtils_1.NodeUtils.parentizeNode(propertyNode.value, propertyNode);
        return propertyNode;
    }
};
ObjectPatternPropertiesTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], ObjectPatternPropertiesTransformer);
exports.ObjectPatternPropertiesTransformer = ObjectPatternPropertiesTransformer;
