"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LevelledTopologicalSorter = void 0;
const inversify_1 = require("inversify");
let LevelledTopologicalSorter = class LevelledTopologicalSorter {
    constructor() {
        this.graph = new Map();
    }
    add(precedent, consequent = null) {
        if (consequent !== null) {
            return this.link(precedent, consequent);
        }
        return this.register(precedent);
    }
    sort() {
        const consequents = Array.from(this.graph.keys());
        const results = [];
        const marks = {};
        for (const consequent of consequents) {
            if (marks[consequent] !== undefined) {
                continue;
            }
            this.visit(results, marks, consequent);
        }
        return results;
    }
    sortByGroups() {
        this.sort();
        const resultItemsGroups = [];
        while (this.hasNodes()) {
            const rootNodes = this.findRootNodes();
            resultItemsGroups.push(rootNodes);
            for (const rootNode of rootNodes) {
                this.delete(rootNode);
            }
        }
        return resultItemsGroups;
    }
    delete(consequent) {
        const precedents = this.getPrecedents(consequent);
        if (precedents.length) {
            throw new Error(`Unable to remove non-root node: ${consequent}`);
        }
        this.graph.delete(consequent);
        const precedentsGroups = Array.from(this.graph.values());
        for (const precedentsGroup of precedentsGroups) {
            const precedentsCount = precedentsGroup.length - 1;
            for (let index = precedentsCount; index >= 0; index = index - 1) {
                if (precedentsGroup[index] !== consequent) {
                    continue;
                }
                precedentsGroup.splice(index, 1);
            }
        }
    }
    findRootNodes() {
        const consequents = Array.from(this.graph.keys());
        const rootNodes = [];
        for (const consequent of consequents) {
            if (!this.hasPrecedents(consequent)) {
                rootNodes.push(consequent);
            }
        }
        return rootNodes;
    }
    getPrecedents(consequent) {
        const precedents = this.graph.get(consequent);
        if (!precedents) {
            throw new Error(`Unknown node: ${consequent}`);
        }
        return precedents;
    }
    hasNodes() {
        return this.graph.size > 0;
    }
    hasPrecedents(consequent) {
        return this.getPrecedents(consequent).length > 0;
    }
    link(precedent, consequent) {
        this.register(precedent);
        this.register(consequent);
        const target = this.graph.get(consequent);
        if (target && !target.includes(precedent)) {
            target.push(precedent);
        }
        return this;
    }
    register(name) {
        if (!this.graph.has(name)) {
            this.graph.set(name, []);
        }
        return this;
    }
    visit(results, marks, name) {
        const mark = marks[name];
        if (mark === 'visiting') {
            throw new Error(`Detected cycle involving node: ${name}`);
        }
        if (mark) {
            return;
        }
        marks[name] = 'visiting';
        const precedents = this.getPrecedents(name);
        for (const precedent of precedents) {
            this.visit(results, marks, precedent);
        }
        marks[name] = 'ok';
        results.push(name);
        return;
    }
};
LevelledTopologicalSorter = __decorate([
    (0, inversify_1.injectable)()
], LevelledTopologicalSorter);
exports.LevelledTopologicalSorter = LevelledTopologicalSorter;
