"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectExpressionToVariableDeclarationExtractor = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const ObjectExpressionKeysTransformerCustomNode_1 = require("../../../enums/custom-nodes/ObjectExpressionKeysTransformerCustomNode");
const NodeAppender_1 = require("../../../node/NodeAppender");
const NodeGuards_1 = require("../../../node/NodeGuards");
const NodeStatementUtils_1 = require("../../../node/NodeStatementUtils");
const NodeUtils_1 = require("../../../node/NodeUtils");
const NodeLexicalScopeUtils_1 = require("../../../node/NodeLexicalScopeUtils");
let ObjectExpressionToVariableDeclarationExtractor = class ObjectExpressionToVariableDeclarationExtractor {
    constructor(objectExpressionKeysTransformerCustomNodeFactory) {
        this.objectExpressionKeysTransformerCustomNodeFactory = objectExpressionKeysTransformerCustomNodeFactory;
    }
    extract(objectExpressionNode, hostStatement) {
        return this.transformObjectExpressionToVariableDeclaration(objectExpressionNode, hostStatement);
    }
    transformObjectExpressionToVariableDeclaration(objectExpressionNode, hostStatement) {
        var _a;
        const hostNodeWithStatements = NodeStatementUtils_1.NodeStatementUtils.getScopeOfNode(hostStatement);
        const lexicalScopeNode = NodeGuards_1.NodeGuards.isNodeWithLexicalScope(hostNodeWithStatements)
            ? hostNodeWithStatements
            : (_a = NodeLexicalScopeUtils_1.NodeLexicalScopeUtils.getLexicalScope(hostNodeWithStatements)) !== null && _a !== void 0 ? _a : null;
        if (!lexicalScopeNode) {
            throw new Error('Cannot find lexical scope node for the host statement node');
        }
        const properties = objectExpressionNode.properties;
        const newObjectExpressionHostStatement = this.getObjectExpressionHostNode(lexicalScopeNode, properties);
        const statementsToInsert = [newObjectExpressionHostStatement];
        NodeAppender_1.NodeAppender.insertBefore(hostNodeWithStatements, statementsToInsert, hostStatement);
        NodeUtils_1.NodeUtils.parentizeAst(newObjectExpressionHostStatement);
        NodeUtils_1.NodeUtils.parentizeNode(newObjectExpressionHostStatement, hostNodeWithStatements);
        const newObjectExpressionIdentifier = this.getObjectExpressionIdentifierNode(newObjectExpressionHostStatement);
        const newObjectExpressionNode = this.getObjectExpressionNode(newObjectExpressionHostStatement);
        return {
            nodeToReplace: newObjectExpressionIdentifier,
            objectExpressionHostStatement: newObjectExpressionHostStatement,
            objectExpressionNode: newObjectExpressionNode
        };
    }
    getObjectExpressionHostNode(lexicalScopeNode, properties) {
        const variableDeclarationHostNodeCustomNode = this.objectExpressionKeysTransformerCustomNodeFactory(ObjectExpressionKeysTransformerCustomNode_1.ObjectExpressionKeysTransformerCustomNode.ObjectExpressionVariableDeclarationHostNode);
        variableDeclarationHostNodeCustomNode.initialize(lexicalScopeNode, properties);
        const statementNode = variableDeclarationHostNodeCustomNode.getNode()[0];
        if (!statementNode
            || !NodeGuards_1.NodeGuards.isVariableDeclarationNode(statementNode)) {
            throw new Error('`objectExpressionHostCustomNode.getNode()[0]` should returns array with `VariableDeclaration` node');
        }
        return statementNode;
    }
    getObjectExpressionIdentifierNode(objectExpressionHostNode) {
        const newObjectExpressionIdentifierNode = objectExpressionHostNode.declarations[0].id;
        if (!NodeGuards_1.NodeGuards.isIdentifierNode(newObjectExpressionIdentifierNode)) {
            throw new Error('`objectExpressionHostNode` should contain `VariableDeclarator` node with `Identifier` id property');
        }
        return newObjectExpressionIdentifierNode;
    }
    getObjectExpressionNode(objectExpressionHostNode) {
        var _a;
        const newObjectExpressionNode = (_a = objectExpressionHostNode.declarations[0].init) !== null && _a !== void 0 ? _a : null;
        if (!newObjectExpressionNode || !NodeGuards_1.NodeGuards.isObjectExpressionNode(newObjectExpressionNode)) {
            throw new Error('`objectExpressionHostNode` should contain `VariableDeclarator` node with `ObjectExpression` init property');
        }
        return newObjectExpressionNode;
    }
};
ObjectExpressionToVariableDeclarationExtractor = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IObjectExpressionKeysTransformerCustomNode)),
    __metadata("design:paramtypes", [Function])
], ObjectExpressionToVariableDeclarationExtractor);
exports.ObjectExpressionToVariableDeclarationExtractor = ObjectExpressionToVariableDeclarationExtractor;
