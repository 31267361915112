"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalVariableTemplate2 = void 0;
function GlobalVariableTemplate2() {
    return `
        const getGlobal = function () {
            let globalObject;
        
            try {
                globalObject = Function('return (function() ' + '{}.constructor("return this")( )' + ');')();
            } catch (e) {
                globalObject = window;
            }
            
            return globalObject;
        };
        const that = getGlobal();
    `;
}
exports.GlobalVariableTemplate2 = GlobalVariableTemplate2;
