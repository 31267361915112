"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsInputFileName = void 0;
const class_validator_1 = require("class-validator");
const SourceMapSourcesMode_1 = require("../../enums/source-map/SourceMapSourcesMode");
const IsInputFileName = () => {
    return (target, key) => {
        (0, class_validator_1.IsString)()(target, key);
        (0, class_validator_1.ValidateIf)(({ sourceMapSourcesMode }) => {
            return sourceMapSourcesMode === SourceMapSourcesMode_1.SourceMapSourcesMode.Sources;
        })(target, key);
        (0, class_validator_1.IsNotEmpty)()(target, key);
    };
};
exports.IsInputFileName = IsInputFileName;
