"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.simplifyingTransformersModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const BlockStatementSimplifyTransformer_1 = require("../../../node-transformers/simplifying-transformers/BlockStatementSimplifyTransformer");
const ExpressionStatementsMergeTransformer_1 = require("../../../node-transformers/simplifying-transformers/ExpressionStatementsMergeTransformer");
const IfStatementSimplifyTransformer_1 = require("../../../node-transformers/simplifying-transformers/IfStatementSimplifyTransformer");
const VariableDeclarationsMergeTransformer_1 = require("../../../node-transformers/simplifying-transformers/VariableDeclarationsMergeTransformer");
exports.simplifyingTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(BlockStatementSimplifyTransformer_1.BlockStatementSimplifyTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.BlockStatementSimplifyTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ExpressionStatementsMergeTransformer_1.ExpressionStatementsMergeTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ExpressionStatementsMergeTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(IfStatementSimplifyTransformer_1.IfStatementSimplifyTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.IfStatementSimplifyTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(VariableDeclarationsMergeTransformer_1.VariableDeclarationsMergeTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.VariableDeclarationsMergeTransformer);
});
