"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfDefendingRule = void 0;
const SelfDefendingRule = (options) => {
    if (options.selfDefending) {
        options = {
            ...options,
            compact: true,
            selfDefending: true
        };
    }
    return options;
};
exports.SelfDefendingRule = SelfDefendingRule;
