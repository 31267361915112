"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renamePropertiesTransformersModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const RenamePropertiesReplacer_1 = require("../../../node-transformers/rename-properties-transformers/replacer/RenamePropertiesReplacer");
const RenamePropertiesTransformer_1 = require("../../../node-transformers/rename-properties-transformers/RenamePropertiesTransformer");
exports.renamePropertiesTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(RenamePropertiesTransformer_1.RenamePropertiesTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.RenamePropertiesTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IRenamePropertiesReplacer)
        .to(RenamePropertiesReplacer_1.RenamePropertiesReplacer);
});
