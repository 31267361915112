"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var StringArrayCodeHelperGroup_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayCodeHelperGroup = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const Initializable_1 = require("../../../decorators/Initializable");
const CustomCodeHelper_1 = require("../../../enums/custom-code-helpers/CustomCodeHelper");
const StringArrayEncoding_1 = require("../../../enums/node-transformers/string-array-transformers/StringArrayEncoding");
const AbstractCustomCodeHelperGroup_1 = require("../../AbstractCustomCodeHelperGroup");
const NodeAppender_1 = require("../../../node/NodeAppender");
let StringArrayCodeHelperGroup = StringArrayCodeHelperGroup_1 = class StringArrayCodeHelperGroup extends AbstractCustomCodeHelperGroup_1.AbstractCustomCodeHelperGroup {
    constructor(customCodeHelperFactory, stringArrayStorage, identifierNamesGeneratorFactory, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, randomGenerator, options);
        this.customCodeHelperFactory = customCodeHelperFactory;
        this.stringArrayStorage = stringArrayStorage;
    }
    appendOnFinalizingStage(nodeWithStatements, callsGraphData) {
        if (!this.stringArrayStorage.getLength()) {
            return;
        }
        const scopeStatements = NodeAppender_1.NodeAppender.getScopeStatements(nodeWithStatements);
        this.appendCustomNodeIfExist(CustomCodeHelper_1.CustomCodeHelper.StringArray, (customCodeHelper) => {
            NodeAppender_1.NodeAppender.insertAtIndex(nodeWithStatements, customCodeHelper.getNode(), this.getScopeStatementRandomIndex(scopeStatements));
        });
        for (const stringArrayEncoding of this.options.stringArrayEncoding) {
            const stringArrayCallsWrapperCodeHelperName = this.getStringArrayCallsWrapperCodeHelperName(stringArrayEncoding);
            this.appendCustomNodeIfExist(stringArrayCallsWrapperCodeHelperName, (customCodeHelper) => {
                NodeAppender_1.NodeAppender.insertAtIndex(nodeWithStatements, customCodeHelper.getNode(), this.getScopeStatementRandomIndex(scopeStatements));
            });
        }
    }
    initialize() {
        this.customCodeHelpers = new Map();
        if (!this.options.stringArray) {
            return;
        }
        const stringArrayFunctionName = this.stringArrayStorage.getStorageName();
        const stringArrayCodeHelper = this.customCodeHelperFactory(CustomCodeHelper_1.CustomCodeHelper.StringArray);
        stringArrayCodeHelper.initialize(this.stringArrayStorage, stringArrayFunctionName);
        this.customCodeHelpers.set(CustomCodeHelper_1.CustomCodeHelper.StringArray, stringArrayCodeHelper);
        for (const stringArrayEncoding of this.options.stringArrayEncoding) {
            const stringArrayCallsWrapperCodeHelperName = this.getStringArrayCallsWrapperCodeHelperName(stringArrayEncoding);
            const stringArrayCallsWrapperCodeHelper = this.customCodeHelperFactory(stringArrayCallsWrapperCodeHelperName);
            const stringArrayCallsWrapperName = this.stringArrayStorage.getStorageCallsWrapperName(stringArrayEncoding);
            stringArrayCallsWrapperCodeHelper.initialize(stringArrayFunctionName, stringArrayCallsWrapperName, this.stringArrayStorage.getIndexShiftAmount());
            this.customCodeHelpers.set(stringArrayCallsWrapperCodeHelperName, stringArrayCallsWrapperCodeHelper);
        }
    }
    getStringArrayCallsWrapperCodeHelperName(stringArrayEncoding) {
        var _a;
        return (_a = StringArrayCodeHelperGroup_1
            .stringArrayCallsWrapperCodeHelperMap.get(stringArrayEncoding)) !== null && _a !== void 0 ? _a : CustomCodeHelper_1.CustomCodeHelper.StringArrayCallsWrapper;
    }
    getScopeStatementRandomIndex(scopeStatements) {
        return this.randomGenerator.getRandomInteger(0, Math.max(0, scopeStatements.length));
    }
};
StringArrayCodeHelperGroup.stringArrayCallsWrapperCodeHelperMap = new Map([
    [StringArrayEncoding_1.StringArrayEncoding.None, CustomCodeHelper_1.CustomCodeHelper.StringArrayCallsWrapper],
    [StringArrayEncoding_1.StringArrayEncoding.Base64, CustomCodeHelper_1.CustomCodeHelper.StringArrayCallsWrapperBase64],
    [StringArrayEncoding_1.StringArrayEncoding.Rc4, CustomCodeHelper_1.CustomCodeHelper.StringArrayCallsWrapperRc4]
]);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Map)
], StringArrayCodeHelperGroup.prototype, "customCodeHelpers", void 0);
StringArrayCodeHelperGroup = StringArrayCodeHelperGroup_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICustomCodeHelper)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Function, Object, Object])
], StringArrayCodeHelperGroup);
exports.StringArrayCodeHelperGroup = StringArrayCodeHelperGroup;
