"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomCodeHelperGroup = void 0;
var CustomCodeHelperGroup;
(function (CustomCodeHelperGroup) {
    CustomCodeHelperGroup["ConsoleOutput"] = "ConsoleOutput";
    CustomCodeHelperGroup["DebugProtection"] = "DebugProtection";
    CustomCodeHelperGroup["DomainLock"] = "DomainLock";
    CustomCodeHelperGroup["SelfDefending"] = "SelfDefending";
    CustomCodeHelperGroup["StringArray"] = "StringArray";
})(CustomCodeHelperGroup = exports.CustomCodeHelperGroup || (exports.CustomCodeHelperGroup = {}));
