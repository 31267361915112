"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionsPreset = void 0;
const Utils_1 = require("../../../utils/Utils");
exports.OptionsPreset = Utils_1.Utils.makeEnum({
    Default: 'default',
    LowObfuscation: 'low-obfuscation',
    MediumObfuscation: 'medium-obfuscation',
    HighObfuscation: 'high-obfuscation'
});
