"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeLexicalScopeUtils = void 0;
const NodeGuards_1 = require("./NodeGuards");
class NodeLexicalScopeUtils {
    static getLexicalScope(node) {
        return NodeLexicalScopeUtils.getLexicalScopesRecursive(node, 1)[0];
    }
    static getLexicalScopes(node) {
        return NodeLexicalScopeUtils.getLexicalScopesRecursive(node);
    }
    static getLexicalScopesRecursive(node, maxSize = Infinity, nodesWithLexicalScope = [], depth = 0) {
        if (nodesWithLexicalScope.length >= maxSize) {
            return nodesWithLexicalScope;
        }
        const parentNode = node.parentNode;
        if (!parentNode) {
            throw new ReferenceError('`parentNode` property of given node is `undefined`');
        }
        if (NodeGuards_1.NodeGuards.isNodeWithLexicalScope(node)) {
            nodesWithLexicalScope.push(node);
        }
        if (node !== parentNode) {
            return NodeLexicalScopeUtils.getLexicalScopesRecursive(parentNode, maxSize, nodesWithLexicalScope, ++depth);
        }
        return nodesWithLexicalScope;
    }
}
exports.NodeLexicalScopeUtils = NodeLexicalScopeUtils;
