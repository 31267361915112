"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayRC4DecodeTemplate = void 0;
function StringArrayRC4DecodeTemplate(randomGenerator) {
    const identifierLength = 6;
    const initializedIdentifier = randomGenerator.getRandomString(identifierLength);
    const rc4Identifier = randomGenerator.getRandomString(identifierLength);
    const onceIdentifier = randomGenerator.getRandomString(identifierLength);
    return `
        if ({stringArrayCallsWrapperName}.${initializedIdentifier} === undefined) {
            {atobPolyfill}
            {rc4Polyfill}
            {stringArrayCallsWrapperName}.${rc4Identifier} = {rc4FunctionName};
            
            {stringArrayCacheName} = arguments;
            
            {stringArrayCallsWrapperName}.${initializedIdentifier} = true;
        }
  
        const firstValue = stringArray[0];
        const cacheKey = index + firstValue;
        const cachedValue = {stringArrayCacheName}[cacheKey];

        if (!cachedValue) {
            if ({stringArrayCallsWrapperName}.${onceIdentifier} === undefined) {
                {selfDefendingCode}
                
                {stringArrayCallsWrapperName}.${onceIdentifier} = true;
            }
            
            value = {stringArrayCallsWrapperName}.${rc4Identifier}(value, key);
            {stringArrayCacheName}[cacheKey] = value;
        } else {
            value = cachedValue;
        }
    `;
}
exports.StringArrayRC4DecodeTemplate = StringArrayRC4DecodeTemplate;
