"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayCallsWrapperBase64CodeHelper = void 0;
const inversify_1 = require("inversify");
const AtobTemplate_1 = require("./templates/string-array-calls-wrapper/AtobTemplate");
const StringArrayBase64DecodeTemplate_1 = require("./templates/string-array-calls-wrapper/StringArrayBase64DecodeTemplate");
const StringArrayCallsWrapperCodeHelper_1 = require("./StringArrayCallsWrapperCodeHelper");
let StringArrayCallsWrapperBase64CodeHelper = class StringArrayCallsWrapperBase64CodeHelper extends StringArrayCallsWrapperCodeHelper_1.StringArrayCallsWrapperCodeHelper {
    getDecodeStringArrayTemplate() {
        const atobFunctionName = this.randomGenerator.getRandomString(6);
        const atobPolyfill = this.customCodeHelperFormatter.formatTemplate((0, AtobTemplate_1.AtobTemplate)(this.options.selfDefending), {
            atobFunctionName: atobFunctionName
        });
        const selfDefendingCode = this.getSelfDefendingTemplate();
        return this.customCodeHelperFormatter.formatTemplate((0, StringArrayBase64DecodeTemplate_1.StringArrayBase64DecodeTemplate)(this.randomGenerator), {
            atobPolyfill,
            atobFunctionName,
            selfDefendingCode,
            stringArrayCallsWrapperName: this.stringArrayCallsWrapperName,
            stringArrayCacheName: this.stringArrayCacheName
        });
    }
};
StringArrayCallsWrapperBase64CodeHelper = __decorate([
    (0, inversify_1.injectable)()
], StringArrayCallsWrapperBase64CodeHelper);
exports.StringArrayCallsWrapperBase64CodeHelper = StringArrayCallsWrapperBase64CodeHelper;
