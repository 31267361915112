"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AtobTemplate = void 0;
const Base64AlphabetSwapped_1 = require("../../../../constants/Base64AlphabetSwapped");
function AtobTemplate(selfDefending) {
    return `
        var {atobFunctionName} = function (input) {
            const chars = '${Base64AlphabetSwapped_1.base64alphabetSwapped}';

            let output = '';
            let tempEncodedString = '';
            ${selfDefending ? 'let func = output + {atobFunctionName};' : ''}
            
            for (
                let bc = 0, bs, buffer, idx = 0;
                buffer = input.charAt(idx++);
                ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer, bc++ % 4)
                    ? output += ${(() => {
        const basePart = 'String.fromCharCode(255 & bs >> (-2 * bc & 6))';
        return selfDefending
            ? `((func.charCodeAt(idx + 10) - 10 !== 0) ? ${basePart} : bc)`
            : basePart;
    })()}
                    : 0
            ) {
                buffer = chars.indexOf(buffer);
            }
            
             for (let k = 0, length = output.length; k < length; k++) {
                tempEncodedString += '%' + ('00' + output.charCodeAt(k).toString(16)).slice(-2);
            }
        
            return decodeURIComponent(tempEncodedString);
        };
    `;
}
exports.AtobTemplate = AtobTemplate;
