"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var TemplateLiteralTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateLiteralTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeUtils_1 = require("../../node/NodeUtils");
let TemplateLiteralTransformer = TemplateLiteralTransformer_1 = class TemplateLiteralTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
    }
    static isLiteralNodeWithStringValue(node) {
        return !!node && NodeGuards_1.NodeGuards.isLiteralNode(node) && typeof node.value === 'string';
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Converting:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isTemplateLiteralNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(templateLiteralNode, parentNode) {
        if (NodeGuards_1.NodeGuards.isTaggedTemplateExpressionNode(parentNode)) {
            return templateLiteralNode;
        }
        return this.transformTemplateLiteralNode(templateLiteralNode, parentNode);
    }
    transformTemplateLiteralNode(templateLiteralNode, parentNode) {
        const templateLiteralExpressions = templateLiteralNode.expressions;
        let nodes = [];
        templateLiteralNode.quasis.forEach((templateElement) => {
            if (templateElement.value.cooked === undefined || templateElement.value.cooked === null) {
                return;
            }
            nodes.push(NodeFactory_1.NodeFactory.literalNode(templateElement.value.cooked));
            const expression = templateLiteralExpressions.shift();
            if (!expression) {
                return;
            }
            nodes.push(expression);
        });
        nodes = nodes.filter((node) => {
            return !(NodeGuards_1.NodeGuards.isLiteralNode(node) && node.value === '');
        });
        if (!TemplateLiteralTransformer_1.isLiteralNodeWithStringValue(nodes[0]) &&
            !TemplateLiteralTransformer_1.isLiteralNodeWithStringValue(nodes[1])) {
            nodes.unshift(NodeFactory_1.NodeFactory.literalNode(''));
        }
        let transformedNode;
        if (nodes.length > 1) {
            let root = NodeFactory_1.NodeFactory.binaryExpressionNode('+', nodes.shift(), nodes.shift());
            nodes.forEach((node) => {
                root = NodeFactory_1.NodeFactory.binaryExpressionNode('+', root, node);
            });
            transformedNode = root;
        }
        else {
            transformedNode = nodes[0];
        }
        NodeUtils_1.NodeUtils.parentizeAst(transformedNode);
        NodeUtils_1.NodeUtils.parentizeNode(transformedNode, parentNode);
        return transformedNode;
    }
};
TemplateLiteralTransformer = TemplateLiteralTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], TemplateLiteralTransformer);
exports.TemplateLiteralTransformer = TemplateLiteralTransformer;
