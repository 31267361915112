"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.JavaScriptObfuscator = void 0;
require("reflect-metadata");
const ServiceIdentifiers_1 = require("./container/ServiceIdentifiers");
const InversifyContainerFacade_1 = require("./container/InversifyContainerFacade");
const Options_1 = require("./options/Options");
const Utils_1 = require("./utils/Utils");
class JavaScriptObfuscatorFacade {
    static obfuscate(sourceCode, inputOptions = {}) {
        const inversifyContainerFacade = new InversifyContainerFacade_1.InversifyContainerFacade();
        inversifyContainerFacade.load(sourceCode, '', inputOptions);
        const javaScriptObfuscator = inversifyContainerFacade
            .get(ServiceIdentifiers_1.ServiceIdentifiers.IJavaScriptObfuscator);
        const obfuscationResult = javaScriptObfuscator.obfuscate(sourceCode);
        inversifyContainerFacade.unload();
        return obfuscationResult;
    }
    static obfuscateMultiple(sourceCodesObject, inputOptions = {}) {
        if (typeof sourceCodesObject !== 'object') {
            throw new Error('Source codes object should be a plain object');
        }
        return Object
            .keys(sourceCodesObject)
            .reduce((acc, sourceCodeIdentifier, index) => {
            const identifiersPrefix = Utils_1.Utils.getIdentifiersPrefixForMultipleSources(inputOptions.identifiersPrefix, index);
            const sourceCode = sourceCodesObject[sourceCodeIdentifier];
            const sourceCodeOptions = {
                ...inputOptions,
                identifiersPrefix
            };
            return {
                ...acc,
                [sourceCodeIdentifier]: JavaScriptObfuscatorFacade.obfuscate(sourceCode, sourceCodeOptions)
            };
        }, {});
    }
    static getOptionsByPreset(optionsPreset) {
        return Options_1.Options.getOptionsByPreset(optionsPreset);
    }
}
exports.JavaScriptObfuscator = JavaScriptObfuscatorFacade;
JavaScriptObfuscatorFacade.version = (_a = process.env.VERSION) !== null && _a !== void 0 ? _a : 'unknown';
