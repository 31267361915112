"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeLiteralUtils = void 0;
const NodeGuards_1 = require("./NodeGuards");
class NodeLiteralUtils {
    static isNumberLiteralNode(literalNode) {
        return typeof literalNode.value === 'number';
    }
    static isStringLiteralNode(literalNode) {
        return typeof literalNode.value === 'string';
    }
    static isProhibitedLiteralNode(literalNode, parentNode) {
        if (NodeGuards_1.NodeGuards.isPropertyNode(parentNode) && !parentNode.computed && parentNode.key === literalNode) {
            return true;
        }
        if (NodeGuards_1.NodeGuards.isImportDeclarationNode(parentNode)) {
            return true;
        }
        if (NodeGuards_1.NodeGuards.isExportAllDeclarationNode(parentNode) || NodeGuards_1.NodeGuards.isExportNamedDeclarationNode(parentNode)) {
            return true;
        }
        return false;
    }
}
exports.NodeLiteralUtils = NodeLiteralUtils;
