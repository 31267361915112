"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockStatementControlFlowFlatteningNode = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const StringSeparator_1 = require("../../enums/StringSeparator");
const Initializable_1 = require("../../decorators/Initializable");
const AbstractCustomNode_1 = require("../AbstractCustomNode");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeUtils_1 = require("../../node/NodeUtils");
let BlockStatementControlFlowFlatteningNode = class BlockStatementControlFlowFlatteningNode extends AbstractCustomNode_1.AbstractCustomNode {
    constructor(identifierNamesGeneratorFactory, customCodeHelperFormatter, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, customCodeHelperFormatter, randomGenerator, options);
    }
    initialize(blockStatementBody, shuffledKeys, originalKeysIndexesInShuffledArray) {
        this.blockStatementBody = blockStatementBody;
        this.shuffledKeys = shuffledKeys;
        this.originalKeysIndexesInShuffledArray = originalKeysIndexesInShuffledArray;
    }
    getNodeStructure() {
        const controllerIdentifierName = this.randomGenerator.getRandomString(6);
        const indexIdentifierName = this.randomGenerator.getRandomString(6);
        const structure = NodeFactory_1.NodeFactory.blockStatementNode([
            NodeFactory_1.NodeFactory.variableDeclarationNode([
                NodeFactory_1.NodeFactory.variableDeclaratorNode(NodeFactory_1.NodeFactory.identifierNode(controllerIdentifierName), NodeFactory_1.NodeFactory.callExpressionNode(NodeFactory_1.NodeFactory.memberExpressionNode(NodeFactory_1.NodeFactory.literalNode(this.originalKeysIndexesInShuffledArray.join(StringSeparator_1.StringSeparator.VerticalLine)), NodeFactory_1.NodeFactory.identifierNode('split')), [
                    NodeFactory_1.NodeFactory.literalNode(StringSeparator_1.StringSeparator.VerticalLine)
                ]))
            ], 'const'),
            NodeFactory_1.NodeFactory.variableDeclarationNode([
                NodeFactory_1.NodeFactory.variableDeclaratorNode(NodeFactory_1.NodeFactory.identifierNode(indexIdentifierName), NodeFactory_1.NodeFactory.literalNode(0))
            ], 'let'),
            NodeFactory_1.NodeFactory.whileStatementNode(NodeFactory_1.NodeFactory.literalNode(true), NodeFactory_1.NodeFactory.blockStatementNode([
                NodeFactory_1.NodeFactory.switchStatementNode(NodeFactory_1.NodeFactory.memberExpressionNode(NodeFactory_1.NodeFactory.identifierNode(controllerIdentifierName), NodeFactory_1.NodeFactory.updateExpressionNode('++', NodeFactory_1.NodeFactory.identifierNode(indexIdentifierName)), true), this.shuffledKeys.map((key, index) => {
                    const statement = this.blockStatementBody[key];
                    const consequent = [statement];
                    if (!NodeGuards_1.NodeGuards.isReturnStatementNode(statement)) {
                        consequent.push(NodeFactory_1.NodeFactory.continueStatement());
                    }
                    return NodeFactory_1.NodeFactory.switchCaseNode(NodeFactory_1.NodeFactory.literalNode(String(index)), consequent);
                })),
                NodeFactory_1.NodeFactory.breakStatement()
            ]))
        ]);
        NodeUtils_1.NodeUtils.parentizeAst(structure);
        return [structure];
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Array)
], BlockStatementControlFlowFlatteningNode.prototype, "blockStatementBody", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Array)
], BlockStatementControlFlowFlatteningNode.prototype, "originalKeysIndexesInShuffledArray", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Array)
], BlockStatementControlFlowFlatteningNode.prototype, "shuffledKeys", void 0);
BlockStatementControlFlowFlatteningNode = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object, Object])
], BlockStatementControlFlowFlatteningNode);
exports.BlockStatementControlFlowFlatteningNode = BlockStatementControlFlowFlatteningNode;
