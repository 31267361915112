"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CryptUtils = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../container/ServiceIdentifiers");
const Base64Alphabet_1 = require("../constants/Base64Alphabet");
const RandomGenerator_1 = require("./RandomGenerator");
const Utils_1 = require("./Utils");
let CryptUtils = class CryptUtils {
    constructor(randomGenerator) {
        this.base64Alphabet = Base64Alphabet_1.base64alphabet;
        this.randomGenerator = randomGenerator;
    }
    btoa(string) {
        const chars = this.base64Alphabet;
        let output = '';
        string = encodeURIComponent(string).replace(/%([0-9A-F]{2})/g, (match, p1) => {
            return String.fromCharCode(parseInt(`${Utils_1.Utils.hexadecimalPrefix}${p1}`, 16));
        });
        for (let block, charCode, idx = 0, map = chars; string.charAt(idx | 0) || (map = '=', idx % 1); output += map.charAt(63 & block >> 8 - idx % 1 * 8)) {
            charCode = string.charCodeAt(idx += 3 / 4);
            if (charCode > 0xFF) {
                throw new Error('\'btoa\' failed: The string to be encoded contains characters outside of the Latin1 range.');
            }
            block = block << 8 | charCode;
        }
        return output;
    }
    hideString(str, length) {
        const escapeRegExp = (s) => s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        const randomMerge = (s1, s2) => {
            let i1 = -1;
            let i2 = -1;
            let result = '';
            while (i1 < s1.length || i2 < s2.length) {
                if (this.randomGenerator.getMathRandom() < 0.5 && i2 < s2.length) {
                    result += s2.charAt(++i2);
                }
                else {
                    result += s1.charAt(++i1);
                }
            }
            return result;
        };
        const randomString = this.randomGenerator.getRandomGenerator().string({
            length: length,
            pool: RandomGenerator_1.RandomGenerator.randomGeneratorPool
        });
        let randomStringDiff = randomString.replace(new RegExp(`[${escapeRegExp(str)}]`, 'g'), '');
        const randomStringDiffArray = randomStringDiff.split('');
        this.randomGenerator.getRandomGenerator().shuffle(randomStringDiffArray);
        randomStringDiff = randomStringDiffArray.join('');
        return [randomMerge(str, randomStringDiff), randomStringDiff];
    }
    rc4(string, key) {
        const s = [];
        let j = 0;
        let x;
        let result = '';
        for (var i = 0; i < 256; i++) {
            s[i] = i;
        }
        for (i = 0; i < 256; i++) {
            j = (j + s[i] + key.charCodeAt(i % key.length)) % 256;
            x = s[i];
            s[i] = s[j];
            s[j] = x;
        }
        i = 0;
        j = 0;
        for (let y = 0; y < string.length; y++) {
            i = (i + 1) % 256;
            j = (j + s[i]) % 256;
            x = s[i];
            s[i] = s[j];
            s[j] = x;
            result += String.fromCharCode(string.charCodeAt(y) ^ s[(s[i] + s[j]) % 256]);
        }
        return result;
    }
};
CryptUtils = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __metadata("design:paramtypes", [Object])
], CryptUtils);
exports.CryptUtils = CryptUtils;
