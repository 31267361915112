"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var ScopeIdentifiersTraverser_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScopeIdentifiersTraverser = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../container/ServiceIdentifiers");
const NodeGuards_1 = require("./NodeGuards");
let ScopeIdentifiersTraverser = ScopeIdentifiersTraverser_1 = class ScopeIdentifiersTraverser {
    constructor(scopeAnalyzer) {
        this.scopeAnalyzer = scopeAnalyzer;
    }
    traverseScopeIdentifiers(programNode, parentNode, callback) {
        this.scopeAnalyzer.analyze(programNode);
        const globalScope = this.scopeAnalyzer.acquireScope(programNode);
        this.traverseScopeIdentifiersRecursive(globalScope, globalScope, callback);
    }
    traverseScopeThroughIdentifiers(programNode, parentNode, callback) {
        this.scopeAnalyzer.analyze(programNode);
        const globalScope = this.scopeAnalyzer.acquireScope(programNode);
        this.traverseScopeThroughIdentifiersRecursive(globalScope, globalScope, callback);
    }
    traverseScopeIdentifiersRecursive(rootScope, currentScope, callback) {
        const variableScope = currentScope.variableScope;
        const variableLexicalScopeNode = NodeGuards_1.NodeGuards.isNodeWithBlockLexicalScope(variableScope.block)
            ? variableScope.block
            : null;
        const isGlobalDeclaration = ScopeIdentifiersTraverser_1.globalScopeNames.includes(variableScope.type);
        if (!variableLexicalScopeNode) {
            return;
        }
        for (const variable of currentScope.variables) {
            if (variable.name === ScopeIdentifiersTraverser_1.argumentsVariableName) {
                continue;
            }
            const isBubblingDeclaration = variable
                .identifiers
                .some((identifier) => identifier.parentNode
                && NodeGuards_1.NodeGuards.isPropertyNode(identifier.parentNode)
                && identifier.parentNode.shorthand);
            callback({
                isGlobalDeclaration,
                isBubblingDeclaration,
                rootScope,
                variable,
                variableScope,
                variableLexicalScopeNode
            });
        }
        for (const childScope of currentScope.childScopes) {
            this.traverseScopeIdentifiersRecursive(rootScope, childScope, callback);
        }
    }
    traverseScopeThroughIdentifiersRecursive(rootScope, currentScope, callback) {
        const variableScope = currentScope.variableScope;
        const variableLexicalScopeNode = NodeGuards_1.NodeGuards.isNodeWithBlockLexicalScope(variableScope.block)
            ? variableScope.block
            : null;
        const isGlobalDeclaration = ScopeIdentifiersTraverser_1.globalScopeNames.includes(variableScope.type);
        if (!variableLexicalScopeNode) {
            return;
        }
        for (const reference of currentScope.through) {
            callback({
                isGlobalDeclaration,
                reference,
                variableLexicalScopeNode
            });
        }
        for (const childScope of currentScope.childScopes) {
            this.traverseScopeThroughIdentifiersRecursive(rootScope, childScope, callback);
        }
    }
};
ScopeIdentifiersTraverser.argumentsVariableName = 'arguments';
ScopeIdentifiersTraverser.globalScopeNames = [
    'global',
    'module'
];
ScopeIdentifiersTraverser = ScopeIdentifiersTraverser_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IScopeAnalyzer)),
    __metadata("design:paramtypes", [Object])
], ScopeIdentifiersTraverser);
exports.ScopeIdentifiersTraverser = ScopeIdentifiersTraverser;
