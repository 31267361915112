"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeTransformersRunner = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../container/ServiceIdentifiers");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const VisitorDirection_1 = require("../enums/node-transformers/VisitorDirection");
const NodeGuards_1 = require("../node/NodeGuards");
const NodeMetadata_1 = require("../node/NodeMetadata");
let NodeTransformersRunner = class NodeTransformersRunner {
    constructor(nodeTransformerFactory, nodeTransformerNamesGroupsBuilder) {
        this.nodeTransformerFactory = nodeTransformerFactory;
        this.nodeTransformerNamesGroupsBuilder = nodeTransformerNamesGroupsBuilder;
    }
    transform(astTree, nodeTransformerNames, nodeTransformationStage) {
        if (!nodeTransformerNames.length) {
            return astTree;
        }
        const normalizedNodeTransformers = this.buildNormalizedNodeTransformers(nodeTransformerNames, nodeTransformationStage);
        const nodeTransformerNamesGroups = this.nodeTransformerNamesGroupsBuilder.build(normalizedNodeTransformers);
        for (const nodeTransformerNamesGroup of nodeTransformerNamesGroups) {
            const enterVisitors = [];
            const leaveVisitors = [];
            for (const nodeTransformerName of nodeTransformerNamesGroup) {
                const nodeTransformer = normalizedNodeTransformers[nodeTransformerName];
                const visitor = nodeTransformer.getVisitor(nodeTransformationStage);
                if (!visitor) {
                    continue;
                }
                if (visitor.enter) {
                    enterVisitors.push({ enter: visitor.enter });
                }
                if (visitor.leave) {
                    leaveVisitors.push({ leave: visitor.leave });
                }
            }
            if (!enterVisitors.length && !leaveVisitors.length) {
                continue;
            }
            estraverse.replace(astTree, {
                enter: this.mergeVisitorsForDirection(enterVisitors, VisitorDirection_1.VisitorDirection.Enter),
                leave: this.mergeVisitorsForDirection(leaveVisitors, VisitorDirection_1.VisitorDirection.Leave)
            });
        }
        return astTree;
    }
    buildNormalizedNodeTransformers(nodeTransformerNames, nodeTransformationStage) {
        return nodeTransformerNames
            .reduce((acc, nodeTransformerName) => {
            const nodeTransformer = this.nodeTransformerFactory(nodeTransformerName);
            if (!nodeTransformer.getVisitor(nodeTransformationStage)) {
                return acc;
            }
            return {
                ...acc,
                [nodeTransformerName]: nodeTransformer
            };
        }, {});
    }
    mergeVisitorsForDirection(visitors, direction) {
        const visitorsLength = visitors.length;
        if (!visitorsLength) {
            return (node, parentNode) => node;
        }
        return (node, parentNode) => {
            if (NodeMetadata_1.NodeMetadata.isIgnoredNode(node)) {
                return estraverse.VisitorOption.Skip;
            }
            for (let i = 0; i < visitorsLength; i++) {
                const visitorFunction = visitors[i][direction];
                if (!visitorFunction) {
                    continue;
                }
                const visitorResult = visitorFunction(node, parentNode);
                const isValidVisitorResult = visitorResult && NodeGuards_1.NodeGuards.isNode(visitorResult);
                if (!isValidVisitorResult) {
                    continue;
                }
                node = visitorResult;
            }
            return node;
        };
    }
};
NodeTransformersRunner = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__INodeTransformer)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformerNamesGroupsBuilder)),
    __metadata("design:paramtypes", [Function, Object])
], NodeTransformersRunner);
exports.NodeTransformersRunner = NodeTransformersRunner;
