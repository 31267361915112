"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayControlFlowTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const ControlFlowReplacer_1 = require("../../enums/node-transformers/control-flow-transformers/control-flow-replacers/ControlFlowReplacer");
const ControlFlowStorage_1 = require("../../enums/storages/ControlFlowStorage");
const NodeType_1 = require("../../enums/node/NodeType");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const FunctionControlFlowTransformer_1 = require("./FunctionControlFlowTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
let StringArrayControlFlowTransformer = class StringArrayControlFlowTransformer extends FunctionControlFlowTransformer_1.FunctionControlFlowTransformer {
    constructor(controlFlowStorageFactoryCreator, controlFlowReplacerFactory, controlFlowCustomNodeFactory, randomGenerator, options) {
        super(controlFlowStorageFactoryCreator, controlFlowReplacerFactory, controlFlowCustomNodeFactory, randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.StringArrayTransformer,
            NodeTransformer_1.NodeTransformer.StringArrayRotateFunctionTransformer,
            NodeTransformer_1.NodeTransformer.StringArrayScopeCallsWrapperTransformer
        ];
        this.controlFlowReplacersMap = new Map([
            [NodeType_1.NodeType.Literal, ControlFlowReplacer_1.ControlFlowReplacer.StringArrayCallControlFlowReplacer]
        ]);
        this.controlFlowStorageNodes = new WeakSet();
        this.controlFlowStorageFactory = controlFlowStorageFactoryCreator(ControlFlowStorage_1.ControlFlowStorage.StringControlFlowStorage);
    }
    getVisitor(nodeTransformationStage) {
        if (!this.options.stringArrayCallsTransform) {
            return null;
        }
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.StringArray:
                return {
                    leave: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isFunctionNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformFunctionBodyNode(node, parentNode, functionNode, controlFlowStorage) {
        const isControlFlowStorageNode = NodeGuards_1.NodeGuards.isVariableDeclarationNode(node)
            && this.controlFlowStorageNodes.has(node);
        if (isControlFlowStorageNode) {
            return estraverse.VisitorOption.Skip;
        }
        return super.transformFunctionBodyNode(node, parentNode, functionNode, controlFlowStorage);
    }
    getControlFlowStorage(hostNode) {
        return this.controlFlowStorageFactory();
    }
    appendControlFlowStorageNode(hostNode, controlFlowStorageNode) {
        super.appendControlFlowStorageNode(hostNode, controlFlowStorageNode);
        this.controlFlowStorageNodes.add(controlFlowStorageNode);
    }
    isAllowedTransformationByThreshold() {
        return this.randomGenerator.getMathRandom() <= this.options.stringArrayCallsTransformThreshold;
    }
};
StringArrayControlFlowTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__TControlFlowStorage)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowReplacer)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowCustomNode)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Function, Function, Object, Object])
], StringArrayControlFlowTransformer);
exports.StringArrayControlFlowTransformer = StringArrayControlFlowTransformer;
