"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var EscapeSequenceEncoder_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.EscapeSequenceEncoder = void 0;
const inversify_1 = require("inversify");
let EscapeSequenceEncoder = EscapeSequenceEncoder_1 = class EscapeSequenceEncoder {
    constructor() {
        this.stringsCache = new Map();
    }
    encode(string, encodeAllSymbols) {
        const cacheKey = `${string}-${String(encodeAllSymbols)}`;
        if (this.stringsCache.has(cacheKey)) {
            return this.stringsCache.get(cacheKey);
        }
        const radix = 16;
        const replaceRegExp = new RegExp('[\\s\\S]', 'g');
        let prefix;
        let template;
        const result = string.replace(replaceRegExp, (character) => {
            const shouldEncodeCharacter = encodeAllSymbols
                || EscapeSequenceEncoder_1.forceEscapeCharactersRegExp.test(character);
            if (!shouldEncodeCharacter) {
                return character;
            }
            if (EscapeSequenceEncoder_1.ASCIICharactersRegExp.test(character)) {
                prefix = '\\x';
                template = '00';
            }
            else {
                prefix = '\\u';
                template = '0000';
            }
            return `${prefix}${(template + character.charCodeAt(0).toString(radix)).slice(-template.length)}`;
        });
        this.stringsCache.set(cacheKey, result);
        this.stringsCache.set(`${result}-${String(encodeAllSymbols)}`, result);
        return result;
    }
};
EscapeSequenceEncoder.ASCIICharactersRegExp = /[\x00-\x7F]/;
EscapeSequenceEncoder.forceEscapeCharactersRegExp = /[\x00-\x1F\x7F-\x9F'"\\\s]/;
EscapeSequenceEncoder = EscapeSequenceEncoder_1 = __decorate([
    (0, inversify_1.injectable)()
], EscapeSequenceEncoder);
exports.EscapeSequenceEncoder = EscapeSequenceEncoder;
