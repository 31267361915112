"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebuggerTemplate = void 0;
function DebuggerTemplate() {
    return `
        if (typeof counter === 'string') {
            return (function (arg) {}.constructor('while (true) {}').apply('counter'));
        } else {
            if (('' + counter / counter)['length'] !== 1 || counter % 20 === 0) {
                (function () {return true;}.constructor('debu' + 'gger').call('action'));
            } else {
                (function () {return false;}.constructor('debu' + 'gger').apply('stateObject'));
            }
            
        }
    `;
}
exports.DebuggerTemplate = DebuggerTemplate;
