"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayTemplate = void 0;
function StringArrayTemplate() {
    return `
        function {stringArrayFunctionName} () {
            const {stringArrayName} = [{stringArrayStorageItems}];
            
            {stringArrayFunctionName} = function () {
                return {stringArrayName};
            };
            
            return {stringArrayFunctionName}();
        }
    `;
}
exports.StringArrayTemplate = StringArrayTemplate;
