"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertingTransformersModule = void 0;
const inversify_1 = require("inversify");
const InversifyContainerFacade_1 = require("../../InversifyContainerFacade");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const ObjectExpressionExtractor_1 = require("../../../enums/node-transformers/converting-transformers/properties-extractors/ObjectExpressionExtractor");
const BasePropertiesExtractor_1 = require("../../../node-transformers/converting-transformers/object-expression-extractors/BasePropertiesExtractor");
const BooleanLiteralTransformer_1 = require("../../../node-transformers/converting-transformers/BooleanLiteralTransformer");
const ExportSpecifierTransformer_1 = require("../../../node-transformers/converting-transformers/ExportSpecifierTransformer");
const MemberExpressionTransformer_1 = require("../../../node-transformers/converting-transformers/MemberExpressionTransformer");
const ClassFieldTransformer_1 = require("../../../node-transformers/converting-transformers/ClassFieldTransformer");
const NumberLiteralTransformer_1 = require("../../../node-transformers/converting-transformers/NumberLiteralTransformer");
const NumberToNumericalExpressionTransformer_1 = require("../../../node-transformers/converting-transformers/NumberToNumericalExpressionTransformer");
const ObjectExpressionKeysTransformer_1 = require("../../../node-transformers/converting-transformers/ObjectExpressionKeysTransformer");
const ObjectExpressionToVariableDeclarationExtractor_1 = require("../../../node-transformers/converting-transformers/object-expression-extractors/ObjectExpressionToVariableDeclarationExtractor");
const ObjectExpressionTransformer_1 = require("../../../node-transformers/converting-transformers/ObjectExpressionTransformer");
const ObjectPatternPropertiesTransformer_1 = require("../../../node-transformers/converting-transformers/ObjectPatternPropertiesTransformer");
const SplitStringTransformer_1 = require("../../../node-transformers/converting-transformers/SplitStringTransformer");
const TemplateLiteralTransformer_1 = require("../../../node-transformers/converting-transformers/TemplateLiteralTransformer");
exports.convertingTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(BooleanLiteralTransformer_1.BooleanLiteralTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.BooleanLiteralTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ExportSpecifierTransformer_1.ExportSpecifierTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ExportSpecifierTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(MemberExpressionTransformer_1.MemberExpressionTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.MemberExpressionTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ClassFieldTransformer_1.ClassFieldTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ClassFieldTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(NumberLiteralTransformer_1.NumberLiteralTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.NumberLiteralTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(NumberToNumericalExpressionTransformer_1.NumberToNumericalExpressionTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.NumberToNumericalExpressionTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ObjectExpressionKeysTransformer_1.ObjectExpressionKeysTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ObjectExpressionKeysTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ObjectExpressionTransformer_1.ObjectExpressionTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ObjectExpressionTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ObjectPatternPropertiesTransformer_1.ObjectPatternPropertiesTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ObjectPatternPropertiesTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(SplitStringTransformer_1.SplitStringTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.SplitStringTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(TemplateLiteralTransformer_1.TemplateLiteralTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.TemplateLiteralTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IObjectExpressionExtractor)
        .to(ObjectExpressionToVariableDeclarationExtractor_1.ObjectExpressionToVariableDeclarationExtractor)
        .whenTargetNamed(ObjectExpressionExtractor_1.ObjectExpressionExtractor.ObjectExpressionToVariableDeclarationExtractor);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IObjectExpressionExtractor)
        .to(BasePropertiesExtractor_1.BasePropertiesExtractor)
        .whenTargetNamed(ObjectExpressionExtractor_1.ObjectExpressionExtractor.BasePropertiesExtractor);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IObjectExpressionExtractor)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getCacheFactory(ServiceIdentifiers_1.ServiceIdentifiers.IObjectExpressionExtractor));
});
