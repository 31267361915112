"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeAppender = void 0;
const NodeGuards_1 = require("./NodeGuards");
class NodeAppender {
    static append(nodeWithStatements, statements) {
        statements = NodeAppender.parentizeScopeStatementsBeforeAppend(nodeWithStatements, statements);
        const updatedStatements = NodeAppender.getScopeStatements(nodeWithStatements).concat(statements);
        NodeAppender.setScopeStatements(nodeWithStatements, updatedStatements);
    }
    static appendToOptimalBlockScope(callsGraphData, nodeWithStatements, bodyStatements, index = 0) {
        const targetBlockScope = callsGraphData.length
            ? NodeAppender.getOptimalBlockScope(callsGraphData, index)
            : nodeWithStatements;
        NodeAppender.prepend(targetBlockScope, bodyStatements);
    }
    static getOptimalBlockScope(callsGraphData, index, deep = Infinity) {
        const firstCall = callsGraphData[index];
        if (deep <= 0) {
            throw new Error('Invalid `deep` argument value. Value should be bigger then 0.');
        }
        if (deep > 1 && firstCall.callsGraph.length) {
            return NodeAppender.getOptimalBlockScope(firstCall.callsGraph, 0, --deep);
        }
        else {
            return firstCall.callee;
        }
    }
    static getScopeStatements(nodeWithStatements) {
        if (NodeGuards_1.NodeGuards.isSwitchCaseNode(nodeWithStatements)) {
            return nodeWithStatements.consequent;
        }
        return nodeWithStatements.body;
    }
    static insertBefore(nodeWithStatements, statements, target) {
        const indexInScopeStatement = NodeAppender
            .getScopeStatements(nodeWithStatements)
            .indexOf(target);
        NodeAppender.insertAtIndex(nodeWithStatements, statements, indexInScopeStatement);
    }
    static insertAfter(nodeWithStatements, statements, target) {
        const indexInScopeStatement = NodeAppender
            .getScopeStatements(nodeWithStatements)
            .indexOf(target);
        NodeAppender.insertAtIndex(nodeWithStatements, statements, indexInScopeStatement + 1);
    }
    static insertAtIndex(nodeWithStatements, statements, index) {
        statements = NodeAppender.parentizeScopeStatementsBeforeAppend(nodeWithStatements, statements);
        NodeAppender.setScopeStatements(nodeWithStatements, [
            ...NodeAppender.getScopeStatements(nodeWithStatements).slice(0, index),
            ...statements,
            ...NodeAppender.getScopeStatements(nodeWithStatements).slice(index)
        ]);
    }
    static prepend(nodeWithStatements, statements) {
        statements = NodeAppender.parentizeScopeStatementsBeforeAppend(nodeWithStatements, statements);
        const updatedStatements = statements.concat(NodeAppender.getScopeStatements(nodeWithStatements));
        NodeAppender.setScopeStatements(nodeWithStatements, updatedStatements);
    }
    static remove(nodeWithStatements, statement) {
        const scopeStatements = NodeAppender.getScopeStatements(nodeWithStatements);
        const indexInScopeStatement = scopeStatements.indexOf(statement);
        if (indexInScopeStatement === -1) {
            return;
        }
        const updatedStatements = [...scopeStatements];
        updatedStatements.splice(indexInScopeStatement, 1);
        NodeAppender.setScopeStatements(nodeWithStatements, updatedStatements);
    }
    static parentizeScopeStatementsBeforeAppend(nodeWithStatements, statements) {
        statements.forEach((statement) => {
            statement.parentNode = nodeWithStatements;
        });
        return statements;
    }
    static setScopeStatements(nodeWithStatements, statements) {
        if (NodeGuards_1.NodeGuards.isSwitchCaseNode(nodeWithStatements)) {
            nodeWithStatements.consequent = statements;
            return;
        }
        nodeWithStatements.body = statements;
    }
}
exports.NodeAppender = NodeAppender;
