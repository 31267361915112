"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObfuscationTarget = void 0;
const Utils_1 = require("../utils/Utils");
exports.ObfuscationTarget = Utils_1.Utils.makeEnum({
    Browser: 'browser',
    BrowserNoEval: 'browser-no-eval',
    Node: 'node',
    ServiceWorker: 'service-worker',
});
