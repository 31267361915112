"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nodeTransformersModule = void 0;
const InversifyContainerFacade_1 = require("../../InversifyContainerFacade");
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformerNamesGroupsBuilder_1 = require("../../../node-transformers/NodeTransformerNamesGroupsBuilder");
exports.nodeTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__INodeTransformer)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getCacheFactory(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer));
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformerNamesGroupsBuilder)
        .to(NodeTransformerNamesGroupsBuilder_1.NodeTransformerNamesGroupsBuilder)
        .inSingletonScope();
});
