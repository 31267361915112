"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalVariableTemplate1 = void 0;
function GlobalVariableTemplate1() {
    return `
        let that;
        
        try {
            const getGlobal = Function('return (function() ' + '{}.constructor("return this")( )' + ');');
            
            that = getGlobal();
        } catch (e) {
            that = window;
        }
    `;
}
exports.GlobalVariableTemplate1 = GlobalVariableTemplate1;
