"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeadCodeInjectionIdentifiersTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
let DeadCodeInjectionIdentifiersTransformer = class DeadCodeInjectionIdentifiersTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(identifierReplacer, randomGenerator, options, scopeIdentifiersTraverser) {
        super(randomGenerator, options);
        this.identifierReplacer = identifierReplacer;
        this.scopeIdentifiersTraverser = scopeIdentifiersTraverser;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.RenameIdentifiers:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isProgramNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(programNode, parentNode) {
        this.scopeIdentifiersTraverser.traverseScopeThroughIdentifiers(programNode, parentNode, (data) => {
            const { reference, variableLexicalScopeNode } = data;
            this.transformScopeThroughIdentifiers(reference, variableLexicalScopeNode);
        });
        return programNode;
    }
    transformScopeThroughIdentifiers(reference, lexicalScopeNode) {
        if (reference.resolved) {
            return;
        }
        const identifier = reference.identifier;
        this.storeIdentifierName(identifier, lexicalScopeNode);
        this.replaceIdentifierName(identifier, lexicalScopeNode, reference);
    }
    storeIdentifierName(identifierNode, lexicalScopeNode) {
        this.identifierReplacer.storeLocalName(identifierNode, lexicalScopeNode);
    }
    replaceIdentifierName(identifierNode, lexicalScopeNode, reference) {
        const newIdentifier = this.identifierReplacer
            .replace(identifierNode, lexicalScopeNode);
        reference.identifier.name = newIdentifier.name;
    }
};
DeadCodeInjectionIdentifiersTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierReplacer)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IScopeIdentifiersTraverser)),
    __metadata("design:paramtypes", [Object, Object, Object, Object])
], DeadCodeInjectionIdentifiersTransformer);
exports.DeadCodeInjectionIdentifiersTransformer = DeadCodeInjectionIdentifiersTransformer;
