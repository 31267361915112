"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractControlFlowReplacer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
let AbstractControlFlowReplacer = class AbstractControlFlowReplacer {
    constructor(controlFlowCustomNodeFactory, identifierNamesGeneratorFactory, randomGenerator, options) {
        this.replacerDataByControlFlowStorageId = new Map();
        this.controlFlowCustomNodeFactory = controlFlowCustomNodeFactory;
        this.identifierNamesGenerator = identifierNamesGeneratorFactory(options);
        this.randomGenerator = randomGenerator;
        this.options = options;
    }
    generateStorageKey(controlFlowStorage) {
        const key = this.randomGenerator.getRandomString(5);
        if (controlFlowStorage.has(key)) {
            return this.generateStorageKey(controlFlowStorage);
        }
        return key;
    }
    insertCustomNodeToControlFlowStorage(customNode, controlFlowStorage, replacerId, usingExistingIdentifierChance) {
        var _a, _b;
        const controlFlowStorageId = controlFlowStorage.getStorageId();
        const storageKeysById = (_a = this.replacerDataByControlFlowStorageId.get(controlFlowStorageId)) !== null && _a !== void 0 ? _a : new Map();
        const storageKeysForCurrentId = (_b = storageKeysById.get(replacerId)) !== null && _b !== void 0 ? _b : [];
        const shouldPickFromStorageKeysById = this.randomGenerator.getMathRandom() < usingExistingIdentifierChance
            && storageKeysForCurrentId.length;
        if (shouldPickFromStorageKeysById) {
            return this.randomGenerator.getRandomGenerator().pickone(storageKeysForCurrentId);
        }
        const storageKey = this.generateStorageKey(controlFlowStorage);
        storageKeysForCurrentId.push(storageKey);
        storageKeysById.set(replacerId, storageKeysForCurrentId);
        this.replacerDataByControlFlowStorageId.set(controlFlowStorageId, storageKeysById);
        controlFlowStorage.set(storageKey, customNode);
        return storageKey;
    }
};
AbstractControlFlowReplacer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowCustomNode)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Function, Object, Object])
], AbstractControlFlowReplacer);
exports.AbstractControlFlowReplacer = AbstractControlFlowReplacer;
