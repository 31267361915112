"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsDomainLockRedirectUrl = void 0;
const class_validator_1 = require("class-validator");
const ObfuscationTarget_1 = require("../../enums/ObfuscationTarget");
const Default_1 = require("../presets/Default");
const IsAllowedForObfuscationTargets_1 = require("./IsAllowedForObfuscationTargets");
const IsDomainLockRedirectUrl = () => {
    return (target, key) => {
        (0, class_validator_1.ValidateIf)(({ domainLockRedirectUrl }) => {
            return domainLockRedirectUrl !== Default_1.DEFAULT_PRESET.domainLockRedirectUrl;
        })(target, key);
        (0, class_validator_1.IsUrl)({
            require_protocol: false,
            require_host: false
        })(target, key);
        (0, IsAllowedForObfuscationTargets_1.IsAllowedForObfuscationTargets)([
            ObfuscationTarget_1.ObfuscationTarget.Browser,
            ObfuscationTarget_1.ObfuscationTarget.BrowserNoEval,
        ])(target, key);
    };
};
exports.IsDomainLockRedirectUrl = IsDomainLockRedirectUrl;
