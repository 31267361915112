"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.optionsModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const Options_1 = require("../../../options/Options");
const OptionsNormalizer_1 = require("../../../options/OptionsNormalizer");
exports.optionsModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)
        .to(Options_1.Options)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IOptionsNormalizer)
        .to(OptionsNormalizer_1.OptionsNormalizer)
        .inSingletonScope();
});
