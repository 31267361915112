"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var ScopeAnalyzer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScopeAnalyzer = void 0;
const inversify_1 = require("inversify");
const eslintScope = __importStar(require("eslint-scope"));
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const eslint_visitor_keys_1 = require("eslint-visitor-keys");
const EcmaVersion_1 = require("../../constants/EcmaVersion");
const NodeGuards_1 = require("../../node/NodeGuards");
let ScopeAnalyzer = ScopeAnalyzer_1 = class ScopeAnalyzer {
    constructor() {
        this.scopeManager = null;
    }
    static attachMissingRanges(astTree) {
        estraverse.replace(astTree, {
            enter: (node, parentNode) => {
                var _a, _b, _c, _d;
                if (!node.range) {
                    node.range = [
                        (_b = (_a = parentNode === null || parentNode === void 0 ? void 0 : parentNode.range) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : ScopeAnalyzer_1.emptyRangeValue,
                        (_d = (_c = parentNode === null || parentNode === void 0 ? void 0 : parentNode.range) === null || _c === void 0 ? void 0 : _c[1]) !== null && _d !== void 0 ? _d : ScopeAnalyzer_1.emptyRangeValue
                    ];
                }
                return node;
            }
        });
    }
    static isRootNode(node) {
        return NodeGuards_1.NodeGuards.isProgramNode(node) || node.parentNode === node;
    }
    analyze(astTree) {
        const sourceTypeLength = ScopeAnalyzer_1.sourceTypes.length;
        ScopeAnalyzer_1.attachMissingRanges(astTree);
        for (let i = 0; i < sourceTypeLength; i++) {
            try {
                this.scopeManager = eslintScope.analyze(astTree, {
                    ...ScopeAnalyzer_1.eslintScopeOptions,
                    sourceType: ScopeAnalyzer_1.sourceTypes[i]
                });
                return;
            }
            catch (error) {
                if (i < sourceTypeLength - 1) {
                    continue;
                }
                throw new Error(error);
            }
        }
        throw new Error('Scope analyzing error');
    }
    acquireScope(node) {
        if (!this.scopeManager) {
            throw new Error('Scope manager is not defined');
        }
        const scope = this.scopeManager.acquire(node, ScopeAnalyzer_1.isRootNode(node));
        if (!scope) {
            throw new Error('Cannot acquire scope for node');
        }
        this.sanitizeScopes(scope);
        return scope;
    }
    sanitizeScopes(scope) {
        scope.childScopes.forEach((childScope) => {
            if (childScope.type === 'class' && childScope.upper) {
                if (!childScope.variables.length) {
                    return;
                }
                const classNameVariable = childScope.variables[0];
                const upperVariable = childScope.upper.variables
                    .find((variable) => {
                    const isValidClassNameVariable = classNameVariable.defs
                        .some((definition) => definition.type === 'ClassName');
                    return isValidClassNameVariable && variable.name === classNameVariable.name;
                });
                upperVariable === null || upperVariable === void 0 ? void 0 : upperVariable.references.push(...childScope.variables[0].references);
            }
        });
        for (const childScope of scope.childScopes) {
            this.sanitizeScopes(childScope);
        }
    }
};
ScopeAnalyzer.eslintScopeOptions = {
    ecmaVersion: EcmaVersion_1.ecmaVersion,
    childVisitorKeys: eslint_visitor_keys_1.KEYS,
    optimistic: true
};
ScopeAnalyzer.sourceTypes = [
    'script',
    'module'
];
ScopeAnalyzer.emptyRangeValue = 0;
ScopeAnalyzer = ScopeAnalyzer_1 = __decorate([
    (0, inversify_1.injectable)()
], ScopeAnalyzer);
exports.ScopeAnalyzer = ScopeAnalyzer;
