"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugProtectionFunctionCodeHelper = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const ObfuscationTarget_1 = require("../../enums/ObfuscationTarget");
const Initializable_1 = require("../../decorators/Initializable");
const DebuggerTemplate_1 = require("./templates/debug-protection-function/DebuggerTemplate");
const DebuggerTemplateNoEval_1 = require("./templates/debug-protection-function/DebuggerTemplateNoEval");
const DebugProtectionFunctionTemplate_1 = require("./templates/debug-protection-function/DebugProtectionFunctionTemplate");
const AbstractCustomCodeHelper_1 = require("../AbstractCustomCodeHelper");
const NodeUtils_1 = require("../../node/NodeUtils");
let DebugProtectionFunctionCodeHelper = class DebugProtectionFunctionCodeHelper extends AbstractCustomCodeHelper_1.AbstractCustomCodeHelper {
    constructor(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options);
    }
    initialize(debugProtectionFunctionName) {
        this.debugProtectionFunctionName = debugProtectionFunctionName;
    }
    getNodeStructure(codeHelperTemplate) {
        return NodeUtils_1.NodeUtils.convertCodeToStructure(codeHelperTemplate);
    }
    getCodeHelperTemplate() {
        const debuggerTemplate = this.options.target !== ObfuscationTarget_1.ObfuscationTarget.BrowserNoEval
            ? (0, DebuggerTemplate_1.DebuggerTemplate)()
            : (0, DebuggerTemplateNoEval_1.DebuggerTemplateNoEval)();
        return this.customCodeHelperFormatter.formatTemplate((0, DebugProtectionFunctionTemplate_1.DebugProtectionFunctionTemplate)(), {
            debuggerTemplate,
            debugProtectionFunctionName: this.debugProtectionFunctionName
        });
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], DebugProtectionFunctionCodeHelper.prototype, "debugProtectionFunctionName", void 0);
DebugProtectionFunctionCodeHelper = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperObfuscator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object, Object, Object])
], DebugProtectionFunctionCodeHelper);
exports.DebugProtectionFunctionCodeHelper = DebugProtectionFunctionCodeHelper;
