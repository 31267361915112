"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringArrayTransformersModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const StringArrayRotateFunctionTransformer_1 = require("../../../node-transformers/string-array-transformers/StringArrayRotateFunctionTransformer");
const StringArrayScopeCallsWrapperTransformer_1 = require("../../../node-transformers/string-array-transformers/StringArrayScopeCallsWrapperTransformer");
const StringArrayTransformer_1 = require("../../../node-transformers/string-array-transformers/StringArrayTransformer");
exports.stringArrayTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(StringArrayRotateFunctionTransformer_1.StringArrayRotateFunctionTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.StringArrayRotateFunctionTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(StringArrayScopeCallsWrapperTransformer_1.StringArrayScopeCallsWrapperTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.StringArrayScopeCallsWrapperTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(StringArrayTransformer_1.StringArrayTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.StringArrayTransformer);
});
