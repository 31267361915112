"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializingTransformersModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const CommentsTransformer_1 = require("../../../node-transformers/initializing-transformers/CommentsTransformer");
exports.initializingTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(CommentsTransformer_1.CommentsTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.CommentsTransformer);
});
