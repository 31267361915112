"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctionExpressionCalleeDataExtractor = void 0;
const inversify_1 = require("inversify");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const AbstractCalleeDataExtractor_1 = require("./AbstractCalleeDataExtractor");
const NodeGuards_1 = require("../../../node/NodeGuards");
const NodeStatementUtils_1 = require("../../../node/NodeStatementUtils");
let FunctionExpressionCalleeDataExtractor = class FunctionExpressionCalleeDataExtractor extends AbstractCalleeDataExtractor_1.AbstractCalleeDataExtractor {
    extract(blockScopeBody, callee) {
        let calleeName = null;
        let calleeBlockStatement = null;
        if (NodeGuards_1.NodeGuards.isIdentifierNode(callee)) {
            calleeName = callee.name;
            calleeBlockStatement = this.getCalleeBlockStatement(NodeStatementUtils_1.NodeStatementUtils.getParentNodeWithStatements(blockScopeBody[0]), callee.name);
        }
        else if (NodeGuards_1.NodeGuards.isFunctionExpressionNode(callee)) {
            calleeName = null;
            calleeBlockStatement = callee.body;
        }
        if (!calleeBlockStatement) {
            return null;
        }
        return {
            callee: calleeBlockStatement,
            name: calleeName
        };
    }
    getCalleeBlockStatement(targetNode, name) {
        let calleeBlockStatement = null;
        estraverse.traverse(targetNode, {
            enter: (node, parentNode) => {
                if (NodeGuards_1.NodeGuards.isFunctionExpressionNode(node) &&
                    parentNode &&
                    NodeGuards_1.NodeGuards.isVariableDeclaratorNode(parentNode) &&
                    NodeGuards_1.NodeGuards.isIdentifierNode(parentNode.id) &&
                    parentNode.id.name === name) {
                    calleeBlockStatement = node.body;
                    return estraverse.VisitorOption.Break;
                }
            }
        });
        return calleeBlockStatement;
    }
};
FunctionExpressionCalleeDataExtractor = __decorate([
    (0, inversify_1.injectable)()
], FunctionExpressionCalleeDataExtractor);
exports.FunctionExpressionCalleeDataExtractor = FunctionExpressionCalleeDataExtractor;
