"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumericalExpressionDataToNodeConverter = void 0;
const NodeFactory_1 = require("./NodeFactory");
const NumberUtils_1 = require("../utils/NumberUtils");
class NumericalExpressionDataToNodeConverter {
    static convertIntegerNumberData(numberNumericalExpressionData, literalNodeGetter) {
        return NumericalExpressionDataToNodeConverter.convertNumericalExpressionDataToNode(numberNumericalExpressionData, literalNodeGetter);
    }
    static convertFloatNumberData(integerNumberNumericalExpressionData, decimalPart, literalNodeGetter) {
        const integerNumberNumericalExpressionNode = NumericalExpressionDataToNodeConverter
            .convertNumericalExpressionDataToNode(integerNumberNumericalExpressionData, literalNodeGetter);
        return NodeFactory_1.NodeFactory.binaryExpressionNode('+', integerNumberNumericalExpressionNode, NodeFactory_1.NodeFactory.literalNode(decimalPart));
    }
    static convertNumericalExpressionDataToNode(numberNumericalExpressionData, literalNodeGetter, operator = '+') {
        var _a;
        const numberNumericalExpressionDataLength = numberNumericalExpressionData.length;
        const leftParts = numberNumericalExpressionDataLength > 1
            ? numberNumericalExpressionData.slice(0, numberNumericalExpressionDataLength - 1)
            : [numberNumericalExpressionData[0]];
        const rightParts = numberNumericalExpressionDataLength > 1
            ? numberNumericalExpressionData.slice(-1)
            : [];
        if (rightParts.length) {
            return NumericalExpressionDataToNodeConverter
                .convertPartsToBinaryExpression(operator, leftParts, rightParts, literalNodeGetter);
        }
        const firstLeftPartOrNumber = (_a = leftParts[0]) !== null && _a !== void 0 ? _a : null;
        return Array.isArray(firstLeftPartOrNumber)
            ? NumericalExpressionDataToNodeConverter.convertNumericalExpressionDataToNode(firstLeftPartOrNumber, literalNodeGetter, '*')
            : NumericalExpressionDataToNodeConverter.convertPartOrNumberToLiteralNode(firstLeftPartOrNumber, literalNodeGetter);
    }
    static convertPartsToBinaryExpression(operator, leftParts, rightParts, literalNodeGetter) {
        const rightPartOrNumber = rightParts[0];
        if (Array.isArray(rightPartOrNumber)) {
            return NodeFactory_1.NodeFactory.binaryExpressionNode(operator, NumericalExpressionDataToNodeConverter.convertNumericalExpressionDataToNode(leftParts, literalNodeGetter), NumericalExpressionDataToNodeConverter.convertNumericalExpressionDataToNode(rightPartOrNumber, literalNodeGetter, '*'));
        }
        else {
            return NodeFactory_1.NodeFactory.binaryExpressionNode(operator, NumericalExpressionDataToNodeConverter.convertNumericalExpressionDataToNode(leftParts, literalNodeGetter), this.convertPartOrNumberToLiteralNode(rightPartOrNumber, literalNodeGetter));
        }
    }
    static convertPartOrNumberToLiteralNode(partOrNumber, literalNodeGetter) {
        const number = Array.isArray(partOrNumber)
            ? partOrNumber[0]
            : partOrNumber;
        const isPositiveNumber = NumberUtils_1.NumberUtils.isPositive(number);
        const absoluteNumber = Math.abs(number);
        return literalNodeGetter(absoluteNumber, isPositiveNumber);
    }
}
exports.NumericalExpressionDataToNodeConverter = NumericalExpressionDataToNodeConverter;
