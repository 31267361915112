"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayScopeCallsWrapperFunctionNode = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const Initializable_1 = require("../../decorators/Initializable");
const AbstractStringArrayCallNode_1 = require("./AbstractStringArrayCallNode");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeUtils_1 = require("../../node/NodeUtils");
let StringArrayScopeCallsWrapperFunctionNode = class StringArrayScopeCallsWrapperFunctionNode extends AbstractStringArrayCallNode_1.AbstractStringArrayCallNode {
    constructor(identifierNamesGeneratorFactory, stringArrayIndexNodeFactory, customCodeHelperFormatter, stringArrayStorage, arrayUtils, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, stringArrayIndexNodeFactory, customCodeHelperFormatter, stringArrayStorage, arrayUtils, randomGenerator, options);
    }
    initialize(stringArrayScopeCallsWrapperData, upperStringArrayCallsWrapperData) {
        this.stringArrayScopeCallsWrapperData = stringArrayScopeCallsWrapperData;
        this.upperStringArrayCallsWrapperData = upperStringArrayCallsWrapperData;
    }
    getNodeStructure() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const stringArrayCallIdentifierNode = NodeFactory_1.NodeFactory.identifierNode(this.randomGenerator.getRandomString(6));
        const decodeKeyIdentifierNode = NodeFactory_1.NodeFactory.identifierNode(this.randomGenerator.getRandomString(6));
        const stringArrayCallNode = this.getUpperStringArrayCallNode(stringArrayCallIdentifierNode, this.getStringArrayIndexNode(this.stringArrayScopeCallsWrapperData.index
            - this.upperStringArrayCallsWrapperData.index));
        const parameters = this.arrayUtils.fillWithRange(!this.stringArrayScopeCallsWrapperData.parameterIndexesData
            ? AbstractStringArrayCallNode_1.AbstractStringArrayCallNode.stringArrayRootCallsWrapperParametersCount
            : this.options.stringArrayWrappersParametersMaxCount, () => this.getFakeParameterNode());
        parameters.splice((_b = (_a = this.stringArrayScopeCallsWrapperData.parameterIndexesData) === null || _a === void 0 ? void 0 : _a.valueIndexParameterIndex) !== null && _b !== void 0 ? _b : 0, 1, stringArrayCallIdentifierNode);
        parameters.splice((_d = (_c = this.stringArrayScopeCallsWrapperData.parameterIndexesData) === null || _c === void 0 ? void 0 : _c.decodeKeyParameterIndex) !== null && _d !== void 0 ? _d : 1, 1, decodeKeyIdentifierNode);
        const callExpressionArgs = this.arrayUtils.fillWithRange(!this.upperStringArrayCallsWrapperData.parameterIndexesData
            ? AbstractStringArrayCallNode_1.AbstractStringArrayCallNode.stringArrayRootCallsWrapperParametersCount
            : this.options.stringArrayWrappersParametersMaxCount, (index) => this.getUpperStringArrayCallNode(parameters[index], this.getFakeUpperStringArrayIndexNode()));
        callExpressionArgs.splice((_f = (_e = this.upperStringArrayCallsWrapperData.parameterIndexesData) === null || _e === void 0 ? void 0 : _e.valueIndexParameterIndex) !== null && _f !== void 0 ? _f : 0, 1, stringArrayCallNode);
        callExpressionArgs.splice((_h = (_g = this.upperStringArrayCallsWrapperData.parameterIndexesData) === null || _g === void 0 ? void 0 : _g.decodeKeyParameterIndex) !== null && _h !== void 0 ? _h : 1, 1, decodeKeyIdentifierNode);
        const functionDeclarationNode = NodeFactory_1.NodeFactory.functionDeclarationNode(this.stringArrayScopeCallsWrapperData.name, parameters, NodeFactory_1.NodeFactory.blockStatementNode([
            NodeFactory_1.NodeFactory.returnStatementNode(NodeFactory_1.NodeFactory.callExpressionNode(NodeFactory_1.NodeFactory.identifierNode(this.upperStringArrayCallsWrapperData.name), callExpressionArgs))
        ]));
        const structure = functionDeclarationNode;
        NodeUtils_1.NodeUtils.parentizeAst(structure);
        for (const parameter of parameters) {
            parameter.name = this.identifierNamesGenerator.generateForLexicalScope(functionDeclarationNode);
        }
        return [structure];
    }
    getUpperStringArrayCallNode(indexParameterIdentifierNode, indexShiftNode) {
        return NodeFactory_1.NodeFactory.binaryExpressionNode('-', indexParameterIdentifierNode, indexShiftNode);
    }
    getFakeParameterNode() {
        return NodeFactory_1.NodeFactory.identifierNode(this.randomGenerator.getRandomString(6));
    }
    getFakeUpperStringArrayIndexNode() {
        return this.getStringArrayIndexNode(this.randomGenerator.getRandomInteger(0, 500));
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Object)
], StringArrayScopeCallsWrapperFunctionNode.prototype, "upperStringArrayCallsWrapperData", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Object)
], StringArrayScopeCallsWrapperFunctionNode.prototype, "stringArrayScopeCallsWrapperData", void 0);
StringArrayScopeCallsWrapperFunctionNode = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayIndexNode)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(6, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Function, Object, Object, Object, Object, Object])
], StringArrayScopeCallsWrapperFunctionNode);
exports.StringArrayScopeCallsWrapperFunctionNode = StringArrayScopeCallsWrapperFunctionNode;
