"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var RenamePropertiesTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenamePropertiesTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const RenamePropertiesMode_1 = require("../../enums/node-transformers/rename-properties-transformers/RenamePropertiesMode");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeLiteralUtils_1 = require("../../node/NodeLiteralUtils");
const NodeMetadata_1 = require("../../node/NodeMetadata");
let RenamePropertiesTransformer = RenamePropertiesTransformer_1 = class RenamePropertiesTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(renamePropertiesReplacer, randomGenerator, options) {
        super(randomGenerator, options);
        this.renamePropertiesReplacer = renamePropertiesReplacer;
    }
    static isValidPropertyNode(propertyNode, propertyKeyNode) {
        if (NodeGuards_1.NodeGuards.isIdentifierNode(propertyKeyNode) && propertyNode.computed) {
            return false;
        }
        return true;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Preparing:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode) {
                            this.prepareNode(node, parentNode);
                        }
                    }
                };
            case NodeTransformationStage_1.NodeTransformationStage.RenameProperties:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    prepareNode(node, parentNode) {
        if ((NodeGuards_1.NodeGuards.isPropertyNode(parentNode) && parentNode.key === node)
            || NodeGuards_1.NodeGuards.isMemberExpressionNode(parentNode) && parentNode.property === node
            || NodeGuards_1.NodeGuards.isMethodDefinitionNode(parentNode) && parentNode.key === node
            || NodeGuards_1.NodeGuards.isPropertyDefinitionNode(parentNode) && parentNode.key === node) {
            NodeMetadata_1.NodeMetadata.set(node, { propertyKeyToRenameNode: true });
            return;
        }
        if (this.options.renamePropertiesMode === RenamePropertiesMode_1.RenamePropertiesMode.Safe) {
            this.analyzeAutoExcludedPropertyNames(node, parentNode);
        }
    }
    transformNode(node, parentNode) {
        if (!NodeGuards_1.NodeGuards.isIdentifierNode(node) && !NodeGuards_1.NodeGuards.isLiteralNode(node)) {
            return node;
        }
        if (!NodeMetadata_1.NodeMetadata.isPropertyKeyToRenameNode(node)) {
            return node;
        }
        const isPropertyNode = NodeGuards_1.NodeGuards.isPropertyNode(parentNode);
        const isPropertyLikeNode = isPropertyNode
            || NodeGuards_1.NodeGuards.isPropertyDefinitionNode(parentNode)
            || NodeGuards_1.NodeGuards.isMemberExpressionNode(parentNode)
            || NodeGuards_1.NodeGuards.isMethodDefinitionNode(parentNode);
        if (isPropertyLikeNode && !RenamePropertiesTransformer_1.isValidPropertyNode(parentNode, node)) {
            return node;
        }
        if (isPropertyNode) {
            parentNode.shorthand = false;
        }
        return this.renamePropertiesReplacer.replace(node);
    }
    analyzeAutoExcludedPropertyNames(node, parentNode) {
        if (!NodeGuards_1.NodeGuards.isLiteralNode(node) || !NodeLiteralUtils_1.NodeLiteralUtils.isStringLiteralNode(node)) {
            return;
        }
        this.renamePropertiesReplacer.excludePropertyName(node.value);
    }
};
RenamePropertiesTransformer = RenamePropertiesTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRenamePropertiesReplacer)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object, Object])
], RenamePropertiesTransformer);
exports.RenamePropertiesTransformer = RenamePropertiesTransformer;
