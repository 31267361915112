"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeMetadata = void 0;
class NodeMetadata {
    static set(node, metadata) {
        var _a;
        node.metadata = Object.assign((_a = node.metadata) !== null && _a !== void 0 ? _a : {}, metadata);
    }
    static get(node, metadataKey) {
        return node.metadata !== undefined
            ? node.metadata[metadataKey]
            : undefined;
    }
    static isEvalHostNode(node) {
        return NodeMetadata.get(node, 'evalHostNode') === true;
    }
    static isForceTransformNode(node) {
        return NodeMetadata.get(node, 'forceTransformNode') === true;
    }
    static isIgnoredNode(node) {
        return NodeMetadata.get(node, 'ignoredNode') === true;
    }
    static isPropertyKeyToRenameNode(node) {
        return NodeMetadata.get(node, 'propertyKeyToRenameNode') === true;
    }
    static isStringArrayCallLiteralNode(literalNode) {
        return NodeMetadata.get(literalNode, 'stringArrayCallLiteralNode') === true;
    }
}
exports.NodeMetadata = NodeMetadata;
