"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOW_OBFUSCATION_PRESET = void 0;
const OptionsPreset_1 = require("../../enums/options/presets/OptionsPreset");
const Default_1 = require("./Default");
exports.LOW_OBFUSCATION_PRESET = Object.freeze({
    ...Default_1.DEFAULT_PRESET,
    disableConsoleOutput: true,
    optionsPreset: OptionsPreset_1.OptionsPreset.LowObfuscation,
    stringArrayRotate: true,
    selfDefending: true,
    simplify: true,
    stringArrayCallsTransform: false,
    stringArrayCallsTransformThreshold: 0,
    stringArrayShuffle: true
});
