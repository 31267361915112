"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var MangledIdentifierNamesGenerator_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MangledIdentifierNamesGenerator = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const AlphabetString_1 = require("../../constants/AlphabetString");
const AlphabetStringUppercase_1 = require("../../constants/AlphabetStringUppercase");
const NumbersString_1 = require("../../constants/NumbersString");
const ReservedIdentifierNames_1 = require("../../constants/ReservedIdentifierNames");
const AbstractIdentifierNamesGenerator_1 = require("./AbstractIdentifierNamesGenerator");
const NodeLexicalScopeUtils_1 = require("../../node/NodeLexicalScopeUtils");
let MangledIdentifierNamesGenerator = MangledIdentifierNamesGenerator_1 = class MangledIdentifierNamesGenerator extends AbstractIdentifierNamesGenerator_1.AbstractIdentifierNamesGenerator {
    constructor(randomGenerator, options, setUtils) {
        super(randomGenerator, options);
        this.lastMangledName = MangledIdentifierNamesGenerator_1.initMangledNameCharacter;
        this.lastMangledNameForScopeMap = new WeakMap();
        this.lastMangledNameForLabelMap = new Map();
        this.setUtils = setUtils;
    }
    generateNext(nameLength) {
        const identifierName = this.generateNewMangledName(this.lastMangledName);
        this.updatePreviousMangledName(identifierName);
        this.preserveName(identifierName);
        return identifierName;
    }
    generateForGlobalScope(nameLength) {
        const prefix = this.options.identifiersPrefix ?
            `${this.options.identifiersPrefix}`
            : '';
        const identifierName = this.generateNewMangledName(this.lastMangledName, (newIdentifierName) => {
            const identifierNameWithPrefix = `${prefix}${newIdentifierName}`;
            return this.isValidIdentifierName(identifierNameWithPrefix);
        });
        const identifierNameWithPrefix = `${prefix}${identifierName}`;
        this.updatePreviousMangledName(identifierName);
        this.preserveName(identifierNameWithPrefix);
        return identifierNameWithPrefix;
    }
    generateForLexicalScope(lexicalScopeNode, nameLength) {
        const lexicalScopes = [
            lexicalScopeNode,
            ...NodeLexicalScopeUtils_1.NodeLexicalScopeUtils.getLexicalScopes(lexicalScopeNode)
        ];
        const lastMangledNameForScope = this.getLastMangledNameForScopes(lexicalScopes);
        const identifierName = this.generateNewMangledName(lastMangledNameForScope, (newIdentifierName) => this.isValidIdentifierNameInLexicalScopes(newIdentifierName, lexicalScopes));
        this.lastMangledNameForScopeMap.set(lexicalScopeNode, identifierName);
        this.updatePreviousMangledName(identifierName);
        this.preserveNameForLexicalScope(identifierName, lexicalScopeNode);
        return identifierName;
    }
    generateForLabel(label, nameLength) {
        const lastMangledNameForLabel = this.getLastMangledNameForLabel(label);
        const identifierName = this.generateNewMangledName(lastMangledNameForLabel);
        this.updatePreviousMangledNameForLabel(identifierName, label, lastMangledNameForLabel);
        return identifierName;
    }
    isIncrementedMangledName(nextName, prevName) {
        if (nextName === prevName) {
            return false;
        }
        const nextNameLength = nextName.length;
        const prevNameLength = prevName.length;
        if (nextNameLength !== prevNameLength) {
            return nextNameLength > prevNameLength;
        }
        const nameSequence = this.getNameSequence();
        for (let i = 0; i < nextNameLength; i++) {
            const nextNameCharacter = nextName[i];
            const prevNameCharacter = prevName[i];
            if (nextNameCharacter === prevNameCharacter) {
                continue;
            }
            const indexOfNextNameCharacter = nameSequence.indexOf(nextNameCharacter);
            const indexOfPrevNameCharacter = nameSequence.indexOf(prevNameCharacter);
            return indexOfNextNameCharacter > indexOfPrevNameCharacter;
        }
        throw new Error('Something goes wrong during comparison of mangled names');
    }
    isValidIdentifierName(mangledName) {
        return super.isValidIdentifierName(mangledName)
            && !MangledIdentifierNamesGenerator_1.reservedNamesSet.has(mangledName);
    }
    getNameSequence() {
        return MangledIdentifierNamesGenerator_1.nameSequence;
    }
    updatePreviousMangledName(name) {
        if (!this.isIncrementedMangledName(name, this.lastMangledName)) {
            return;
        }
        this.lastMangledName = name;
    }
    updatePreviousMangledNameForLabel(name, label, lastMangledNameForLabel) {
        if (!this.isIncrementedMangledName(name, lastMangledNameForLabel)) {
            return;
        }
        this.lastMangledNameForLabelMap.set(label, name);
    }
    generateNewMangledName(previousMangledName, validationFunction) {
        var _a;
        const generateNewMangledName = (name, regenerationAttempt = 0) => {
            if (regenerationAttempt > MangledIdentifierNamesGenerator_1.maxRegenerationAttempts) {
                const lastPreservedName = this.setUtils.getLastElement(this.preservedNamesSet);
                if (lastPreservedName) {
                    return this.generateNewMangledName(lastPreservedName);
                }
            }
            const nameSequence = this.getNameSequence();
            const nameSequenceLength = nameSequence.length;
            const nameLength = name.length;
            const zeroSequence = (num) => {
                return '0'.repeat(num);
            };
            let index = nameLength - 1;
            do {
                const character = name[index];
                const indexInSequence = nameSequence.indexOf(character);
                const lastNameSequenceIndex = nameSequenceLength - 1;
                if (indexInSequence !== lastNameSequenceIndex) {
                    const previousNamePart = name.slice(0, index);
                    const nextCharacter = nameSequence[indexInSequence + 1];
                    const zeroSequenceLength = nameLength - (index + 1);
                    const zeroSequenceCharacters = zeroSequence(zeroSequenceLength);
                    return previousNamePart + nextCharacter + zeroSequenceCharacters;
                }
                --index;
            } while (index >= 0);
            const firstLetterCharacter = nameSequence[NumbersString_1.numbersString.length];
            return `${firstLetterCharacter}${zeroSequence(nameLength)}`;
        };
        let identifierName = previousMangledName;
        let isValidIdentifierName;
        do {
            identifierName = generateNewMangledName(identifierName);
            isValidIdentifierName = (_a = validationFunction === null || validationFunction === void 0 ? void 0 : validationFunction(identifierName)) !== null && _a !== void 0 ? _a : this.isValidIdentifierName(identifierName);
        } while (!isValidIdentifierName);
        return identifierName;
    }
    getLastMangledNameForScopes(lexicalScopeNodes) {
        var _a;
        for (const lexicalScope of lexicalScopeNodes) {
            const lastMangledName = (_a = this.lastMangledNameForScopeMap.get(lexicalScope)) !== null && _a !== void 0 ? _a : null;
            if (!lastMangledName) {
                continue;
            }
            return lastMangledName;
        }
        return MangledIdentifierNamesGenerator_1.initMangledNameCharacter;
    }
    getLastMangledNameForLabel(label) {
        var _a;
        const lastMangledName = (_a = this.lastMangledNameForLabelMap.get(label)) !== null && _a !== void 0 ? _a : null;
        return lastMangledName !== null && lastMangledName !== void 0 ? lastMangledName : MangledIdentifierNamesGenerator_1.initMangledNameCharacter;
    }
};
MangledIdentifierNamesGenerator.maxRegenerationAttempts = 20;
MangledIdentifierNamesGenerator.initMangledNameCharacter = '9';
MangledIdentifierNamesGenerator.nameSequence = [
    ...`${NumbersString_1.numbersString}${AlphabetString_1.alphabetString}${AlphabetStringUppercase_1.alphabetStringUppercase}`
];
MangledIdentifierNamesGenerator.reservedNamesSet = new Set(ReservedIdentifierNames_1.reservedIdentifierNames);
MangledIdentifierNamesGenerator = MangledIdentifierNamesGenerator_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ISetUtils)),
    __metadata("design:paramtypes", [Object, Object, Object])
], MangledIdentifierNamesGenerator);
exports.MangledIdentifierNamesGenerator = MangledIdentifierNamesGenerator;
