"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayCallsWrapperTemplate = void 0;
function StringArrayCallsWrapperTemplate() {
    return `
        function {stringArrayCallsWrapperName} ({stringArrayCacheName}, key) {
            const stringArray = {stringArrayFunctionName}();
            
            {stringArrayCallsWrapperName} = function (index, key) {
                index = index - {indexShiftAmount};
                
                let value = stringArray[index];
                
                {decodeCodeHelperTemplate}
            
                return value;
            };
         
            return {stringArrayCallsWrapperName}({stringArrayCacheName}, key);
        }
    `;
}
exports.StringArrayCallsWrapperTemplate = StringArrayCallsWrapperTemplate;
