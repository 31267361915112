"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomCodeHelper = void 0;
var CustomCodeHelper;
(function (CustomCodeHelper) {
    CustomCodeHelper["CallsControllerFunction"] = "CallsControllerFunction";
    CustomCodeHelper["ConsoleOutputDisable"] = "ConsoleOutputDisable";
    CustomCodeHelper["DebugProtectionFunctionCall"] = "DebugProtectionFunctionCall";
    CustomCodeHelper["DebugProtectionFunctionInterval"] = "DebugProtectionFunctionInterval";
    CustomCodeHelper["DebugProtectionFunction"] = "DebugProtectionFunction";
    CustomCodeHelper["DomainLock"] = "DomainLock";
    CustomCodeHelper["SelfDefending"] = "SelfDefending";
    CustomCodeHelper["StringArray"] = "StringArray";
    CustomCodeHelper["StringArrayCallsWrapper"] = "StringArrayCallsWrapper";
    CustomCodeHelper["StringArrayCallsWrapperBase64"] = "StringArrayCallsWrapperBase64";
    CustomCodeHelper["StringArrayCallsWrapperRc4"] = "StringArrayCallsWrapperRc4";
    CustomCodeHelper["StringArrayRotateFunction"] = "StringArrayRotateFunction";
})(CustomCodeHelper = exports.CustomCodeHelper || (exports.CustomCodeHelper = {}));
