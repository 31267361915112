"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.codeTransformersModule = void 0;
const InversifyContainerFacade_1 = require("../../InversifyContainerFacade");
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const CodeTransformer_1 = require("../../../enums/code-transformers/CodeTransformer");
const CodeTransformerNamesGroupsBuilder_1 = require("../../../code-transformers/CodeTransformerNamesGroupsBuilder");
const HashbangOperatorTransformer_1 = require("../../../code-transformers/preparing-transformers/HashbangOperatorTransformer");
exports.codeTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICodeTransformer)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getCacheFactory(ServiceIdentifiers_1.ServiceIdentifiers.ICodeTransformer));
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICodeTransformerNamesGroupsBuilder)
        .to(CodeTransformerNamesGroupsBuilder_1.CodeTransformerNamesGroupsBuilder)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICodeTransformer)
        .to(HashbangOperatorTransformer_1.HashbangOperatorTransformer)
        .whenTargetNamed(CodeTransformer_1.CodeTransformer.HashbangOperatorTransformer);
});
