"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ConditionalCommentObfuscatingGuard_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConditionalCommentObfuscatingGuard = void 0;
const inversify_1 = require("inversify");
const ObfuscatingGuardResult_1 = require("../../../enums/node/ObfuscatingGuardResult");
const NodeGuards_1 = require("../../../node/NodeGuards");
let ConditionalCommentObfuscatingGuard = ConditionalCommentObfuscatingGuard_1 = class ConditionalCommentObfuscatingGuard {
    constructor() {
        this.obfuscationAllowed = true;
    }
    static isConditionalComment(comment) {
        return ConditionalCommentObfuscatingGuard_1.obfuscationEnableCommentRegExp.test(comment.value) ||
            ConditionalCommentObfuscatingGuard_1.obfuscationDisableCommentRegExp.test(comment.value);
    }
    check(node) {
        if (NodeGuards_1.NodeGuards.isNodeWithComments(node)) {
            const leadingComments = node.leadingComments;
            if (leadingComments) {
                this.obfuscationAllowed = this.checkComments(leadingComments);
            }
        }
        return this.obfuscationAllowed
            ? ObfuscatingGuardResult_1.ObfuscatingGuardResult.Transform
            : ObfuscatingGuardResult_1.ObfuscatingGuardResult.Ignore;
    }
    checkComments(comments) {
        const commentsLength = comments.length;
        let obfuscationAllowed = this.obfuscationAllowed;
        for (let i = 0; i < commentsLength; i++) {
            const comment = comments[i];
            if (ConditionalCommentObfuscatingGuard_1.obfuscationEnableCommentRegExp.test(comment.value)) {
                obfuscationAllowed = true;
                continue;
            }
            if (ConditionalCommentObfuscatingGuard_1.obfuscationDisableCommentRegExp.test(comment.value)) {
                obfuscationAllowed = false;
            }
        }
        return obfuscationAllowed;
    }
};
ConditionalCommentObfuscatingGuard.obfuscationEnableCommentRegExp = new RegExp('javascript-obfuscator *: *enable');
ConditionalCommentObfuscatingGuard.obfuscationDisableCommentRegExp = new RegExp('javascript-obfuscator *: *disable');
ConditionalCommentObfuscatingGuard = ConditionalCommentObfuscatingGuard_1 = __decorate([
    (0, inversify_1.injectable)()
], ConditionalCommentObfuscatingGuard);
exports.ConditionalCommentObfuscatingGuard = ConditionalCommentObfuscatingGuard;
