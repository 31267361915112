"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainLockRule = void 0;
const Utils_1 = require("../../utils/Utils");
const DomainLockRule = (options) => {
    if (options.domainLock.length) {
        const normalizedDomains = [];
        for (const domain of options.domainLock) {
            normalizedDomains.push(Utils_1.Utils.extractDomainFrom(domain));
        }
        options = {
            ...options,
            domainLock: normalizedDomains
        };
    }
    return options;
};
exports.DomainLockRule = DomainLockRule;
