"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var ClassFieldTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClassFieldTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeGuards_1 = require("../../node/NodeGuards");
let ClassFieldTransformer = ClassFieldTransformer_1 = class ClassFieldTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Converting:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode
                            && (NodeGuards_1.NodeGuards.isMethodDefinitionNode(node)
                                || NodeGuards_1.NodeGuards.isPropertyDefinitionNode(node))) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(classFieldNode, parentNode) {
        if (NodeGuards_1.NodeGuards.isIdentifierNode(classFieldNode.key)) {
            return this.replaceIdentifierKey(classFieldNode, classFieldNode.key);
        }
        if (NodeGuards_1.NodeGuards.isLiteralNode(classFieldNode.key)) {
            return this.replaceLiteralKey(classFieldNode, classFieldNode.key);
        }
        return classFieldNode;
    }
    replaceIdentifierKey(classFieldNode, keyNode) {
        if (!ClassFieldTransformer_1.ignoredNames.includes(keyNode.name)
            && !classFieldNode.computed) {
            classFieldNode.computed = true;
            classFieldNode.key = NodeFactory_1.NodeFactory.literalNode(keyNode.name);
        }
        return classFieldNode;
    }
    replaceLiteralKey(classFieldNode, keyNode) {
        if (typeof keyNode.value === 'string'
            && !ClassFieldTransformer_1.ignoredNames.includes(keyNode.value)
            && !classFieldNode.computed) {
            classFieldNode.computed = true;
        }
        return classFieldNode;
    }
};
ClassFieldTransformer.ignoredNames = ['constructor'];
ClassFieldTransformer = ClassFieldTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], ClassFieldTransformer);
exports.ClassFieldTransformer = ClassFieldTransformer;
