"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InversifyContainerFacade = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("./ServiceIdentifiers");
const AnalyzersModule_1 = require("./modules/analyzers/AnalyzersModule");
const CodeTransformersModule_1 = require("./modules/code-transformers/CodeTransformersModule");
const ControlFlowTransformersModule_1 = require("./modules/node-transformers/ControlFlowTransformersModule");
const ConvertingTransformersModule_1 = require("./modules/node-transformers/ConvertingTransformersModule");
const CustomCodeHelpersModule_1 = require("./modules/custom-code-helpers/CustomCodeHelpersModule");
const CustomNodesModule_1 = require("./modules/custom-nodes/CustomNodesModule");
const DeadCodeInjectionTransformersModule_1 = require("./modules/node-transformers/DeadCodeInjectionTransformersModule");
const FinalizingTransformersModule_1 = require("./modules/node-transformers/FinalizingTransformersModule");
const GeneratorsModule_1 = require("./modules/generators/GeneratorsModule");
const InitializingTransformersModule_1 = require("./modules/node-transformers/InitializingTransformersModule");
const NodeModule_1 = require("./modules/node/NodeModule");
const NodeTransformersModule_1 = require("./modules/node-transformers/NodeTransformersModule");
const OptionsModule_1 = require("./modules/options/OptionsModule");
const PreparingTransformersModule_1 = require("./modules/node-transformers/PreparingTransformersModule");
const RenameIdentifiersTransformersModule_1 = require("./modules/node-transformers/RenameIdentifiersTransformersModule");
const RenamePropertiesTransformersModule_1 = require("./modules/node-transformers/RenamePropertiesTransformersModule");
const SimplifyingTransformersModule_1 = require("./modules/node-transformers/SimplifyingTransformersModule");
const StoragesModule_1 = require("./modules/storages/StoragesModule");
const StringArrayTransformersModule_1 = require("./modules/node-transformers/StringArrayTransformersModule");
const UtilsModule_1 = require("./modules/utils/UtilsModule");
const CodeTransformersRunner_1 = require("../code-transformers/CodeTransformersRunner");
const JavaScriptObfuscator_1 = require("../JavaScriptObfuscator");
const Logger_1 = require("../logger/Logger");
const NodeTransformersRunner_1 = require("../node-transformers/NodeTransformersRunner");
const ObfuscationResult_1 = require("../source-code/ObfuscationResult");
const SourceCode_1 = require("../source-code/SourceCode");
class InversifyContainerFacade {
    constructor() {
        this.container = new inversify_1.Container();
    }
    static getFactory(serviceIdentifier) {
        return (context) => {
            return (bindingName) => {
                return context.container.getNamed(serviceIdentifier, bindingName);
            };
        };
    }
    static getCacheFactory(serviceIdentifier) {
        return (context) => {
            const cache = new Map();
            return (bindingName) => {
                if (cache.has(bindingName)) {
                    return cache.get(bindingName);
                }
                const object = context.container.getNamed(serviceIdentifier, bindingName);
                cache.set(bindingName, object);
                return object;
            };
        };
    }
    static getConstructorFactory(serviceIdentifier, ...dependencies) {
        return (context) => {
            const cache = new Map();
            const cachedDependencies = [];
            return (bindingName) => {
                dependencies.forEach((dependency, index) => {
                    if (!cachedDependencies[index]) {
                        cachedDependencies[index] = context.container.get(dependency);
                    }
                });
                if (cache.has(bindingName)) {
                    return new (cache.get(bindingName))(...cachedDependencies);
                }
                const constructor = context.container
                    .getNamed(serviceIdentifier, bindingName);
                cache.set(bindingName, constructor);
                return new constructor(...cachedDependencies);
            };
        };
    }
    get(serviceIdentifier) {
        return this.container.get(serviceIdentifier);
    }
    getNamed(serviceIdentifier, named) {
        return this.container.getNamed(serviceIdentifier, named);
    }
    load(sourceCode, sourceMap, options) {
        this.container
            .bind(ServiceIdentifiers_1.ServiceIdentifiers.ISourceCode)
            .toDynamicValue(() => new SourceCode_1.SourceCode(sourceCode, sourceMap))
            .inSingletonScope();
        this.container
            .bind(ServiceIdentifiers_1.ServiceIdentifiers.TInputOptions)
            .toDynamicValue(() => options)
            .inSingletonScope();
        this.container
            .bind(ServiceIdentifiers_1.ServiceIdentifiers.ILogger)
            .to(Logger_1.Logger)
            .inSingletonScope();
        this.container
            .bind(ServiceIdentifiers_1.ServiceIdentifiers.IJavaScriptObfuscator)
            .to(JavaScriptObfuscator_1.JavaScriptObfuscator)
            .inSingletonScope();
        this.container
            .bind(ServiceIdentifiers_1.ServiceIdentifiers.ICodeTransformersRunner)
            .to(CodeTransformersRunner_1.CodeTransformersRunner)
            .inSingletonScope();
        this.container
            .bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformersRunner)
            .to(NodeTransformersRunner_1.NodeTransformersRunner)
            .inSingletonScope();
        this.container
            .bind(ServiceIdentifiers_1.ServiceIdentifiers.IObfuscationResult)
            .to(ObfuscationResult_1.ObfuscationResult);
        this.container
            .bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IObfuscationResult)
            .toFactory((context) => {
            return (obfuscatedCodeAsString, sourceMapAsString) => {
                const obfuscationResult = context.container
                    .get(ServiceIdentifiers_1.ServiceIdentifiers.IObfuscationResult);
                obfuscationResult.initialize(obfuscatedCodeAsString, sourceMapAsString);
                return obfuscationResult;
            };
        });
        this.container.load(AnalyzersModule_1.analyzersModule);
        this.container.load(CodeTransformersModule_1.codeTransformersModule);
        this.container.load(ControlFlowTransformersModule_1.controlFlowTransformersModule);
        this.container.load(ConvertingTransformersModule_1.convertingTransformersModule);
        this.container.load(CustomCodeHelpersModule_1.customCodeHelpersModule);
        this.container.load(CustomNodesModule_1.customNodesModule);
        this.container.load(DeadCodeInjectionTransformersModule_1.deadCodeInjectionTransformersModule);
        this.container.load(FinalizingTransformersModule_1.finalizingTransformersModule);
        this.container.load(GeneratorsModule_1.generatorsModule);
        this.container.load(InitializingTransformersModule_1.initializingTransformersModule);
        this.container.load(NodeModule_1.nodeModule);
        this.container.load(NodeTransformersModule_1.nodeTransformersModule);
        this.container.load(OptionsModule_1.optionsModule);
        this.container.load(PreparingTransformersModule_1.preparingTransformersModule);
        this.container.load(RenameIdentifiersTransformersModule_1.renameIdentifiersTransformersModule);
        this.container.load(RenamePropertiesTransformersModule_1.renamePropertiesTransformersModule);
        this.container.load(SimplifyingTransformersModule_1.simplifyingTransformersModule);
        this.container.load(StoragesModule_1.storagesModule);
        this.container.load(StringArrayTransformersModule_1.stringArrayTransformersModule);
        this.container.load(UtilsModule_1.utilsModule);
    }
    unload() {
        this.container.unbindAll();
    }
}
exports.InversifyContainerFacade = InversifyContainerFacade;
