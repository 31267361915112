"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.utilsModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const ArrayUtils_1 = require("../../../utils/ArrayUtils");
const CryptUtils_1 = require("../../../utils/CryptUtils");
const CryptUtilsStringArray_1 = require("../../../utils/CryptUtilsStringArray");
const EscapeSequenceEncoder_1 = require("../../../utils/EscapeSequenceEncoder");
const LevelledTopologicalSorter_1 = require("../../../utils/LevelledTopologicalSorter");
const RandomGenerator_1 = require("../../../utils/RandomGenerator");
const SetUtils_1 = require("../../../utils/SetUtils");
exports.utilsModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)
        .to(ArrayUtils_1.ArrayUtils)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)
        .to(RandomGenerator_1.RandomGenerator)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICryptUtils)
        .to(CryptUtils_1.CryptUtils)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICryptUtilsStringArray)
        .to(CryptUtilsStringArray_1.CryptUtilsStringArray)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IEscapeSequenceEncoder)
        .to(EscapeSequenceEncoder_1.EscapeSequenceEncoder)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ILevelledTopologicalSorter)
        .to(LevelledTopologicalSorter_1.LevelledTopologicalSorter);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ISetUtils)
        .to(SetUtils_1.SetUtils)
        .inSingletonScope();
});
