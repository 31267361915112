"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BinaryExpressionFunctionNode = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const AbstractCustomNode_1 = require("../AbstractCustomNode");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeUtils_1 = require("../../node/NodeUtils");
let BinaryExpressionFunctionNode = class BinaryExpressionFunctionNode extends AbstractCustomNode_1.AbstractCustomNode {
    constructor(identifierNamesGeneratorFactory, customCodeHelperFormatter, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, customCodeHelperFormatter, randomGenerator, options);
    }
    initialize(operator) {
        this.operator = operator;
    }
    getNodeStructure() {
        const structure = NodeFactory_1.NodeFactory.expressionStatementNode(NodeFactory_1.NodeFactory.functionExpressionNode([
            NodeFactory_1.NodeFactory.identifierNode('x'),
            NodeFactory_1.NodeFactory.identifierNode('y')
        ], NodeFactory_1.NodeFactory.blockStatementNode([
            NodeFactory_1.NodeFactory.returnStatementNode(NodeFactory_1.NodeFactory.binaryExpressionNode(this.operator, NodeFactory_1.NodeFactory.identifierNode('x'), NodeFactory_1.NodeFactory.identifierNode('y')))
        ])));
        NodeUtils_1.NodeUtils.parentizeAst(structure);
        return [structure];
    }
};
BinaryExpressionFunctionNode = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object, Object])
], BinaryExpressionFunctionNode);
exports.BinaryExpressionFunctionNode = BinaryExpressionFunctionNode;
