"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfDefendingTemplate = void 0;
function SelfDefendingTemplate() {
    return `
        const {selfDefendingFunctionName} = {callControllerFunctionName}(this, function () {
            return {selfDefendingFunctionName}
                .toString()
                .search('(((.+)+)+)+$')
                .toString()
                .constructor({selfDefendingFunctionName})
                .search('(((.+)+)+)+$');
        });
        
        {selfDefendingFunctionName}();
    `;
}
exports.SelfDefendingTemplate = SelfDefendingTemplate;
