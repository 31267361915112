"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceCode = void 0;
class SourceCode {
    constructor(sourceCode, sourceMap) {
        this.sourceCode = sourceCode;
        this.sourceMap = sourceMap;
    }
    getSourceCode() {
        return this.sourceCode;
    }
    getSourceMap() {
        return this.sourceMap;
    }
    toString() {
        return this.sourceCode;
    }
}
exports.SourceCode = SourceCode;
