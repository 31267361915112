"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayUtils = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../container/ServiceIdentifiers");
let ArrayUtils = class ArrayUtils {
    constructor(randomGenerator) {
        this.randomGenerator = randomGenerator;
    }
    createWithRange(length) {
        const range = [];
        for (let i = 0; i < length; i++) {
            range.push(i);
        }
        return range;
    }
    fillWithRange(length, valueFunction) {
        const range = [];
        for (let i = 0; i < length; i++) {
            range.push(valueFunction(i));
        }
        return range;
    }
    findMostOccurringElement(array) {
        var _a;
        const arrayLength = array.length;
        if (!arrayLength) {
            return null;
        }
        const elementsMap = {};
        let mostOccurringElement = array[0];
        let mostOccurringElementCount = 1;
        for (const element of array) {
            const currentElementCount = (_a = elementsMap[element]) !== null && _a !== void 0 ? _a : 0;
            const updatedElementCount = currentElementCount + 1;
            if (updatedElementCount > mostOccurringElementCount) {
                mostOccurringElement = element;
                mostOccurringElementCount = updatedElementCount;
            }
            elementsMap[element] = updatedElementCount;
        }
        return mostOccurringElement;
    }
    getLastElement(array) {
        return this.getLastElementByIndex(array, 0);
    }
    getLastElementByIndex(array, index) {
        var _a;
        const arrayLength = array.length;
        return (_a = array[arrayLength - 1 - index]) !== null && _a !== void 0 ? _a : undefined;
    }
    rotate(array, times) {
        if (!array.length) {
            throw new ReferenceError('Cannot rotate empty array.');
        }
        if (times <= 0) {
            return array;
        }
        const newArray = array;
        let temp;
        while (times--) {
            temp = newArray.pop();
            if (temp) {
                newArray.unshift(temp);
            }
        }
        return newArray;
    }
    shuffle(array) {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length; i; i--) {
            const j = Math.floor(this.randomGenerator.getMathRandom() * i);
            [shuffledArray[i - 1], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i - 1]];
        }
        return shuffledArray;
    }
};
ArrayUtils = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __metadata("design:paramtypes", [Object])
], ArrayUtils);
exports.ArrayUtils = ArrayUtils;
