"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var StringArrayStorageAnalyzer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayStorageAnalyzer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeLiteralUtils_1 = require("../../node/NodeLiteralUtils");
const NodeMetadata_1 = require("../../node/NodeMetadata");
let StringArrayStorageAnalyzer = StringArrayStorageAnalyzer_1 = class StringArrayStorageAnalyzer {
    constructor(stringArrayStorage, randomGenerator, options) {
        this.stringArrayStorageData = new WeakMap();
        this.stringArrayStorage = stringArrayStorage;
        this.randomGenerator = randomGenerator;
        this.options = options;
    }
    analyze(astTree) {
        if (!this.options.stringArray) {
            return;
        }
        estraverse.traverse(astTree, {
            enter: (node, parentNode) => {
                if (!parentNode) {
                    return;
                }
                if (NodeMetadata_1.NodeMetadata.isIgnoredNode(node)) {
                    return estraverse.VisitorOption.Skip;
                }
                if (!NodeGuards_1.NodeGuards.isLiteralNode(node)) {
                    return;
                }
                this.analyzeLiteralNode(node, parentNode);
            }
        });
    }
    analyzeLiteralNode(literalNode, parentNode) {
        if (!NodeLiteralUtils_1.NodeLiteralUtils.isStringLiteralNode(literalNode)) {
            return;
        }
        if (NodeLiteralUtils_1.NodeLiteralUtils.isProhibitedLiteralNode(literalNode, parentNode)) {
            return;
        }
        if (!this.shouldAddValueToStringArray(literalNode)) {
            return;
        }
        this.addItemDataForLiteralNode(literalNode);
    }
    addItemDataForLiteralNode(literalNode) {
        this.stringArrayStorageData.set(literalNode, this.stringArrayStorage.getOrThrow(literalNode.value));
    }
    getItemDataForLiteralNode(literalNode) {
        return this.stringArrayStorageData.get(literalNode);
    }
    shouldAddValueToStringArray(literalNode) {
        const isForceTransformNode = NodeMetadata_1.NodeMetadata.isForceTransformNode(literalNode);
        if (isForceTransformNode) {
            return true;
        }
        return literalNode.value.length >= StringArrayStorageAnalyzer_1.minimumLengthForStringArray
            && !!this.options.stringArrayThreshold
            && this.randomGenerator.getMathRandom() <= this.options.stringArrayThreshold;
    }
};
StringArrayStorageAnalyzer.minimumLengthForStringArray = 3;
StringArrayStorageAnalyzer = StringArrayStorageAnalyzer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object, Object])
], StringArrayStorageAnalyzer);
exports.StringArrayStorageAnalyzer = StringArrayStorageAnalyzer;
