"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EscapeSequenceTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeLiteralUtils_1 = require("../../node/NodeLiteralUtils");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeUtils_1 = require("../../node/NodeUtils");
let EscapeSequenceTransformer = class EscapeSequenceTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options, escapeSequenceEncoder) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.CustomCodeHelpersTransformer
        ];
        this.escapeSequenceEncoder = escapeSequenceEncoder;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Finalizing:
                return {
                    enter: (node, parentNode) => {
                        if (NodeGuards_1.NodeGuards.isLiteralNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(literalNode, parentNode) {
        if (!NodeLiteralUtils_1.NodeLiteralUtils.isStringLiteralNode(literalNode)) {
            return literalNode;
        }
        const encodedValue = this.escapeSequenceEncoder.encode(literalNode.value, this.options.unicodeEscapeSequence);
        const newLiteralNode = NodeFactory_1.NodeFactory.literalNode(encodedValue);
        NodeUtils_1.NodeUtils.parentizeNode(newLiteralNode, parentNode);
        return newLiteralNode;
    }
};
EscapeSequenceTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IEscapeSequenceEncoder)),
    __metadata("design:paramtypes", [Object, Object, Object])
], EscapeSequenceTransformer);
exports.EscapeSequenceTransformer = EscapeSequenceTransformer;
