"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.finalizingTransformersModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const DirectivePlacementTransformer_1 = require("../../../node-transformers/finalizing-transformers/DirectivePlacementTransformer");
const EscapeSequenceTransformer_1 = require("../../../node-transformers/finalizing-transformers/EscapeSequenceTransformer");
exports.finalizingTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(DirectivePlacementTransformer_1.DirectivePlacementTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.DirectivePlacementTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(EscapeSequenceTransformer_1.EscapeSequenceTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.EscapeSequenceTransformer);
});
