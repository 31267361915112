"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renameIdentifiersTransformersModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const DeadCodeInjectionIdentifiersTransformer_1 = require("../../../node-transformers/dead-code-injection-transformers/DeadCodeInjectionIdentifiersTransformer");
const IdentifierReplacer_1 = require("../../../node-transformers/rename-identifiers-transformers/replacer/IdentifierReplacer");
const LabeledStatementTransformer_1 = require("../../../node-transformers/rename-identifiers-transformers/LabeledStatementTransformer");
const ScopeIdentifiersTransformer_1 = require("../../../node-transformers/rename-identifiers-transformers/ScopeIdentifiersTransformer");
const ScopeThroughIdentifiersTransformer_1 = require("../../../node-transformers/rename-identifiers-transformers/ScopeThroughIdentifiersTransformer");
const ThroughIdentifierReplacer_1 = require("../../../node-transformers/rename-identifiers-transformers/through-replacer/ThroughIdentifierReplacer");
exports.renameIdentifiersTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(DeadCodeInjectionIdentifiersTransformer_1.DeadCodeInjectionIdentifiersTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.DeadCodeInjectionIdentifiersTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(LabeledStatementTransformer_1.LabeledStatementTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.LabeledStatementTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ScopeIdentifiersTransformer_1.ScopeIdentifiersTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ScopeIdentifiersTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ScopeThroughIdentifiersTransformer_1.ScopeThroughIdentifiersTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ScopeThroughIdentifiersTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierReplacer)
        .to(IdentifierReplacer_1.IdentifierReplacer)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IThroughIdentifierReplacer)
        .to(ThroughIdentifierReplacer_1.ThroughIdentifierReplacer)
        .inSingletonScope();
});
