"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var StringArrayTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const StringArrayCustomNode_1 = require("../../enums/custom-nodes/StringArrayCustomNode");
const StringArrayWrappersType_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayWrappersType");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeLiteralUtils_1 = require("../../node/NodeLiteralUtils");
const NodeMetadata_1 = require("../../node/NodeMetadata");
const NodeUtils_1 = require("../../node/NodeUtils");
let StringArrayTransformer = StringArrayTransformer_1 = class StringArrayTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options, literalNodesCacheStorage, visitedLexicalScopeNodesStackStorage, stringArrayStorage, stringArrayScopeCallsWrappersDataStorage, stringArrayStorageAnalyzer, identifierNamesGeneratorFactory, stringArrayTransformerCustomNodeFactory) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.StringArrayRotateFunctionTransformer
        ];
        this.literalNodesCacheStorage = literalNodesCacheStorage;
        this.visitedLexicalScopeNodesStackStorage = visitedLexicalScopeNodesStackStorage;
        this.stringArrayStorage = stringArrayStorage;
        this.stringArrayScopeCallsWrappersDataStorage = stringArrayScopeCallsWrappersDataStorage;
        this.stringArrayStorageAnalyzer = stringArrayStorageAnalyzer;
        this.identifierNamesGenerator = identifierNamesGeneratorFactory(options);
        this.stringArrayTransformerCustomNodeFactory = stringArrayTransformerCustomNodeFactory;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.StringArray:
                return {
                    enter: (node, parentNode) => {
                        if (NodeGuards_1.NodeGuards.isProgramNode(node)) {
                            this.prepareNode(node);
                        }
                        if (parentNode
                            && NodeGuards_1.NodeGuards.isLiteralNode(node)
                            && !NodeMetadata_1.NodeMetadata.isStringArrayCallLiteralNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    prepareNode(programNode) {
        if (this.options.stringArray) {
            this.stringArrayStorageAnalyzer.analyze(programNode);
        }
        if (this.options.stringArrayShuffle) {
            this.stringArrayStorage.shuffleStorage();
        }
        if (this.options.stringArrayRotate) {
            this.stringArrayStorage.rotateStorage();
        }
    }
    transformNode(literalNode, parentNode) {
        if (!NodeLiteralUtils_1.NodeLiteralUtils.isStringLiteralNode(literalNode)
            || NodeLiteralUtils_1.NodeLiteralUtils.isProhibitedLiteralNode(literalNode, parentNode)) {
            return literalNode;
        }
        const literalValue = literalNode.value;
        const stringArrayStorageItemData = this.stringArrayStorageAnalyzer.getItemDataForLiteralNode(literalNode);
        const cacheKey = this.literalNodesCacheStorage.buildKey(literalValue, stringArrayStorageItemData);
        const useCachedValue = this.literalNodesCacheStorage.shouldUseCachedValue(cacheKey, stringArrayStorageItemData);
        let resultNode;
        if (useCachedValue) {
            const nodeFromCache = this.literalNodesCacheStorage.get(cacheKey);
            resultNode = NodeUtils_1.NodeUtils.clone(nodeFromCache);
        }
        else {
            resultNode = stringArrayStorageItemData
                ? this.getStringArrayCallNode(stringArrayStorageItemData)
                : literalNode;
            this.literalNodesCacheStorage.set(cacheKey, resultNode);
        }
        NodeUtils_1.NodeUtils.parentizeNode(resultNode, parentNode);
        return resultNode;
    }
    getStringArrayCallNode(stringArrayStorageItemData) {
        const stringArrayScopeCallsWrapperData = this.getStringArrayScopeCallsWrapperData(stringArrayStorageItemData);
        const { decodeKey, index } = stringArrayStorageItemData;
        const stringArrayCallCustomNode = this.stringArrayTransformerCustomNodeFactory(StringArrayCustomNode_1.StringArrayCustomNode.StringArrayCallNode);
        stringArrayCallCustomNode.initialize(index, this.stringArrayStorage.getIndexShiftAmount(), stringArrayScopeCallsWrapperData, decodeKey);
        const statementNode = stringArrayCallCustomNode.getNode()[0];
        if (!NodeGuards_1.NodeGuards.isExpressionStatementNode(statementNode)) {
            throw new Error('`stringArrayCallCustomNode.getNode()[0]` should returns array with `ExpressionStatement` node');
        }
        return statementNode.expression;
    }
    getStringArrayScopeCallsWrapperData(stringArrayStorageItemData) {
        return !this.options.stringArrayWrappersCount
            ? this.getRootStringArrayScopeCallsWrapperData(stringArrayStorageItemData)
            : this.getUpperStringArrayScopeCallsWrapperData(stringArrayStorageItemData);
    }
    getRootStringArrayScopeCallsWrapperData(stringArrayStorageItemData) {
        const { encoding } = stringArrayStorageItemData;
        const rootStringArrayCallsWrapperName = this.stringArrayStorage.getStorageCallsWrapperName(encoding);
        return {
            name: rootStringArrayCallsWrapperName,
            index: 0,
            parameterIndexesData: null
        };
    }
    getUpperStringArrayScopeCallsWrapperData(stringArrayStorageItemData) {
        var _a, _b, _c;
        const { encoding } = stringArrayStorageItemData;
        const currentLexicalScopeBodyNode = (_a = this.visitedLexicalScopeNodesStackStorage.getLastElement()) !== null && _a !== void 0 ? _a : null;
        if (!currentLexicalScopeBodyNode) {
            throw new Error('Cannot find current lexical scope body node');
        }
        const stringArrayScopeCallsWrappersDataByEncoding = this.getAndUpdateStringArrayScopeCallsWrappersDataByEncoding(currentLexicalScopeBodyNode, stringArrayStorageItemData);
        const stringArrayScopeCallsWrappersData = (_c = (_b = stringArrayScopeCallsWrappersDataByEncoding[encoding]) === null || _b === void 0 ? void 0 : _b.scopeCallsWrappersData) !== null && _c !== void 0 ? _c : [];
        return this.randomGenerator
            .getRandomGenerator()
            .pickone(stringArrayScopeCallsWrappersData);
    }
    getAndUpdateStringArrayScopeCallsWrappersDataByEncoding(currentLexicalScopeBodyNode, stringArrayStorageItemData) {
        var _a, _b, _c;
        const { encoding } = stringArrayStorageItemData;
        const stringArrayScopeCallsWrappersDataByEncoding = (_a = this.stringArrayScopeCallsWrappersDataStorage.get(currentLexicalScopeBodyNode)) !== null && _a !== void 0 ? _a : {};
        const stringArrayScopeCallsWrappersData = (_c = (_b = stringArrayScopeCallsWrappersDataByEncoding[encoding]) === null || _b === void 0 ? void 0 : _b.scopeCallsWrappersData) !== null && _c !== void 0 ? _c : [];
        const isFilledScopeCallsWrapperNamesList = stringArrayScopeCallsWrappersData.length === this.options.stringArrayWrappersCount;
        if (isFilledScopeCallsWrapperNamesList) {
            return stringArrayScopeCallsWrappersDataByEncoding;
        }
        const nextScopeCallsWrapperName = NodeGuards_1.NodeGuards.isProgramNode(currentLexicalScopeBodyNode)
            ? this.identifierNamesGenerator.generateForGlobalScope()
            : this.identifierNamesGenerator.generateNext();
        const nextScopeCallsWrapperShiftedIndex = this.getStringArrayCallsWrapperShiftedIndex();
        const nextScopeCallsWrapperParameterIndexesData = this.getStringArrayCallsWrapperParameterIndexesData();
        stringArrayScopeCallsWrappersDataByEncoding[encoding] = {
            encoding,
            scopeCallsWrappersData: [
                ...stringArrayScopeCallsWrappersData,
                {
                    name: nextScopeCallsWrapperName,
                    index: nextScopeCallsWrapperShiftedIndex,
                    parameterIndexesData: nextScopeCallsWrapperParameterIndexesData
                }
            ]
        };
        this.stringArrayScopeCallsWrappersDataStorage.set(currentLexicalScopeBodyNode, stringArrayScopeCallsWrappersDataByEncoding);
        return stringArrayScopeCallsWrappersDataByEncoding;
    }
    getStringArrayCallsWrapperShiftedIndex() {
        return this.options.stringArrayWrappersType === StringArrayWrappersType_1.StringArrayWrappersType.Function
            ? this.randomGenerator.getRandomInteger(StringArrayTransformer_1.minShiftedIndexValue, StringArrayTransformer_1.maxShiftedIndexValue)
            : 0;
    }
    getStringArrayCallsWrapperParameterIndexesData() {
        if (this.options.stringArrayWrappersType !== StringArrayWrappersType_1.StringArrayWrappersType.Function) {
            return null;
        }
        const minIndexValue = 0;
        const maxIndexValue = this.options.stringArrayWrappersParametersMaxCount - 1;
        const valueIndexParameterIndex = this.randomGenerator
            .getRandomInteger(minIndexValue, maxIndexValue);
        const decodeKeyParameterIndex = this.randomGenerator
            .getRandomIntegerExcluding(minIndexValue, maxIndexValue, [valueIndexParameterIndex]);
        return {
            valueIndexParameterIndex,
            decodeKeyParameterIndex
        };
    }
};
StringArrayTransformer.minShiftedIndexValue = -1000;
StringArrayTransformer.maxShiftedIndexValue = 1000;
StringArrayTransformer = StringArrayTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ILiteralNodesCacheStorage)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IVisitedLexicalScopeNodesStackStorage)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayScopeCallsWrappersDataStorage)),
    __param(6, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorageAnalyzer)),
    __param(7, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(8, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayCustomNode)),
    __metadata("design:paramtypes", [Object, Object, Object, Object, Object, Object, Object, Function, Function])
], StringArrayTransformer);
exports.StringArrayTransformer = StringArrayTransformer;
