"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var ObfuscatingGuardsTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObfuscatingGuardsTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const ObfuscatingGuard_1 = require("../../enums/node-transformers/preparing-transformers/obfuscating-guards/ObfuscatingGuard");
const ObfuscatingGuardResult_1 = require("../../enums/node/ObfuscatingGuardResult");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeMetadata_1 = require("../../node/NodeMetadata");
let ObfuscatingGuardsTransformer = ObfuscatingGuardsTransformer_1 = class ObfuscatingGuardsTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(obfuscatingGuardFactory, randomGenerator, options) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.ParentificationTransformer,
            NodeTransformer_1.NodeTransformer.VariablePreserveTransformer
        ];
        this.obfuscatingGuards = ObfuscatingGuardsTransformer_1.obfuscatingGuardsList.map(obfuscatingGuardFactory);
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Preparing:
                return {
                    enter: (node, parentNode) => {
                        return this.transformNode(node, parentNode);
                    }
                };
            default:
                return null;
        }
    }
    transformNode(node, parentNode) {
        const obfuscatingGuardResults = this.obfuscatingGuards
            .map((obfuscatingGuard) => obfuscatingGuard.check(node));
        this.setNodeMetadata(node, obfuscatingGuardResults);
        return node;
    }
    setNodeMetadata(node, obfuscatingGuardResults) {
        const isTransformNode = obfuscatingGuardResults
            .every((obfuscatingGuardResult) => obfuscatingGuardResult === ObfuscatingGuardResult_1.ObfuscatingGuardResult.Transform);
        let isForceTransformNode = false;
        let isIgnoredNode = false;
        if (!isTransformNode) {
            isForceTransformNode = obfuscatingGuardResults
                .includes(ObfuscatingGuardResult_1.ObfuscatingGuardResult.ForceTransform);
            isIgnoredNode = !isForceTransformNode && obfuscatingGuardResults
                .includes(ObfuscatingGuardResult_1.ObfuscatingGuardResult.Ignore);
        }
        NodeMetadata_1.NodeMetadata.set(node, {
            forceTransformNode: isForceTransformNode && !NodeGuards_1.NodeGuards.isProgramNode(node),
            ignoredNode: isIgnoredNode && !NodeGuards_1.NodeGuards.isProgramNode(node)
        });
    }
};
ObfuscatingGuardsTransformer.obfuscatingGuardsList = [
    ObfuscatingGuard_1.ObfuscatingGuard.BlackListObfuscatingGuard,
    ObfuscatingGuard_1.ObfuscatingGuard.ConditionalCommentObfuscatingGuard,
    ObfuscatingGuard_1.ObfuscatingGuard.ForceTransformStringObfuscatingGuard,
    ObfuscatingGuard_1.ObfuscatingGuard.IgnoredImportObfuscatingGuard,
    ObfuscatingGuard_1.ObfuscatingGuard.ReservedStringObfuscatingGuard
];
ObfuscatingGuardsTransformer = ObfuscatingGuardsTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__INodeGuard)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object])
], ObfuscatingGuardsTransformer);
exports.ObfuscatingGuardsTransformer = ObfuscatingGuardsTransformer;
