"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeUtils = void 0;
const escodegen = __importStar(require("@javascript-obfuscator/escodegen"));
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const EcmaVersion_1 = require("../constants/EcmaVersion");
const ASTParserFacade_1 = require("../ASTParserFacade");
const NodeGuards_1 = require("./NodeGuards");
const NodeMetadata_1 = require("./NodeMetadata");
class NodeUtils {
    static addXVerbatimPropertyTo(literalNode) {
        literalNode['x-verbatim-property'] = {
            content: literalNode.raw,
            precedence: escodegen.Precedence.Primary
        };
        return literalNode;
    }
    static clone(astTree) {
        return NodeUtils.parentizeAst(NodeUtils.cloneRecursive(astTree));
    }
    static convertCodeToStructure(code) {
        const structure = ASTParserFacade_1.ASTParserFacade.parse(code, {
            ecmaVersion: EcmaVersion_1.ecmaVersion,
            sourceType: 'script'
        });
        estraverse.replace(structure, {
            enter: (node, parentNode) => {
                NodeUtils.parentizeNode(node, parentNode);
                if (NodeGuards_1.NodeGuards.isLiteralNode(node)) {
                    NodeUtils.addXVerbatimPropertyTo(node);
                }
                NodeMetadata_1.NodeMetadata.set(node, { ignoredNode: false });
                return node;
            }
        });
        return structure.body;
    }
    static convertStructureToCode(structure) {
        return structure.reduce((code, node) => {
            return code + escodegen.generate(node, {
                sourceMapWithCode: true
            }).code;
        }, '');
    }
    static getUnaryExpressionArgumentNode(unaryExpressionNode) {
        if (NodeGuards_1.NodeGuards.isUnaryExpressionNode(unaryExpressionNode.argument)) {
            return NodeUtils.getUnaryExpressionArgumentNode(unaryExpressionNode.argument);
        }
        return unaryExpressionNode.argument;
    }
    static parentizeAst(astTree) {
        var _a;
        const parentNode = (_a = astTree.parentNode) !== null && _a !== void 0 ? _a : null;
        estraverse.replace(astTree, {
            enter: NodeUtils.parentizeNode
        });
        if (parentNode) {
            astTree.parentNode = parentNode;
        }
        return astTree;
    }
    static parentizeNode(node, parentNode) {
        node.parentNode = parentNode !== null && parentNode !== void 0 ? parentNode : node;
        return node;
    }
    static cloneRecursive(node) {
        if (node === null) {
            return node;
        }
        const copy = {};
        const nodeKeys = Object.keys(node);
        nodeKeys
            .forEach((property) => {
            var _a;
            if (property === 'parentNode') {
                return;
            }
            const value = (_a = node[property]) !== null && _a !== void 0 ? _a : null;
            let clonedValue;
            if (value === null || value instanceof RegExp) {
                clonedValue = value;
            }
            else if (value instanceof Array) {
                clonedValue = value.map(NodeUtils.cloneRecursive);
            }
            else if (typeof value === 'object') {
                clonedValue = NodeUtils.cloneRecursive(value);
            }
            else {
                clonedValue = value;
            }
            copy[property] = clonedValue;
        });
        return copy;
    }
}
exports.NodeUtils = NodeUtils;
