"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentifierNamesCacheRule = void 0;
const IdentifierNamesCacheRule = (options) => {
    let identifierNamesCache = options.identifierNamesCache;
    if (identifierNamesCache && !identifierNamesCache.globalIdentifiers) {
        identifierNamesCache = {
            ...identifierNamesCache,
            globalIdentifiers: {}
        };
    }
    if (identifierNamesCache && !identifierNamesCache.propertyIdentifiers) {
        identifierNamesCache = {
            ...identifierNamesCache,
            propertyIdentifiers: {}
        };
    }
    options = {
        ...options,
        identifierNamesCache
    };
    return options;
};
exports.IdentifierNamesCacheRule = IdentifierNamesCacheRule;
