"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var FunctionControlFlowTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctionControlFlowTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const ControlFlowCustomNode_1 = require("../../enums/custom-nodes/ControlFlowCustomNode");
const ControlFlowReplacer_1 = require("../../enums/node-transformers/control-flow-transformers/control-flow-replacers/ControlFlowReplacer");
const ControlFlowStorage_1 = require("../../enums/storages/ControlFlowStorage");
const NodeType_1 = require("../../enums/node/NodeType");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeAppender_1 = require("../../node/NodeAppender");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeMetadata_1 = require("../../node/NodeMetadata");
const NodeStatementUtils_1 = require("../../node/NodeStatementUtils");
const NodeUtils_1 = require("../../node/NodeUtils");
let FunctionControlFlowTransformer = FunctionControlFlowTransformer_1 = class FunctionControlFlowTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(controlFlowStorageFactoryCreator, controlFlowReplacerFactory, controlFlowCustomNodeFactory, randomGenerator, options) {
        super(randomGenerator, options);
        this.controlFlowReplacersMap = new Map([
            [NodeType_1.NodeType.BinaryExpression, ControlFlowReplacer_1.ControlFlowReplacer.BinaryExpressionControlFlowReplacer],
            [NodeType_1.NodeType.CallExpression, ControlFlowReplacer_1.ControlFlowReplacer.CallExpressionControlFlowReplacer],
            [NodeType_1.NodeType.LogicalExpression, ControlFlowReplacer_1.ControlFlowReplacer.LogicalExpressionControlFlowReplacer],
            [NodeType_1.NodeType.Literal, ControlFlowReplacer_1.ControlFlowReplacer.StringLiteralControlFlowReplacer]
        ]);
        this.controlFlowData = new WeakMap();
        this.hostNodesWithControlFlowNode = new WeakMap();
        this.visitedFunctionNodes = new WeakSet();
        this.controlFlowStorageFactory = controlFlowStorageFactoryCreator(ControlFlowStorage_1.ControlFlowStorage.FunctionControlFlowStorage);
        this.controlFlowReplacerFactory = controlFlowReplacerFactory;
        this.controlFlowCustomNodeFactory = controlFlowCustomNodeFactory;
    }
    getVisitor(nodeTransformationStage) {
        if (!this.options.controlFlowFlattening) {
            return null;
        }
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.ControlFlowFlattening:
                return {
                    leave: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isFunctionNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(functionNode, parentNode) {
        this.visitedFunctionNodes.add(functionNode);
        if (!NodeGuards_1.NodeGuards.isBlockStatementNode(functionNode.body)) {
            return functionNode;
        }
        const hostNode = this.getHostNode(functionNode.body);
        const controlFlowStorage = this.getControlFlowStorage(hostNode);
        this.transformFunctionBody(functionNode, controlFlowStorage);
        if (!controlFlowStorage.getLength()) {
            return functionNode;
        }
        const controlFlowStorageNode = this.getControlFlowStorageNode(controlFlowStorage);
        this.appendControlFlowStorageNode(hostNode, controlFlowStorageNode);
        return functionNode;
    }
    transformFunctionBody(functionNode, controlFlowStorage) {
        estraverse.replace(functionNode.body, {
            enter: (node, parentNode) => this.transformFunctionBodyNode(node, parentNode, functionNode, controlFlowStorage)
        });
    }
    transformFunctionBodyNode(node, parentNode, functionNode, controlFlowStorage) {
        var _a;
        const shouldSkipTraverse = !parentNode
            || NodeMetadata_1.NodeMetadata.isIgnoredNode(node)
            || this.isVisitedFunctionNode(node);
        if (shouldSkipTraverse) {
            return estraverse.VisitorOption.Skip;
        }
        const controlFlowReplacerName = (_a = this.controlFlowReplacersMap.get(node.type)) !== null && _a !== void 0 ? _a : null;
        if (!controlFlowReplacerName) {
            return node;
        }
        if (!this.isAllowedTransformationByThreshold()) {
            return node;
        }
        const replacedNode = this.controlFlowReplacerFactory(controlFlowReplacerName)
            .replace(node, parentNode, controlFlowStorage);
        NodeUtils_1.NodeUtils.parentizeNode(replacedNode, parentNode);
        return replacedNode;
    }
    getHostNode(functionNodeBody) {
        const blockScopesOfNode = NodeStatementUtils_1.NodeStatementUtils.getParentNodesWithStatements(functionNodeBody);
        if (blockScopesOfNode.length === 1) {
            return functionNodeBody;
        }
        else {
            blockScopesOfNode.pop();
        }
        if (blockScopesOfNode.length > FunctionControlFlowTransformer_1.hostNodeSearchMinDepth) {
            blockScopesOfNode.splice(0, FunctionControlFlowTransformer_1.hostNodeSearchMinDepth);
        }
        if (blockScopesOfNode.length > FunctionControlFlowTransformer_1.hostNodeSearchMaxDepth) {
            blockScopesOfNode.length = FunctionControlFlowTransformer_1.hostNodeSearchMaxDepth;
        }
        return this.randomGenerator.getRandomGenerator().pickone(blockScopesOfNode);
    }
    getControlFlowStorage(hostNode) {
        var _a, _b;
        let controlFlowStorage;
        const hostControlFlowStorage = (_a = this.controlFlowData.get(hostNode)) !== null && _a !== void 0 ? _a : null;
        if (!hostControlFlowStorage) {
            controlFlowStorage = this.controlFlowStorageFactory();
        }
        else {
            const existingControlFlowStorageNode = (_b = this.hostNodesWithControlFlowNode.get(hostNode)) !== null && _b !== void 0 ? _b : null;
            if (existingControlFlowStorageNode) {
                NodeAppender_1.NodeAppender.remove(hostNode, existingControlFlowStorageNode);
            }
            controlFlowStorage = hostControlFlowStorage;
        }
        this.controlFlowData.set(hostNode, controlFlowStorage);
        return controlFlowStorage;
    }
    getControlFlowStorageNode(controlFlowStorage) {
        const controlFlowStorageCustomNode = this.controlFlowCustomNodeFactory(ControlFlowCustomNode_1.ControlFlowCustomNode.ControlFlowStorageNode);
        controlFlowStorageCustomNode.initialize(controlFlowStorage);
        const controlFlowStorageNode = controlFlowStorageCustomNode.getNode()[0];
        if (!NodeGuards_1.NodeGuards.isVariableDeclarationNode(controlFlowStorageNode)) {
            throw new Error('`controlFlowStorageNode` should contain `VariableDeclaration` node with control flow storage object');
        }
        return controlFlowStorageNode;
    }
    appendControlFlowStorageNode(hostNode, controlFlowStorageNode) {
        NodeUtils_1.NodeUtils.parentizeAst(controlFlowStorageNode);
        NodeAppender_1.NodeAppender.prepend(hostNode, [controlFlowStorageNode]);
        this.hostNodesWithControlFlowNode.set(hostNode, controlFlowStorageNode);
    }
    isVisitedFunctionNode(node) {
        return NodeGuards_1.NodeGuards.isFunctionNode(node) && this.visitedFunctionNodes.has(node);
    }
    isAllowedTransformationByThreshold() {
        return this.randomGenerator.getMathRandom() <= this.options.controlFlowFlatteningThreshold;
    }
};
FunctionControlFlowTransformer.hostNodeSearchMinDepth = 0;
FunctionControlFlowTransformer.hostNodeSearchMaxDepth = 2;
FunctionControlFlowTransformer = FunctionControlFlowTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__TControlFlowStorage)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowReplacer)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowCustomNode)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Function, Function, Object, Object])
], FunctionControlFlowTransformer);
exports.FunctionControlFlowTransformer = FunctionControlFlowTransformer;
