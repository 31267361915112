"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var Options_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Options = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../container/ServiceIdentifiers");
const class_validator_1 = require("class-validator");
const IdentifierNamesGenerator_1 = require("../enums/generators/identifier-names-generators/IdentifierNamesGenerator");
const ObfuscationTarget_1 = require("../enums/ObfuscationTarget");
const OptionsPreset_1 = require("../enums/options/presets/OptionsPreset");
const RenamePropertiesMode_1 = require("../enums/node-transformers/rename-properties-transformers/RenamePropertiesMode");
const SourceMapMode_1 = require("../enums/source-map/SourceMapMode");
const SourceMapSourcesMode_1 = require("../enums/source-map/SourceMapSourcesMode");
const StringArrayIndexesType_1 = require("../enums/node-transformers/string-array-transformers/StringArrayIndexesType");
const StringArrayEncoding_1 = require("../enums/node-transformers/string-array-transformers/StringArrayEncoding");
const StringArrayWrappersType_1 = require("../enums/node-transformers/string-array-transformers/StringArrayWrappersType");
const Default_1 = require("./presets/Default");
const LowObfuscation_1 = require("./presets/LowObfuscation");
const MediumObfuscation_1 = require("./presets/MediumObfuscation");
const HighObfuscation_1 = require("./presets/HighObfuscation");
const ValidationErrorsFormatter_1 = require("./ValidationErrorsFormatter");
const IsAllowedForObfuscationTargets_1 = require("./validators/IsAllowedForObfuscationTargets");
const IsDomainLockRedirectUrl_1 = require("./validators/IsDomainLockRedirectUrl");
const IsIdentifierNamesCache_1 = require("./validators/IsIdentifierNamesCache");
const IsInputFileName_1 = require("./validators/IsInputFileName");
let Options = Options_1 = class Options {
    constructor(inputOptions, optionsNormalizer) {
        var _a;
        const optionsPreset = Options_1.getOptionsByPreset((_a = inputOptions.optionsPreset) !== null && _a !== void 0 ? _a : OptionsPreset_1.OptionsPreset.Default);
        Object.assign(this, optionsPreset, inputOptions);
        const errors = (0, class_validator_1.validateSync)(this, Options_1.validatorOptions);
        if (errors.length) {
            throw new ReferenceError(`Validation failed. errors:\n${ValidationErrorsFormatter_1.ValidationErrorsFormatter.format(errors)}`);
        }
        Object.assign(this, optionsNormalizer.normalize(this));
    }
    static getOptionsByPreset(optionsPreset) {
        var _a;
        const options = (_a = Options_1.optionPresetsMap.get(optionsPreset)) !== null && _a !== void 0 ? _a : null;
        if (!options) {
            throw new Error(`Options for preset name \`${optionsPreset}\` are not found`);
        }
        return options;
    }
};
Options.optionPresetsMap = new Map([
    [OptionsPreset_1.OptionsPreset.Default, Default_1.DEFAULT_PRESET],
    [OptionsPreset_1.OptionsPreset.LowObfuscation, LowObfuscation_1.LOW_OBFUSCATION_PRESET],
    [OptionsPreset_1.OptionsPreset.MediumObfuscation, MediumObfuscation_1.MEDIUM_OBFUSCATION_PRESET],
    [OptionsPreset_1.OptionsPreset.HighObfuscation, HighObfuscation_1.HIGH_OBFUSCATION_PRESET]
]);
Options.validatorOptions = {
    forbidUnknownValues: true,
    validationError: {
        target: false
    }
};
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "compact", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "controlFlowFlattening", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    (0, class_validator_1.Max)(1),
    __metadata("design:type", Number)
], Options.prototype, "controlFlowFlatteningThreshold", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "deadCodeInjection", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], Options.prototype, "deadCodeInjectionThreshold", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "debugProtection", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], Options.prototype, "debugProtectionInterval", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "disableConsoleOutput", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsString)({
        each: true
    }),
    (0, IsAllowedForObfuscationTargets_1.IsAllowedForObfuscationTargets)([
        ObfuscationTarget_1.ObfuscationTarget.Browser,
        ObfuscationTarget_1.ObfuscationTarget.BrowserNoEval,
    ]),
    __metadata("design:type", Array)
], Options.prototype, "domainLock", void 0);
__decorate([
    (0, IsDomainLockRedirectUrl_1.IsDomainLockRedirectUrl)(),
    __metadata("design:type", String)
], Options.prototype, "domainLockRedirectUrl", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsString)({
        each: true
    }),
    __metadata("design:type", Array)
], Options.prototype, "forceTransformStrings", void 0);
__decorate([
    (0, IsIdentifierNamesCache_1.IsIdentifierNamesCache)(),
    __metadata("design:type", Object)
], Options.prototype, "identifierNamesCache", void 0);
__decorate([
    (0, class_validator_1.IsIn)([
        IdentifierNamesGenerator_1.IdentifierNamesGenerator.DictionaryIdentifierNamesGenerator,
        IdentifierNamesGenerator_1.IdentifierNamesGenerator.HexadecimalIdentifierNamesGenerator,
        IdentifierNamesGenerator_1.IdentifierNamesGenerator.MangledIdentifierNamesGenerator,
        IdentifierNamesGenerator_1.IdentifierNamesGenerator.MangledShuffledIdentifierNamesGenerator
    ]),
    __metadata("design:type", Object)
], Options.prototype, "identifierNamesGenerator", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], Options.prototype, "identifiersPrefix", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsString)({
        each: true
    }),
    (0, class_validator_1.ValidateIf)((options) => options.identifierNamesGenerator === IdentifierNamesGenerator_1.IdentifierNamesGenerator.DictionaryIdentifierNamesGenerator),
    (0, class_validator_1.ArrayNotEmpty)(),
    __metadata("design:type", Array)
], Options.prototype, "identifiersDictionary", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "ignoreImports", void 0);
__decorate([
    (0, IsInputFileName_1.IsInputFileName)(),
    __metadata("design:type", String)
], Options.prototype, "inputFileName", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "log", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "numbersToExpressions", void 0);
__decorate([
    (0, class_validator_1.IsIn)([
        OptionsPreset_1.OptionsPreset.Default,
        OptionsPreset_1.OptionsPreset.LowObfuscation,
        OptionsPreset_1.OptionsPreset.MediumObfuscation,
        OptionsPreset_1.OptionsPreset.HighObfuscation
    ]),
    __metadata("design:type", String)
], Options.prototype, "optionsPreset", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "renameGlobals", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "renameProperties", void 0);
__decorate([
    (0, class_validator_1.IsIn)([RenamePropertiesMode_1.RenamePropertiesMode.Safe, RenamePropertiesMode_1.RenamePropertiesMode.Unsafe]),
    __metadata("design:type", String)
], Options.prototype, "renamePropertiesMode", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsString)({
        each: true
    }),
    __metadata("design:type", Array)
], Options.prototype, "reservedNames", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsString)({
        each: true
    }),
    __metadata("design:type", Array)
], Options.prototype, "reservedStrings", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "selfDefending", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "simplify", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "sourceMap", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.ValidateIf)((options) => Boolean(options.sourceMapBaseUrl)),
    (0, class_validator_1.IsUrl)({
        require_protocol: true,
        require_tld: false,
        require_valid_protocol: true
    }),
    __metadata("design:type", String)
], Options.prototype, "sourceMapBaseUrl", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], Options.prototype, "sourceMapFileName", void 0);
__decorate([
    (0, class_validator_1.IsIn)([SourceMapMode_1.SourceMapMode.Inline, SourceMapMode_1.SourceMapMode.Separate]),
    __metadata("design:type", Object)
], Options.prototype, "sourceMapMode", void 0);
__decorate([
    (0, class_validator_1.IsIn)([SourceMapSourcesMode_1.SourceMapSourcesMode.Sources, SourceMapSourcesMode_1.SourceMapSourcesMode.SourcesContent]),
    __metadata("design:type", Object)
], Options.prototype, "sourceMapSourcesMode", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "splitStrings", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.ValidateIf)((options) => Boolean(options.splitStrings)),
    (0, class_validator_1.Min)(1),
    __metadata("design:type", Number)
], Options.prototype, "splitStringsChunkLength", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "stringArray", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "stringArrayCallsTransform", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    (0, class_validator_1.Max)(1),
    __metadata("design:type", Number)
], Options.prototype, "stringArrayCallsTransformThreshold", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsIn)([StringArrayEncoding_1.StringArrayEncoding.None, StringArrayEncoding_1.StringArrayEncoding.Base64, StringArrayEncoding_1.StringArrayEncoding.Rc4], { each: true }),
    __metadata("design:type", Array)
], Options.prototype, "stringArrayEncoding", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayNotEmpty)(),
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsIn)([StringArrayIndexesType_1.StringArrayIndexesType.HexadecimalNumber, StringArrayIndexesType_1.StringArrayIndexesType.HexadecimalNumericString], { each: true }),
    __metadata("design:type", Array)
], Options.prototype, "stringArrayIndexesType", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "stringArrayIndexShift", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "stringArrayRotate", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "stringArrayShuffle", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "stringArrayWrappersChainedCalls", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], Options.prototype, "stringArrayWrappersCount", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(2),
    __metadata("design:type", Number)
], Options.prototype, "stringArrayWrappersParametersMaxCount", void 0);
__decorate([
    (0, class_validator_1.IsIn)([StringArrayWrappersType_1.StringArrayWrappersType.Variable, StringArrayWrappersType_1.StringArrayWrappersType.Function]),
    __metadata("design:type", String)
], Options.prototype, "stringArrayWrappersType", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    (0, class_validator_1.Max)(1),
    __metadata("design:type", Number)
], Options.prototype, "stringArrayThreshold", void 0);
__decorate([
    (0, class_validator_1.IsIn)([ObfuscationTarget_1.ObfuscationTarget.Browser, ObfuscationTarget_1.ObfuscationTarget.BrowserNoEval, ObfuscationTarget_1.ObfuscationTarget.Node, ObfuscationTarget_1.ObfuscationTarget.ServiceWorker]),
    __metadata("design:type", Object)
], Options.prototype, "target", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "transformObjectKeys", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], Options.prototype, "unicodeEscapeSequence", void 0);
Options = Options_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.TInputOptions)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptionsNormalizer)),
    __metadata("design:paramtypes", [Object, Object])
], Options);
exports.Options = Options;
