"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generatorsModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const IdentifierNamesGenerator_1 = require("../../../enums/generators/identifier-names-generators/IdentifierNamesGenerator");
const DictionaryIdentifierNamesGenerator_1 = require("../../../generators/identifier-names-generators/DictionaryIdentifierNamesGenerator");
const HexadecimalIdentifierNamesGenerator_1 = require("../../../generators/identifier-names-generators/HexadecimalIdentifierNamesGenerator");
const MangledIdentifierNamesGenerator_1 = require("../../../generators/identifier-names-generators/MangledIdentifierNamesGenerator");
const MangledShuffledIdentifierNamesGenerator_1 = require("../../../generators/identifier-names-generators/MangledShuffledIdentifierNamesGenerator");
exports.generatorsModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierNamesGenerator)
        .to(DictionaryIdentifierNamesGenerator_1.DictionaryIdentifierNamesGenerator)
        .inSingletonScope()
        .whenTargetNamed(IdentifierNamesGenerator_1.IdentifierNamesGenerator.DictionaryIdentifierNamesGenerator);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierNamesGenerator)
        .to(HexadecimalIdentifierNamesGenerator_1.HexadecimalIdentifierNamesGenerator)
        .inSingletonScope()
        .whenTargetNamed(IdentifierNamesGenerator_1.IdentifierNamesGenerator.HexadecimalIdentifierNamesGenerator);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierNamesGenerator)
        .to(MangledIdentifierNamesGenerator_1.MangledIdentifierNamesGenerator)
        .inSingletonScope()
        .whenTargetNamed(IdentifierNamesGenerator_1.IdentifierNamesGenerator.MangledIdentifierNamesGenerator);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierNamesGenerator)
        .to(MangledShuffledIdentifierNamesGenerator_1.MangledShuffledIdentifierNamesGenerator)
        .inSingletonScope()
        .whenTargetNamed(IdentifierNamesGenerator_1.IdentifierNamesGenerator.MangledShuffledIdentifierNamesGenerator);
    function identifierNameGeneratorFactory() {
        let cachedIdentifierNamesGenerator = null;
        return (context) => (options) => {
            if (cachedIdentifierNamesGenerator) {
                return cachedIdentifierNamesGenerator;
            }
            let identifierNamesGenerator;
            switch (options.identifierNamesGenerator) {
                case IdentifierNamesGenerator_1.IdentifierNamesGenerator.DictionaryIdentifierNamesGenerator:
                    identifierNamesGenerator = context.container.getNamed(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierNamesGenerator, IdentifierNamesGenerator_1.IdentifierNamesGenerator.DictionaryIdentifierNamesGenerator);
                    break;
                case IdentifierNamesGenerator_1.IdentifierNamesGenerator.MangledIdentifierNamesGenerator:
                    identifierNamesGenerator = context.container.getNamed(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierNamesGenerator, IdentifierNamesGenerator_1.IdentifierNamesGenerator.MangledIdentifierNamesGenerator);
                    break;
                case IdentifierNamesGenerator_1.IdentifierNamesGenerator.MangledShuffledIdentifierNamesGenerator:
                    identifierNamesGenerator = context.container.getNamed(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierNamesGenerator, IdentifierNamesGenerator_1.IdentifierNamesGenerator.MangledShuffledIdentifierNamesGenerator);
                    break;
                case IdentifierNamesGenerator_1.IdentifierNamesGenerator.HexadecimalIdentifierNamesGenerator:
                default:
                    identifierNamesGenerator = context.container.getNamed(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierNamesGenerator, IdentifierNamesGenerator_1.IdentifierNamesGenerator.HexadecimalIdentifierNamesGenerator);
            }
            cachedIdentifierNamesGenerator = identifierNamesGenerator;
            return identifierNamesGenerator;
        };
    }
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)
        .toFactory(identifierNameGeneratorFactory());
});
