"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayRule = void 0;
const StringArrayEncoding_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayEncoding");
const StringArrayRule = (options) => {
    if (!options.stringArray) {
        options = {
            ...options,
            stringArray: false,
            stringArrayCallsTransform: false,
            stringArrayCallsTransformThreshold: 0,
            stringArrayEncoding: [
                StringArrayEncoding_1.StringArrayEncoding.None
            ],
            stringArrayIndexShift: false,
            stringArrayRotate: false,
            stringArrayShuffle: false,
            stringArrayWrappersChainedCalls: false,
            stringArrayWrappersCount: 0,
            stringArrayThreshold: 0
        };
    }
    return options;
};
exports.StringArrayRule = StringArrayRule;
