"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControlFlowReplacer = void 0;
var ControlFlowReplacer;
(function (ControlFlowReplacer) {
    ControlFlowReplacer["BinaryExpressionControlFlowReplacer"] = "BinaryExpressionControlFlowReplacer";
    ControlFlowReplacer["CallExpressionControlFlowReplacer"] = "CallExpressionControlFlowReplacer";
    ControlFlowReplacer["LogicalExpressionControlFlowReplacer"] = "LogicalExpressionControlFlowReplacer";
    ControlFlowReplacer["StringArrayCallControlFlowReplacer"] = "StringArrayCallControlFlowReplacer";
    ControlFlowReplacer["StringLiteralControlFlowReplacer"] = "StringLiteralControlFlowReplacer";
})(ControlFlowReplacer = exports.ControlFlowReplacer || (exports.ControlFlowReplacer = {}));
