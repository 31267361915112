"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebuggerTemplateNoEval = void 0;
function DebuggerTemplateNoEval() {
    return `
        if (typeof counter === 'string') {
            const func = function () {
                while (true) {}
            };
            
            return func();
        } else {
            if (('' + counter / counter)['length'] !== 1 || counter % 20 === 0) {
                debugger;
            } else {
                debugger;
            }
            
        }
    `;
}
exports.DebuggerTemplateNoEval = DebuggerTemplateNoEval;
