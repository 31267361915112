"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugProtectionFunctionIntervalTemplate = void 0;
function DebugProtectionFunctionIntervalTemplate() {
    return `
        (function () {
            {globalVariableTemplate}
    
            that.setInterval({debugProtectionFunctionName}, {debugProtectionInterval});
        })();
    `;
}
exports.DebugProtectionFunctionIntervalTemplate = DebugProtectionFunctionIntervalTemplate;
