"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationErrorsFormatter = void 0;
class ValidationErrorsFormatter {
    static format(errors) {
        return errors
            .reduce((errorMessages, error) => [
            ...errorMessages,
            ValidationErrorsFormatter.formatWithNestedConstraints(error)
        ], [])
            .join('\n');
    }
    static formatWithNestedConstraints(error) {
        const constraints = error.constraints;
        if (!constraints) {
            return `\`${error.property}\` error\n`;
        }
        const rootError = `\`${error.property}\` errors:\n`;
        const nestedErrors = Object
            .keys(constraints)
            .map((constraint) => `    - ${constraints[constraint]}\n`)
            .join();
        return `${rootError}${nestedErrors}`;
    }
}
exports.ValidationErrorsFormatter = ValidationErrorsFormatter;
