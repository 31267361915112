"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var StringArrayCallControlFlowReplacer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayCallControlFlowReplacer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const ControlFlowCustomNode_1 = require("../../../enums/custom-nodes/ControlFlowCustomNode");
const AbstractControlFlowReplacer_1 = require("./AbstractControlFlowReplacer");
const NodeGuards_1 = require("../../../node/NodeGuards");
const NodeLiteralUtils_1 = require("../../../node/NodeLiteralUtils");
const NodeMetadata_1 = require("../../../node/NodeMetadata");
let StringArrayCallControlFlowReplacer = StringArrayCallControlFlowReplacer_1 = class StringArrayCallControlFlowReplacer extends AbstractControlFlowReplacer_1.AbstractControlFlowReplacer {
    constructor(controlFlowCustomNodeFactory, identifierNamesGeneratorFactory, randomGenerator, options) {
        super(controlFlowCustomNodeFactory, identifierNamesGeneratorFactory, randomGenerator, options);
    }
    replace(literalNode, parentNode, controlFlowStorage) {
        const isStringArrayCallLiteralNode = NodeMetadata_1.NodeMetadata.isStringArrayCallLiteralNode(literalNode)
            && (NodeLiteralUtils_1.NodeLiteralUtils.isNumberLiteralNode(literalNode)
                || NodeLiteralUtils_1.NodeLiteralUtils.isStringLiteralNode(literalNode));
        if (!isStringArrayCallLiteralNode) {
            return literalNode;
        }
        const replacerId = literalNode.value;
        const literalCustomNode = this.controlFlowCustomNodeFactory(ControlFlowCustomNode_1.ControlFlowCustomNode.LiteralNode);
        literalCustomNode.initialize(literalNode);
        const storageKey = this.insertCustomNodeToControlFlowStorage(literalCustomNode, controlFlowStorage, replacerId, StringArrayCallControlFlowReplacer_1.usingExistingIdentifierChance);
        return this.getControlFlowStorageCallNode(controlFlowStorage.getStorageId(), storageKey);
    }
    generateStorageKey(controlFlowStorage) {
        const key = this.identifierNamesGenerator
            .generateForLabel(controlFlowStorage.getStorageId());
        if (controlFlowStorage.has(key)) {
            return this.generateStorageKey(controlFlowStorage);
        }
        return key;
    }
    getControlFlowStorageCallNode(controlFlowStorageId, storageKey) {
        const controlFlowStorageCallCustomNode = this.controlFlowCustomNodeFactory(ControlFlowCustomNode_1.ControlFlowCustomNode.StringLiteralControlFlowStorageCallNode);
        controlFlowStorageCallCustomNode.initialize(controlFlowStorageId, storageKey);
        const statementNode = controlFlowStorageCallCustomNode.getNode()[0];
        if (!statementNode || !NodeGuards_1.NodeGuards.isExpressionStatementNode(statementNode)) {
            throw new Error('`controlFlowStorageCallCustomNode.getNode()[0]` should returns array with `ExpressionStatement` node');
        }
        return statementNode.expression;
    }
};
StringArrayCallControlFlowReplacer.usingExistingIdentifierChance = 0.5;
StringArrayCallControlFlowReplacer = StringArrayCallControlFlowReplacer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowCustomNode)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Function, Object, Object])
], StringArrayCallControlFlowReplacer);
exports.StringArrayCallControlFlowReplacer = StringArrayCallControlFlowReplacer;
