"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalVariableNoEvalTemplate = void 0;
function GlobalVariableNoEvalTemplate() {
    return `
        const that = (typeof window !== 'undefined'
           ? window
           : (typeof process === 'object' &&
              typeof require === 'function' &&
              typeof global === 'object')
             ? global
             : this);
    `;
}
exports.GlobalVariableNoEvalTemplate = GlobalVariableNoEvalTemplate;
