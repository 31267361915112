"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storagesModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const ControlFlowStorage_1 = require("../../../enums/storages/ControlFlowStorage");
const CustomCodeHelperGroupStorage_1 = require("../../../storages/custom-code-helpers/CustomCodeHelperGroupStorage");
const FunctionControlFlowStorage_1 = require("../../../storages/control-flow-transformers/FunctionControlFlowStorage");
const GlobalIdentifierNamesCacheStorage_1 = require("../../../storages/identifier-names-cache/GlobalIdentifierNamesCacheStorage");
const LiteralNodesCacheStorage_1 = require("../../../storages/string-array-transformers/LiteralNodesCacheStorage");
const PropertyIdentifierNamesCacheStorage_1 = require("../../../storages/identifier-names-cache/PropertyIdentifierNamesCacheStorage");
const StringArrayScopeCallsWrappersDataStorage_1 = require("../../../storages/string-array-transformers/StringArrayScopeCallsWrappersDataStorage");
const StringControlFlowStorage_1 = require("../../../storages/control-flow-transformers/StringControlFlowStorage");
const StringArrayStorage_1 = require("../../../storages/string-array-transformers/StringArrayStorage");
const VisitedLexicalScopeNodesStackStorage_1 = require("../../../storages/string-array-transformers/VisitedLexicalScopeNodesStackStorage");
exports.storagesModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.TCustomNodeGroupStorage)
        .to(CustomCodeHelperGroupStorage_1.CustomCodeHelperGroupStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IControlFlowStorage)
        .to(FunctionControlFlowStorage_1.FunctionControlFlowStorage)
        .whenTargetNamed(ControlFlowStorage_1.ControlFlowStorage.FunctionControlFlowStorage);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IGlobalIdentifierNamesCacheStorage)
        .to(GlobalIdentifierNamesCacheStorage_1.GlobalIdentifierNamesCacheStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ILiteralNodesCacheStorage)
        .to(LiteralNodesCacheStorage_1.LiteralNodesCacheStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IPropertyIdentifierNamesCacheStorage)
        .to(PropertyIdentifierNamesCacheStorage_1.PropertyIdentifierNamesCacheStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)
        .to(StringArrayStorage_1.StringArrayStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayScopeCallsWrappersDataStorage)
        .to(StringArrayScopeCallsWrappersDataStorage_1.StringArrayScopeCallsWrappersDataStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IControlFlowStorage)
        .to(StringControlFlowStorage_1.StringControlFlowStorage)
        .whenTargetNamed(ControlFlowStorage_1.ControlFlowStorage.StringControlFlowStorage);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IVisitedLexicalScopeNodesStackStorage)
        .to(VisitedLexicalScopeNodesStackStorage_1.VisitedLexicalScopeNodesStackStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__TControlFlowStorage)
        .toFactory((context) => (controlFlowStorageName) => () => context.container.getNamed(ServiceIdentifiers_1.ServiceIdentifiers.IControlFlowStorage, controlFlowStorageName));
});
