"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeTransformationStage = void 0;
var NodeTransformationStage;
(function (NodeTransformationStage) {
    NodeTransformationStage["ControlFlowFlattening"] = "ControlFlowFlattening";
    NodeTransformationStage["Converting"] = "Converting";
    NodeTransformationStage["DeadCodeInjection"] = "DeadCodeInjection";
    NodeTransformationStage["Finalizing"] = "Finalizing";
    NodeTransformationStage["Initializing"] = "Initializing";
    NodeTransformationStage["Preparing"] = "Preparing";
    NodeTransformationStage["RenameIdentifiers"] = "RenameIdentifiers";
    NodeTransformationStage["RenameProperties"] = "RenameProperties";
    NodeTransformationStage["Simplifying"] = "Simplifying";
    NodeTransformationStage["StringArray"] = "StringArray";
})(NodeTransformationStage = exports.NodeTransformationStage || (exports.NodeTransformationStage = {}));
