"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapStorage = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../container/ServiceIdentifiers");
const Initializable_1 = require("../decorators/Initializable");
let MapStorage = class MapStorage {
    constructor(randomGenerator, options) {
        this.randomGenerator = randomGenerator;
        this.options = options;
    }
    initialize() {
        this.storage = new Map();
        this.storageId = this.randomGenerator.getRandomString(6);
    }
    get(key) {
        return this.storage.get(key);
    }
    getOrThrow(key) {
        const value = this.get(key);
        if (!value) {
            throw new Error(`No value found in map storage with key \`${key}\``);
        }
        return value;
    }
    getKeyOf(value) {
        for (const [key, storageValue] of this.storage) {
            if (value === storageValue) {
                return key;
            }
        }
        return null;
    }
    getLength() {
        return this.storage.size;
    }
    getStorage() {
        return this.storage;
    }
    getStorageAsDictionary() {
        return Object.fromEntries(this.storage);
    }
    getStorageId() {
        return this.storageId;
    }
    has(key) {
        return this.storage.has(key);
    }
    mergeWith(storage, mergeId = false) {
        this.storage = new Map([...this.storage, ...storage.getStorage()]);
        if (mergeId) {
            this.storageId = storage.getStorageId();
        }
    }
    set(key, value) {
        this.storage.set(key, value);
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], MapStorage.prototype, "storageId", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Map)
], MapStorage.prototype, "storage", void 0);
__decorate([
    (0, inversify_1.postConstruct)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MapStorage.prototype, "initialize", null);
MapStorage = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], MapStorage);
exports.MapStorage = MapStorage;
