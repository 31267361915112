"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var CustomCodeHelperGroupStorage_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomCodeHelperGroupStorage = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const CustomCodeHelperGroup_1 = require("../../enums/custom-code-helpers/CustomCodeHelperGroup");
const MapStorage_1 = require("../MapStorage");
let CustomCodeHelperGroupStorage = CustomCodeHelperGroupStorage_1 = class CustomCodeHelperGroupStorage extends MapStorage_1.MapStorage {
    constructor(customCodeHelperGroupFactory, randomGenerator, options) {
        super(randomGenerator, options);
        this.customCodeHelperGroupFactory = customCodeHelperGroupFactory;
    }
    initialize() {
        super.initialize();
        CustomCodeHelperGroupStorage_1.customCodeHelperGroupsList.forEach((customCodeHelperGroupName) => {
            const customCodeHelperGroup = this.customCodeHelperGroupFactory(customCodeHelperGroupName);
            this.storage.set(customCodeHelperGroupName, customCodeHelperGroup);
        });
    }
};
CustomCodeHelperGroupStorage.customCodeHelperGroupsList = [
    CustomCodeHelperGroup_1.CustomCodeHelperGroup.ConsoleOutput,
    CustomCodeHelperGroup_1.CustomCodeHelperGroup.DebugProtection,
    CustomCodeHelperGroup_1.CustomCodeHelperGroup.DomainLock,
    CustomCodeHelperGroup_1.CustomCodeHelperGroup.SelfDefending,
    CustomCodeHelperGroup_1.CustomCodeHelperGroup.StringArray
];
__decorate([
    (0, inversify_1.postConstruct)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CustomCodeHelperGroupStorage.prototype, "initialize", null);
CustomCodeHelperGroupStorage = CustomCodeHelperGroupStorage_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICustomCodeHelperGroup)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object])
], CustomCodeHelperGroupStorage);
exports.CustomCodeHelperGroupStorage = CustomCodeHelperGroupStorage;
