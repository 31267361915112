"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugProtectionFunctionCallCodeHelper = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const Initializable_1 = require("../../decorators/Initializable");
const DebugProtectionFunctionCallTemplate_1 = require("./templates/debug-protection-function-call/DebugProtectionFunctionCallTemplate");
const AbstractCustomCodeHelper_1 = require("../AbstractCustomCodeHelper");
const NodeUtils_1 = require("../../node/NodeUtils");
let DebugProtectionFunctionCallCodeHelper = class DebugProtectionFunctionCallCodeHelper extends AbstractCustomCodeHelper_1.AbstractCustomCodeHelper {
    constructor(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options);
    }
    initialize(debugProtectionFunctionName, callsControllerFunctionName) {
        this.debugProtectionFunctionName = debugProtectionFunctionName;
        this.callsControllerFunctionName = callsControllerFunctionName;
    }
    getNodeStructure(codeHelperTemplate) {
        return NodeUtils_1.NodeUtils.convertCodeToStructure(codeHelperTemplate);
    }
    getCodeHelperTemplate() {
        return this.customCodeHelperFormatter.formatTemplate((0, DebugProtectionFunctionCallTemplate_1.DebugProtectionFunctionCallTemplate)(), {
            debugProtectionFunctionName: this.debugProtectionFunctionName,
            callControllerFunctionName: this.callsControllerFunctionName
        });
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], DebugProtectionFunctionCallCodeHelper.prototype, "callsControllerFunctionName", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], DebugProtectionFunctionCallCodeHelper.prototype, "debugProtectionFunctionName", void 0);
DebugProtectionFunctionCallCodeHelper = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperObfuscator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object, Object, Object])
], DebugProtectionFunctionCallCodeHelper);
exports.DebugProtectionFunctionCallCodeHelper = DebugProtectionFunctionCallCodeHelper;
