"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nodeModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const ScopeIdentifiersTraverser_1 = require("../../../node/ScopeIdentifiersTraverser");
exports.nodeModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IScopeIdentifiersTraverser)
        .to(ScopeIdentifiersTraverser_1.ScopeIdentifiersTraverser)
        .inSingletonScope();
});
