"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainLockRedirectUrlRule = void 0;
const Default_1 = require("../presets/Default");
const DomainLockRedirectUrlRule = (options) => {
    if (!options.domainLock.length) {
        options = {
            ...options,
            domainLockRedirectUrl: Default_1.DEFAULT_PRESET.domainLockRedirectUrl
        };
    }
    return options;
};
exports.DomainLockRedirectUrlRule = DomainLockRedirectUrlRule;
