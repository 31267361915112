"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockStatementSimplifyTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractStatementSimplifyTransformer_1 = require("./AbstractStatementSimplifyTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeUtils_1 = require("../../node/NodeUtils");
let BlockStatementSimplifyTransformer = class BlockStatementSimplifyTransformer extends AbstractStatementSimplifyTransformer_1.AbstractStatementSimplifyTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.VariableDeclarationsMergeTransformer
        ];
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Simplifying:
                return {
                    leave: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isBlockStatementNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(statementNode, parentNode) {
        const simplifyData = this.getStatementSimplifyData(statementNode);
        if (!simplifyData) {
            return statementNode;
        }
        const partialStatementNode = this.getPartialStatement(simplifyData);
        const transformedNode = NodeGuards_1.NodeGuards.isBlockStatementNode(partialStatementNode)
            ? partialStatementNode
            : NodeFactory_1.NodeFactory.blockStatementNode([partialStatementNode]);
        return NodeUtils_1.NodeUtils.parentizeNode(transformedNode, parentNode);
    }
};
BlockStatementSimplifyTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], BlockStatementSimplifyTransformer);
exports.BlockStatementSimplifyTransformer = BlockStatementSimplifyTransformer;
