"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deadCodeInjectionTransformersModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const DeadCodeInjectionTransformer_1 = require("../../../node-transformers/dead-code-injection-transformers/DeadCodeInjectionTransformer");
exports.deadCodeInjectionTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(DeadCodeInjectionTransformer_1.DeadCodeInjectionTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.DeadCodeInjectionTransformer);
});
