"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayWrappersChainedCallsRule = void 0;
const StringArrayWrappersChainedCallsRule = (options) => {
    if (options.stringArrayWrappersCount === 0) {
        options = {
            ...options,
            stringArrayWrappersChainedCalls: false,
            stringArrayWrappersCount: 0
        };
    }
    return options;
};
exports.StringArrayWrappersChainedCallsRule = StringArrayWrappersChainedCallsRule;
